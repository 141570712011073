import Vue from "vue"
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: "test",
    token:localStorage.getItem("token"), // 初始化token
    userInfo:JSON.parse(sessionStorage.getItem("userInfo")) , // 初始化用户信息
  },
  mutations: {
    // 存储token的方法，设置token等于外部传递进来的值
    SET_TOKEN:(state, token)=> {
      state.token = token
      localStorage.setItem('token',token)
    },
   
    /*
     * @Description: 用户信息
     * @MethodAuthor:  liulian
     * @Date: 2022-03-04 11:49:47
     * @param: '' 
    */
    SET_USERINFO:(state,userInfo) =>{
      state.userInfo = userInfo
      sessionStorage.setItem('userInfo',JSON.stringify(userInfo))
    },
    /*
     * @Description: 移除用户信息
     * @MethodAuthor:  liulian
     * @Date: 2022-04-19 14:20:46
     * @param: '' 
    */
   REMOVE_INFO:(state) => {
    //  移除用户信息将用户所有的信息都置为空
    state.token = ''
    state.userInfo = {}
    localStorage.setItem("token",'')
    sessionStorage.setItem("userInfo",JSON.stringify(""))
   }
  },
  getters: {
  //  获取用户信息
  getUser:state => {
    return state.userInfo
  }
  },
  modules: {},
})
