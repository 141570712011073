<!--
* @description数字勘测云
* @fileName szkcy.vue
* @author liulian
* @date 2023/07/12 09:38:57
-->
<template>
  <div class="szkcy_container">
    <div class="s_top">
      <div class="s_title font-color-white font-size-32 font-weight-600 text-align-center">{{ $t("pc_index.Szkcy_Title") }}</div>
      <div class="s_btns flex-row-center text-align-center">
        <div class="b_btn font-size-16 font-weight-600 font-color-white ">{{ $t("pc_index.Szkcy_try") }}</div>
      </div>
    </div>
    <div class="s_con">
      <div class="s_c_nav flex-row-around align-center">
        <div class="s_c_n_item flex-row-center align-center" :class="activeIndex === index ? 's_c_n_item_active font-color-yellow-FC8F01' : 'font-color-white'" v-for="(item, index) in lists" :key="index" @mouseenter="clickNav(item, index)">
          <img class="s_c_n_i_icon cursor-pointer" :src="activeIndex === index ? item.selIcon : item.icon" alt="icon" />
          <span class="s_c_n_i_span cursor-pointer font-size-24 font-weight-600">{{ $t(`pc_index.${item.name}`) }}</span>
        </div>
      </div>
      <div class="s_c_main flex-row">
        <!-- 侧边栏 -->
        <div class="s_c_slidle">
          <div class="s_c_s_item flex-row-center align-center" :class="slideActiveIndex === index ? 's_c_s_item_active' : ''" v-for="(item, index) in sidebars" :key="index" @mouseenter="clickSlidItem(item, index)">
            <span class="s_c_s_line"></span> <span class="s_c_s_span cursor-pointer" :class="slideActiveIndex === index ? ' font-color-yellow-FC8F01 font-size-20 font-weight-500' : ' font-color-black-301D09 font-size-18 font-weight-400'">{{ $t(`pc_index.${item.name}`) }}</span>
          </div>
        </div>
        <div class="shadow_box" :class="{'en': $i18n.locale == 'English'}">
          <!-- 阴影 -->
          <div class="boxshadow"></div>

          <div class="s_c_m_content flex-row align-center">
          <div class="s_c_m_c_item flex-column align-center" v-for="(item, index) in contentList" :key="index">
            <div class="s_c_m_con">
              <img class="s_c_m_c_i_icon" :src="item.img" alt="描述图片" />
              <div class="s_c_m_title font-size-18 font-weight-500 font-color-black-301D09">{{ $t(`pc_index.${item.title}`) }}</div>
              <div class="s_c_m_p font-size-12 font-weight-400 font-color-gray-301D09">{{ $t(`pc_index.${item.content}`) }}</div>
            </div>
          </div>
        </div>
        </div>
       
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "szkcy",
  components: {},
  data() {
    return {
      lists: [], //导航
      activeIndex: 0,
      slideActiveIndex: 0,
      sidebars: [], //侧边栏
      contentList: [], // 内容
    }
  },
  computed: {},
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    /*
     * @Description: 点击侧边栏
     * @MethodAuthor:  liulian
     * @Date: 2023-07-12 18:00:50
     * @param: ''
     */
    clickSlidItem(item, index) {
      this.slideActiveIndex = index
      this.contentList = item.chidlren
    },
    /*
     * @Description: 点击导航栏
     * @MethodAuthor:  liulian
     * @Date: 2023-07-12 11:36:42
     * @param: ''
     */
    clickNav(item, index) {
      this.activeIndex = index
      this.sidebars = item.chidlren
      this.contentList = this.sidebars[0].chidlren
      this.slideActiveIndex = 0
    },
    /*
     * @Description:初始化数据
     * @MethodAuthor:  liulian
     * @Date: 2023-07-12 10:42:36
     * @param: ''
     */
    init() {
      this.lists = [
        {
          name: "Szkcy_tab1_title",
          icon: require("../../../assets/images/homeImg/szkcy_nav_icon@2x.png"),
          selIcon: require("../../../assets/images/homeImg/szkcy_nav_icon_sel@2x.png"),
          chidlren: [
            {
              name: "Szkcy_tab1_subTab1_title",
              chidlren: [
                {
                  title:'Szkcy_tab1_subTab1_1_title',
                  content:'Szkcy_tab1_subTab1_1_content',
                  img: require("../../../assets/images/qbcpImg/1_1_1@2x.png"),
                },
                {
                  title:'Szkcy_tab1_subTab1_2_title',
                  content:'Szkcy_tab1_subTab1_2_content',
                  img: require("../../../assets/images/qbcpImg/1_1_2@2x.png"),
                },
                {
                  title:'Szkcy_tab1_subTab1_3_title',
                  content:'Szkcy_tab1_subTab1_3_content',
                  img: require("../../../assets/images/qbcpImg/1_1_3@2x.png"),
                },
                {
                  title:'Szkcy_tab1_subTab1_4_title',
                  content:'Szkcy_tab1_subTab1_4_content',
                  img: require("../../../assets/images/qbcpImg/1_1_4@2x.png"),
                },
                {
                  title:'Szkcy_tab1_subTab1_5_title',
                  content:'Szkcy_tab1_subTab1_5_content',
                  img: require("../../../assets/images/qbcpImg/1_1_5@2x.png"),
                }
              ],
            },
          ],
        },
        {
          name: "Szkcy_tab2_title",
          icon: require("../../../assets/images/homeImg/szkcy_nav_icon2@2x.png"),
          selIcon: require("../../../assets/images/homeImg/szkcy_nav_icon2_sel@2x.png"),
          chidlren: [
            {
              name: "Szkcy_tab2_subTab1_title",
              chidlren: [
              {
                  title:'Szkcy_tab2_subTab1_1_title',
                  content:'Szkcy_tab2_subTab1_1_content',
                  img: require("../../../assets/images/qbcpImg/2_1_1@2x.png"),
                },
                {
                  title:'Szkcy_tab2_subTab1_2_title',
                  content:'Szkcy_tab2_subTab1_2_content',
                  img: require("../../../assets/images/qbcpImg/2_1_2@2x.png"),
                },
                {
                  title:'Szkcy_tab2_subTab1_3_title',
                  content:'Szkcy_tab2_subTab1_3_content',
                  img: require("../../../assets/images/qbcpImg/2_1_3@2x.png"),
                },
                {
                  title:'Szkcy_tab2_subTab1_4_title',
                  content:'Szkcy_tab2_subTab1_4_content',
                  img: require("../../../assets/images/qbcpImg/2_1_4@2x.png"),
                },
                {
                  title:'Szkcy_tab2_subTab1_5_title',
                  content:'Szkcy_tab2_subTab1_5_content',
                  img: require("../../../assets/images/qbcpImg/2_1_5@2x.png"),
                },{
                  title:'Szkcy_tab2_subTab1_6_title',
                  content:'Szkcy_tab2_subTab1_6_content',
                  img: require("../../../assets/images/qbcpImg/2_1_6@2x.png"),
                },
              ],
            },
          ],
        },
        {
          name: "Szkcy_tab3_title",
          icon: require("../../../assets/images/homeImg/szkcy_nav_icon3@2x.png"),
          selIcon: require("../../../assets/images/homeImg/szkcy_nav_icon3_sel@2x.png"),
          chidlren: [
            {
              name: "Szkcy_tab3_subTab1_title",
              chidlren: [
              {
                  title:'Szkcy_tab3_subTab1_1_title',
                  content:'Szkcy_tab3_subTab1_1_content',
                  img: require("../../../assets/images/qbcpImg/3_1_1@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab1_2_title',
                  content:'Szkcy_tab3_subTab1_2_content',
                  img: require("../../../assets/images/qbcpImg/3_1_2@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab1_3_title',
                  content:'Szkcy_tab3_subTab1_3_content',
                  img: require("../../../assets/images/qbcpImg/3_1_3@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab1_4_title',
                  content:'Szkcy_tab3_subTab1_4_content',
                  img: require("../../../assets/images/qbcpImg/3_1_4@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab1_5_title',
                  content:'Szkcy_tab3_subTab1_5_content',
                  img: require("../../../assets/images/qbcpImg/3_1_5@2x.png"),
                },
              ],
            },
            {
              name: "Szkcy_tab3_subTab2_title",
              chidlren: [
              {
                  title:'Szkcy_tab3_subTab2_1_title',
                  content:'Szkcy_tab3_subTab2_1_content',
                  img: require("../../../assets/images/qbcpImg/3_2_1@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab2_2_title',
                  content:'Szkcy_tab3_subTab2_2_content',
                  img: require("../../../assets/images/qbcpImg/3_2_2@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab2_3_title',
                  content:'Szkcy_tab3_subTab2_3_content',
                  img: require("../../../assets/images/qbcpImg/3_2_3@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab2_4_title',
                  content:'Szkcy_tab3_subTab2_4_content',
                  img: require("../../../assets/images/qbcpImg/3_2_4@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab2_5_title',
                  content:'Szkcy_tab3_subTab2_5_content',
                  img: require("../../../assets/images/qbcpImg/3_2_5@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab2_6_title',
                  content:'Szkcy_tab3_subTab2_6_content',
                  img: require("../../../assets/images/qbcpImg/3_2_6@2x.png"),
                },
              ],
            },
            {
              name: "Szkcy_tab3_subTab3_title",
              chidlren: [
              {
                  title:'Szkcy_tab3_subTab3_1_title',
                  content:'Szkcy_tab3_subTab3_1_content',
                  img: require("../../../assets/images/qbcpImg/3_3_1@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab3_2_title',
                  content:'Szkcy_tab3_subTab3_2_content',
                  img: require("../../../assets/images/qbcpImg/3_3_2@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab3_3_title',
                  content:'Szkcy_tab3_subTab3_3_content',
                  img: require("../../../assets/images/qbcpImg/3_3_3@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab3_4_title',
                  content:'Szkcy_tab3_subTab3_4_content',
                  img: require("../../../assets/images/qbcpImg/3_3_4@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab3_5_title',
                  content:'Szkcy_tab3_subTab3_5_content',
                  img: require("../../../assets/images/qbcpImg/3_3_5@2x.png"),
                },
                {
                  title:'Szkcy_tab3_subTab3_6_title',
                  content:'Szkcy_tab3_subTab3_6_content',
                  img: require("../../../assets/images/qbcpImg/3_3_6@2x.png"),
                },
              ],
            },
          ],
        },
        {
          name: "Szkcy_tab4_title",
          icon: require("../../../assets/images/homeImg/szkcy_nav_icon4@2x.png"),
          selIcon: require("../../../assets/images/homeImg/szkcy_nav_icon4_sel@2x.png"),
          chidlren: [
            {
              name: "Szkcy_tab4_subTab1_title",
              chidlren: [
              {
                  title:'Szkcy_tab4_subTab1_1_title',
                  content:'Szkcy_tab4_subTab1_1_content',
                  img: require("../../../assets/images/qbcpImg/4_1_1@2x.png"),
                },
                {
                  title:'Szkcy_tab4_subTab1_2_title',
                  content:'Szkcy_tab4_subTab1_2_content',
                  img: require("../../../assets/images/qbcpImg/4_1_2@2x.png"),
                },
                {
                  title:'Szkcy_tab4_subTab1_3_title',
                  content:'Szkcy_tab4_subTab1_3_content',
                  img: require("../../../assets/images/qbcpImg/4_1_3@2x.png"),
                },
                {
                  title:'Szkcy_tab4_subTab1_4_title',
                  content:'Szkcy_tab4_subTab1_4_content',
                  img: require("../../../assets/images/qbcpImg/4_1_4@2x.png"),
                },
                {
                  title:'Szkcy_tab4_subTab1_5_title',
                  content:'Szkcy_tab4_subTab1_5_content',
                  img: require("../../../assets/images/qbcpImg/4_1_5@2x.png"),
                },
              ],
            },
            {
              name: "Szkcy_tab4_subTab2_title",
              chidlren: [
                {
                  title:'Szkcy_tab4_subTab2_1_title',
                  content:'Szkcy_tab4_subTab2_1_content',
                  img: require("../../../assets/images/qbcpImg/4_2_1@2x.png"),
                },
                {
                  title:'Szkcy_tab4_subTab2_2_title',
                  content:'Szkcy_tab4_subTab2_2_content',
                  img: require("../../../assets/images/qbcpImg/4_2_2@2x.png"),
                },
                {
                  title:'Szkcy_tab4_subTab2_3_title',
                  content:'Szkcy_tab4_subTab2_3_content',
                  img: require("../../../assets/images/qbcpImg/4_2_3@2x.png"),
                },
                {
                  title:'Szkcy_tab4_subTab2_4_title',
                  content:'Szkcy_tab4_subTab2_4_content',
                  img: require("../../../assets/images/qbcpImg/4_2_4@2x.png"),
                },
                {
                  title:'Szkcy_tab4_subTab2_5_title',
                  content:'Szkcy_tab4_subTab2_5_content',
                  img: require("../../../assets/images/qbcpImg/4_2_5@2x.png"),
                },
              ],
            },
          ],
        },
      ]

      // 默认的侧边栏
      this.sidebars = this.lists[0].chidlren
      this.contentList = this.sidebars[0].chidlren
    },
  },
}
</script>

<style scoped lang="scss">
.szkcy_container {
  background-color: #fff;
  .s_top {
    height: 338px;
    background: linear-gradient(180deg, #e37b07 0%, #f59025 100%);
    background-image: url("../../../assets/images/homeImg/szkcy_bg@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    .s_title {
      padding-top: 112px;
    }
    .s_btns {
      margin-top: 22px;
      .b_btn {
        width: 120px;
        height: 32px;
        border: 1px solid #ffffff;
        line-height: 30px;
      }
    }
  }
  .s_con {
    width: 1200px;
    margin: -96px auto;
    height: 300px;
    .s_c_n_item {
      flex: 1;
      height: 96px;
      .s_c_n_i_icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }
    }
    .s_c_n_item_active {
      background-color: #fff;
      -webkit-animation: fadeIn 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      animation: fadeIn 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      
    }
    @-webkit-keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
    .s_c_slidle {
      background-color: #fff;
      width: 300px;
      .s_c_s_item {
        height: 80px;
        // line-height: 80px;
        justify-content: left;
        .s_c_s_line {
          width: 35px;
          height: 2px;
          background: #dbdbdb;
          display: block;
          margin-right: 33px;
        }
      }
      .s_c_s_item_active {
        .s_c_s_line {
          width: 46px;
          height: 4px;
          background: #fc8f01;
          margin-right: 22px;
        }
      }
    }
    .shadow_box{
      position: relative;
      flex: 1;
      .boxshadow{
        box-shadow:0px 7px 24px 0px rgba(169,109,32,0.1);//左边阴影
        position: absolute;
        top: 20px;
        left: -1px;
        right: -1px;
        border-top: unset;
        height: 380px;
      }
      &.en {
        .boxshadow {
          height: 520px;
        }
        .s_c_m_c_item {
          min-height: 270px;
        }
      }
    }
  
    .s_c_m_content{
      flex-wrap: wrap;
      width: 100%;
      background-color: #fff;
      position: absolute;
      top: 0px;
      left: 0;
      box-sizing: content-box;
      width: 904px;

      // box-shadow: 0px 7px 24px 0px rgba(169,109,32,0.1);
   
      .s_c_m_c_item{
        z-index: 10;
        // flex: 0 0 33%;
        width: 300px;
        min-height: 200px;
        border: 1px solid rgba(169,109,32,0.1);;
        box-sizing: content-box;
        .s_c_m_con{
          margin-top: 29px;
          width: 240px;
        }
      
        .s_c_m_c_i_icon{
          width: 32px;
          height:32px;
        }
        .s_c_m_title{
          margin-top: 18px;
        }
        .s_c_m_p{
          margin-top: 6px;
          line-height: 24px;
          color: rgba(48,29,9,0.6);
        }
        
      }
      .s_c_m_c_item:nth-child(1){
        border-top: unset;
        border-left: unset;
      }
      .s_c_m_c_item:nth-child(2){
        border-top: unset;
        border-left: unset;
      }
      .s_c_m_c_item:nth-child(3){
        border-top: unset;
        border-left: unset;
        border-right: unset;
      }
      .s_c_m_c_item:nth-child(4){
        border-top: unset;
        border-left: unset;
        border-bottom: unset;
      }
      .s_c_m_c_item:nth-child(5){
        border-top: unset;
        border-left: unset;
        border-bottom: unset;
      }
      .s_c_m_c_item:nth-child(6){
        border-top: unset;
        border-left: unset;
        border-bottom: unset;
        border-right: unset;

      }
    }
  }
}
</style>
