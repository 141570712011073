<!--
* @description 关于我们
* @fileName gywm.vue
* @author liulian
* @date 2023/06/30 18:06:05
-->
<template>
  <div class="gywm">
    <!-- <div class="g_t_b">
      <div class="b_c font-size-24 font-weight-500 text-align-center">想要了解基础设施勘测设计、施工建造、运维管养全过程、全周期、全要素的</div>
      <div class="b_c font-size-24 font-weight-500 text-align-center">行业解决方案与应用案例？</div>
      <div class="b_c font-size-24 font-weight-400 text-align-center">欢迎资讯江苏狄诺尼销售顾问</div>
      <div class="b_c_b text-align-center">
        <span class="font-size-24 font-weight-500">联系我们</span>
      </div>
    </div> -->
    <div
      class="g_title text-align-center font-size-32 font-weight-600 font-color-black-301D09"
    >
      {{ $t("pc_index.Gywm_Title") }}
    </div>
    <div class="g_con" :class="{ en: $i18n.locale == 'English' }">
      <div class="g_left">
        <template v-if="$i18n.locale == 'English'">
          <img
            class="g_l_i1"
            src="@/assets/images/homeImg/gywmEn.png"
            alt="关于我们图片"
          />
        </template>
        <template v-else>
          <img
            class="g_l_i1"
            src="@/assets/images/homeImg/gywmZh.png"
            alt="关于我们图片"
          />
        </template>
        <div class="y_box"></div>
      </div>
      <div
        class="g_right font-size-18 font-weight-400"
        :class="{ en: $i18n.locale == 'English' }"
      >
        <div class="g_r_p">{{ $t("pc_index.Gywm_text1") }}</div>
        <div class="g_r_p">{{ $t("pc_index.Gywm_text2") }}</div>
        <div class="g_r_p">{{ $t("pc_index.Gywm_text3") }}</div>
        <!-- <div class="g_r_p">　　狄诺尼依托强大的技术咨询团队，面向公路、城市道路、铁路等行业用户，在精益化、数字化设计，方案创新、资源优化等方面提供专业的咨询服务；致力于为客户提供设计、分析、优化和成果表达等全方位的解决方案；帮助客户提高设计效率和质量，推动设计理念创新。</div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "gywm",
  components: {},
  data() {
    return {};
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.gywm {
  background: #f5f7f9;
  // height: 644px;
  height: 848px;
  // padding-top: 64px;
  .g_t_b {
    background-image: url("https://pic1.zhimg.com/v2-8d5ebb4f0fa67e76cbdd3c468f596e98_r.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 360px;
    width: 100%;
    .b_c {
      width: 952px;
      margin: auto;
    }
    .b_c:first-child {
      margin-top: 66px;
    }
  }
  .g_title {
    padding-top: 64px;
    margin-bottom: 32px;
  }
  .g_con {
    display: flex;
    justify-content: center;
    &.en {
      .g_left {
        &::before,
        &::after {
          background: none;
        }
      }
      .g_right {
        min-height: 896px;
      }
    }
  }
  .g_left {
    width: 520px;
    height: 450px;
    margin-right: 35px;
    position: relative;
    .g_l_i {
      width: 100%;
      height: 100%;
    }
    .g_l_i1 {
      width: 100%;
      object-fit: contain;
    }
    .g_l_i2 {
      margin-top: 15px;
      width: 100%;
      height: 260px;
    }
    .g_l_i3 {
      margin-top: 15px;
      width: 100%;
      height: 310px;
    }
  }
  // .g_left::before {
  //   position: absolute;
  //   content: "";
  //   width: 14px;
  //   height: 392px;
  //   background-color: #ffb44c;
  //   // left: -14px;
  //   left: 0;
  //   bottom: 0;
  // }
  // .g_left::after {
  //   position: absolute;
  //   content: "";
  //   width: 508px;
  //   // height: 16px;
  //   background-color: #ffb44c;
  //   left: 0px;
  //   bottom: 0;
  // }

  .g_right {
    // width: 579px;
    width: 628px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 364px;
    .g_r_p {
      text-indent: 35px;
      width: 100%;
      line-height: 34px;
      color: rgba(48, 29, 9, 0.74);
    }
    &.en {
      text-align: justify;
      font-size: 16px;
      .g_r_p {
        line-height: 28px;
      }
    }
  }
}
</style>
