<template>
  <div class="hzhb_contienr">
    <div class="h_title text-align-center font-size-32 font-weight-600 font-color-black-301D09">{{ $t("pc_index.Hzhb_Title") }}</div>
    <br/><br/><br/>
    <!-- <div class="h_descript text-align-center font-size-18 font-weight-400" :class="{'en': $i18n.locale == 'English'}">{{ $t("pc_index.Hzhb_describe") }}</div> -->

    <!-- <div class="image-carousel">
      <vue-danmaku class="image-carousel" ref="danmaku" :danmus="danmus" use-slot loop :speeds="speeds">
        弹幕插槽（vue 2.6.0 及以上版本可使用 v-slot:dm="{ index, danmu }"语法）
        <template slot="dm" slot-scope="{ index, item }">
          <div @mouseover="pauseCarousel" @mouseout="resumeCarousel">
            <div class="carousel-inner">
              <img v-for="(image, index) in imgArr1" :key="index" :src="require(`../../../assets/images/hzhbImg/h_${index + 1}@2x.png`)" alt="Image" class="carousel-image" />
            </div>
            <div class="carousel-inner">
              <img v-for="(image, index) in imgArr2" :key="index" :src="require(`../../../assets/images/hzhbImg/h_${index + 12}@2x.png`)" alt="Image" class="carousel-image" />
            </div>
            <div class="carousel-inner">
              <img v-for="(image, index) in imgArr3" :key="index" :src="require(`../../../assets/images/hzhbImg/h_${index + 25}@2x.png`)" alt="Image" class="carousel-image" />
            </div>
            <div class="carousel-inner">
              <img v-for="(image, index) in imgArr4" :key="index" :src="require(`../../../assets/images/hzhbImg/h_${index + 37}@2x.png`)" alt="Image" class="carousel-image" />
            </div>
          </div>
        </template>
      </vue-danmaku>
    </div> -->
    <div class="animation_box">
      <div id="stage_advan_Group" class="stage_advan_Group_id animation">
        <div id="stage_advan_yun2_02Copy">
          <div class="carousel-inner">
            <img v-for="(image, index) in imgArr1" :key="index" :src="require(`../../../assets/images/hzhbImg/h_${index + 1}@2x.png`)" alt="Image" class="carousel-image" />
          </div>
        </div>
      </div>

      <!--第二屏 -->
      <div id="stage_advan_Group" class="stage_advan_Group_id animation2">
        <div id="stage_advan_yun2_02Copy">
          <div class="carousel-inner">
            <img v-for="(image, index) in imgArr2" :key="index" :src="require(`../../../assets/images/hzhbImg/h_${index + 12}@2x.png`)" alt="Image" class="carousel-image" />
          </div>
        </div>
      </div>

      <!-- 第三屏 -->
      <div id="stage_advan_Group" class="stage_advan_Group_id animation3">
        <div id="stage_advan_yun2_02Copy">
          <div class="carousel-inner">
            <img v-for="(image, index) in imgArr3" :key="index" :src="require(`../../../assets/images/hzhbImg/h_${index + 25}@2x.png`)" alt="Image" class="carousel-image" />
          </div>
        </div>
      </div>

      <!--第四屏 -->
      <div id="stage_advan_Group" class="stage_advan_Group_id animation4">
        <div id="stage_advan_yun2_02Copy">
          <div class="carousel-inner">
            <img v-for="(image, index) in imgArr4" :key="index" :src="require(`../../../assets/images/hzhbImg/h_${index + 38}@2x.png`)" alt="Image" class="carousel-image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueDanmaku from "vue-danmaku"
export default {
  components: {
    VueDanmaku,
  },
  data() {
    return {
      danmus: [
        { text: "Danmaku 1", direction: "left" },
        { text: "Danmaku 2", direction: "left" },
        { text: "Danmaku 3", direction: "left" },
      ],
      images: [
        "https://i.postimg.cc/cHZjnpRb/6544-2x.png",
        "https://tse2-mm.cn.bing.net/th/id/OIP-C.mH9YLFEL5YdVxJM82mjVJQAAAA?pid=ImgDet&rs=1",
        "https://ts1.cn.mm.bing.net/th/id/R-C.df4462fabf18edd07195679a5f8a37e5?rik=FnNvr9jWWjHCVQ&riu=http%3a%2f%2fseopic.699pic.com%2fphoto%2f50059%2f8720.jpg_wh1200.jpg&ehk=ofb4q76uCls2S07aIlc8%2bab3H5zwrmj%2bhqiZ%2fyw3Ghw%3d&risl=&pid=ImgRaw&r=0",
        "https://i.postimg.cc/cHZjnpRb/6544-2x.png",
        // 添加更多的图片URL
      ],
      imgArr1: [],
      imgArr2: [],
      imgArr3: [],
      imgArr4: [],
      speeds: 100,
      isAnimation: true,
    }
  },
  created() {
    this.init()
  },
  mounted() {},
  activated() {
    console.log("conso组件被激活的了 ")
    this.speeds = 100
  },
  deactivated() {
    console.log("conso组件被注销了 ")
  },
  methods: {
    /*
     * @Description: 数据
     * @MethodAuthor:  liulian
     * @Date: 2023-07-13 11:46:49
     * @param: ''
     */
    init() {
      for (let i = 0; i < 11; i++) {
        // 10
        this.imgArr1.push({ id: i })
      }
      for (let i = 0; i < 13; i++) {
        this.imgArr2.push({ id: i })
      }
      for (let i = 0; i < 13; i++) {
        this.imgArr3.push({ id: i })
      }
      for (let i = 0; i < 13; i++) {
        this.imgArr4.push({ id: i })
      }
    },

    /*
     * @Description: 鼠标覆盖开始动画
     * @MethodAuthor:  liulian
     * @Date: 2023-07-13 14:47:17
     * @param: ''
     */
    pauseCarousel() {
      this.$refs["danmaku"].pause() // 弹幕暂停
      // this.isAnimation = false
      console.log(" this.isAnimation ", this.isAnimation)
    },
    /*
     * @Description: 鼠标离开执行动画
     * @MethodAuthor:  liulian
     * @Date: 2023-07-13 14:47:07
     * @param: ''
     */
    resumeCarousel() {
      this.$refs["danmaku"].play() // 弹幕开始
      // this.isAnimation = true
      console.log("this.isAnimation ", this.isAnimation)
    },
  },
}
</script>
<style scoped lang="scss">
.hzhb_contienr {
  height: 674px;
  .h_title {
    padding-top: 82px;
  }
  .h_descript {
    width: 668px;
    margin: 24px auto;
    color: rgba(48, 29, 9, 0.74);
    height: 65px;
    line-height: 28px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 40px;
    &.en{
      width: 1250px;
      height: 150px;
    }
  }
}

.image-carousel {
  overflow: hidden;
  height: 420px;
}
.carousel-inner {
  display: flex;
  margin-bottom: 40px;
  top: 0;
  width: 100%;
}

.carousel-image {
  flex-shrink: 0;
  width: auto; /* 设置图片宽度 */
  height: 40px; /* 设置图片高度 */
  margin-right: 24px;
}
.animation_box {
  position: relative;
  width: 100%;
  height: 420px;
  overflow: hidden;
}

#stage_advan_Group {
  position: absolute;
  margin: 0px;
  left: 0px;
  bottom: 0px;
  width: 200%;
  height: 100%;
  right: auto;
}
#stage_advan_Group:nth-child(2) {
  position: absolute;
  top: 80px;
  left: 0px;
  bottom: 0px;
  width: 200%;
  height: 100%;
  right: auto;
}
#stage_advan_Group:nth-child(3) {
  top: 160px;
}
#stage_advan_Group:nth-child(4) {
  top: 240px;
}
.animation {
  -webkit-animation: 28s move  linear infinite ;
}
.animation2 {
  -webkit-animation: 40s move2 infinite linear;
}
.animation3 {
  -webkit-animation: 70s move3 infinite linear;
}
.animation4 {
  -webkit-animation: 45s move4 infinite linear;
}
#stage_advan_yun2_02Copy {
  position: absolute;
  margin: 0px;
  // left: 0px;
  top: 0px;
  // width: 100%;
  height: 42px;
  right: 0;
  bottom: auto;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  float: left;
}

@-webkit-keyframes move {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes move {
  0% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(-50%);
  }
}
@-webkit-keyframes move2 {
  0% {
    transform: translateX(-52%);
  }
  100% {
    transform: translateX(-3%);
  }
}

@keyframes move2 {
  0% {
    transform: translateX(-52%);
  }
  100% {
    transform: translateX(-3%);
  }
}
@-webkit-keyframes move3 {
  0% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes move3 {
  0% {
    transform: translateX(25%);
  }
  100% {
    transform: translateX(-50%);
  }
}
@-webkit-keyframes move4 {
  0% {
    transform: translateX(-52%);
  }
  100% {
    transform: translateX(20%);
  }
}

@keyframes move4 {
  0% {
    transform: translateX(-52%);
  }
  100% {
    transform: translateX(20%);
  }
}
</style>
