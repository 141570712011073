import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入element
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import element from "./utils/element/index";
import themeColor from "./assets/styles/element-variables.scss";
import resetCss from "./assets/styles/reset.css";
import jjpaIndex from "./assets/styles/jjpaIndex.css";
import uniFun from "./utils/uniFun";
import { Message } from "element-ui";
// const videBaseUrl ='http://58.213.48.53:99/video/' // 视频前缀
const videBaseUrl = "https://dev.eicad.net/files/public/video/"; // 视频前缀

// 解决方案 VRRoad  Vr技术视频前缀
const videBaseUrlNew = 'https://dev.eicad.net/files/public/'

import i18n from "./i18n";

Vue.use({ i18n: (key, value) => i18n.t(key, value) }); // 在注册Element时设置i18n的处理方法,可以实现当点击切换按钮后，elementUI可以自动调用.js语言文件实现多语言切换

// 挂载到$message上
Vue.use(element);
Vue.use(Element);
Vue.config.productionTip = false;
Vue.prototype.$uniFun = uniFun;
Vue.prototype.$message = Message;
Vue.prototype.$videBaseUrl = videBaseUrl;
Vue.prototype.$videBaseUrlNew = videBaseUrlNew;

new Vue({
  router,
  store,
  themeColor,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
