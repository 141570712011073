import request from "@/utils/request";

// export function mainCardListApi(query) {
//   return request({
//     url: "/app/api/query/oilCard/mainCardList",
//     method: "get",
//     params: query,
//   });
// }
export function clientBannerListApi() {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: "/client/banner/clientBannerList",
    method: "get",
    params: { language }
  });
}
// ossid查询图片
export function listByIdsApi(ossIds) {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/system/oss/listByIds/${ossIds}`,
    method: "get",
    params: { language }
  });
}
// 首页最新四条资讯
export function clientHomeLatestInfoApi() {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/client/message/clientHomeLatestInfo`,
    method: "get",
    params: { language }
  });
}
// 资讯详情
export function clientMessageSelectByIdApi(messageId) {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/client/message/clientMessageSelectById/${messageId}`,
    method: "get",
    params: { language }
  });
}
// 资讯与活动列表
export function clientMessageListPageApi(params) {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/client/message/clientMessageListPage`,
    method: "get",
    params: { ...params, language }
  });
}
// 首页视频对接
export function clientHomeVideoApi() {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/client/video/clientHomeLatestInfo`,
    method: "get",
    params: { language }
  });
}
// 视频列表
export function clientVideoListPageApi(params) {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/client/video/clientVideoListPage`,
    method: "get",  
    params: { ...params, language }
  });
}
// 视频详情
export function clientVideoSelectByIdApi(videoId) {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/client/video/clientVideoSelectById/${videoId}`,
    method: "get",   
    params: { language }
  });
}
// 视频分类合计
export function classifyListPageApi() {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/client/clientTeaching/classifyListPage`,
    method: "get",   
    params: { language }
  });
}
// 视频分类下视频
export function otherAlbumTeachingApi(classifyId) {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/client/clientTeaching/otherAlbumTeaching/${classifyId}`,
    method: "get",   
    params: { language }
  });
}
// 统计观看人数
export function teachingCountApi(teachingId) {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: `/client/clientTeaching/teachingCount/${teachingId}`,
    method: "get",   
    params: { language }
  });
}

// 查询产品列表
export function getSoftwareList(query) {
  let language = localStorage.getItem('dnn-cw-lang') == 'English' ? 'en' : 'zh'
  return request({
    url: '/client/software/softwareList',
    method: 'get',
    params: { ...query, language }
  })
}