<!--
* @description 视频
* @fileName swiper.vue
* @author liulian
* @date 2022/06/10 18:22:29
-->
<template>
  <div class="swiper-container">
    <div class="outs_box">
      <div class="swiper-wrapper" ref="swiper">
        <div class="swiper-slide swiper-slide1" :class="index === listsData.length - 1 ? 'swiper-no-swiping' : ''" v-for="(item, index) in listsData" :key="index">
          <div class="clickBox cursor-pointer" style="position:absolute;top:0px;z-index:99999" @click="goDetail(item)"></div>
          <!-- <avatarCard :item="item"></avatarCard> -->
          <div class="con_box cursor-pointer" :videoId="item.videoId">
            <img class="c_img" :src="item.videoUrl" alt="封面" :videoId="item.videoId" />
            <div class="c_title font-size-12 font-weight-400 clamp-2" :videoId="item.videoId">{{ item.videoTitle }}</div>
            <div class="time font-size-10 font-weight-400" :videoId="item.videoId">{{ item.updateTime }}</div>
          </div>
          <div class="slide-overlay" :videoId="item.videoId"></div>
        </div>
      </div>
    </div>
    <div class="s_more_btn flex-row-center align-center">
      <span class="m_btn cursor-pointer font-size-12 font-weight-400" @click="moreVedio">{{ $t("pc_index.MoreVideos") }}</span>
    </div>

    <!-- 如果需要分页器 -->
    <div class="swiper-pagination"></div>
    <!-- 如果需要导航按钮 -->
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
  </div>
</template>

<script>
import Swiper from "swiper" // 注意引入的是Swiper
import "swiper/css/swiper.min.css" // 注意这里的引入
// import avatarCard from "@/components/common/avatar-card.vue"
export default {
  name: "sp",
  components: {
    // avatarCard,
  },
  props: {
    // 列表数据
    listsData: {
      type: Array,
      default: () => [
        // {
        //   url: "https://pic1.zhimg.com/v2-8d5ebb4f0fa67e76cbdd3c468f596e98_r.jpg",
        //   title: "数字化建设平台产品系列线-华设智慧工地平台",
        //   time: "2022-10-4",
        // }
      ],
    },
  },
  mounted() {
    let _this = this
    new Swiper(".outs_box", {
      slidesPerView: 5, // 没屏5个
      loop: this.loop, //收尾衔接
      spaceBetween: 12, // 间距
      autoplay: {
        // 自动轮播
        delay: 2000, // Adjust the delay time as needed (in milliseconds)
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      calculateHeight: true,
      cssWidthAndHeight: true,
      
    })
  },
  data() {
    return {
      loop: false,
    }
  },
  created() {
    this.loop = this.listsData.length > 5
    console.log("this.loop ", this.loop)
  },
  methods: {
    testHandle(it){
      console.log('it ')
    },
    goDetail(item) {
      // this.$router.push(`/zxyhdVideoDetail?type=information&id=${item.videoId}`)
      this.$uniFun.openUrl(`/dnnweb/zxyhdVideoDetail?type=information&id=${item.videoId}`)
    },
    moreVedio() {
      // this.$router.push("/information?type=information&class=video")
      this.$uniFun.openUrl(`/dnnweb/information?type=information&class=video`)
    },
  },
}
</script>

<style scoped lang="scss">
.swiper-container {
  margin:16px auto;
  width: 1374px;

  .outs_box {
    width: 1188px;
    overflow: hidden;
    margin: auto;
    min-height: 195px; // 轮播的高度
  }

  .s_more_btn {
    margin-top: 29px;
    .m_btn {
      width: 95px;
      height: 24px;
      line-height: 24px;
      background: #fc8f01;
      border-radius: 3px;
      text-align: center;

      color: #ffffff;
    }
  }
  .clickBox{
    width: 100%;
    height: 100%;
  }
  ::v-deep .swiper-slide {
    border-radius: 4px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }

    .con_box {
      width: 100%;
      .c_title {
        color: #333333;
        padding-left: 8px;
        padding-right: 8px;
        margin-top: 4px;
        height: 32px;
      }
      .c_img {
        height: 128px;
        object-fit: cover;
      }
      .time {
        margin-top: 4px;
        color: #999999;
        margin-left: 8px;
      }
    }
    .slide-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // opacity: 0;
      z-index: 9999;
      cursor: pointer;
    }
  }
  ::v-deep .swiper-slide:first-child {
    margin-left: unset;
  }

  ::v-deep .swiper-button-prev {
    background-image: url("../../../assets/images/left_icon@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 61px !important;
    height: 61px !important;
    position: absolute;
    left: 0;
    top: 78px;
  }
  ::v-deep .swiper-button-prev::after {
    display: none;
    width: 2.375rem;
    height: 2.375rem;
  }
  ::v-deep .swiper-button-next {
    background-image: url("../../../assets/images/right_icon@2x.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 61px !important;
    height: 61px !important;
    position: absolute;
    right: 0;
    top: 78px;
  }
  ::v-deep .swiper-button-next::after {
    display: none;
    width: 2.375rem;
    height: 2.375rem;
  }

  .swiper_btn {
    width: 20px;
    height: 20px;
    background-size: contain;
  }
  ::v-deep .card {
    width: 15.625rem;
    // height: 22.4375rem;
  }
  ::v-deep .swiper-button-prev.swiper-button-disabled {
    pointer-events: unset !important;
  }
}
</style>
