/* 处理文件路径 */
export default {
  //  拨打电话
  callPhone(phone) {
    uni.makePhoneCall({
      phoneNumber: phone, //仅为示例
    })
  },
  // 判断是否为空或者都为空格
  isNullOrEmpty(value) {
    return value === null || value === "" || value === undefined || /^\s+$/gi.test(value) ? true : false
  },

  // 全局打开新标签页
  openUrl(url) {
    // 全局变量
    const baseUrl = window.location.origin
    if (url) {
      window.open(`${baseUrl}${url}`)
    }
  },

  // 判断对象是否包含空值
  /*
   ** @return flag
   ** @params 目标对象
   */
  paramsValidate(params) {
    let flag = true
    for (var key in params) {
      if (params[key] != "0" && !params[key]) {
        return false // 终止程序
      }
    }
    return flag
  },
  // 日期2023-01-03转几小时前，刚刚文字
  timeToWords(stringTime) {
    if (stringTime) {
      // 获取当前
      var minute = 1000 * 60
      var hour = minute * 60
      var day = hour * 24
      var week = day * 7
      var month = day * 30
      var time1 = new Date().getTime() //当前的时间戳
      var time2 = Date.parse(new Date(stringTime.replace(/-/g, "/"))) //指定时间的时间戳
      var time = time1 - time2

      var result = null
      if (time < 0) {
        result = stringTime
      } else if (time / month >= 1) {
        result = parseInt(time / month) + "月前"
      } else if (time / week >= 1) {
        result = parseInt(time / week) + "周前"
      } else if (time / day >= 1) {
        result = parseInt(time / day) + "天前"
      } else if (time / hour >= 1) {
        result = parseInt(time / hour) + "小时前"
      } else if (time / minute >= 1) {
        result = parseInt(time / minute) + "分钟前"
      } else {
        result = "刚刚"
      }
      return result
    }
  },
  // 米转千米
  kmUnit(m) {
    let v = null
    if (typeof m === "number" && !isNaN(m)) {
      if (m >= 1000) {
        v = (m / 1000).toFixed(3) + "km"
      } else {
        v = m + "m"
      }
    } else {
      v = "0m"
    }
    return v
  },
  // 秒转时分秒
  toHour(time) {
    // 转换为式分秒
    let h = parseInt((time / 60 / 60) % 24)
    h = h < 10 ? "0" + h : h
    let m = parseInt((time / 60) % 60)
    m = m < 10 ? "0" + m : m
    let s = parseInt(time % 60)
    s = s < 10 ? "0" + s : s
    // 作为返回值返回
    return [h, m, s]
  },

  /** 时间差计算
   * startTime 开始时间 2022-01-23 12:00:00
   * endTime 结束时间
   * type 返回格式，默认小时
   */
  timeDifference(startTime, endTime, type = "hour") {
    let hours = null
    if (startTime && endTime) {
      let beginDate = new Date(startTime.replace(/-/g, "/"))
      let endDate = new Date(endTime.replace(/-/g, "/"))
      switch (type) {
        case "hour":
          hours = (endDate - beginDate) / (1000 * 60 * 60)
          break
      }
    }
    return hours
  },
  /**
   * @param {Object} timespan
   * 获取当前时间
   * split 分割类型，默认-分割2023-04-13 10:24:30
   */
  getNowTime(split = "-", split2 = ":") {
    let now = new Date()
    let year = now.getFullYear() //获取完整的年份(4位,1970-????)
    let month = now.getMonth() + 1 //获取当前月份(0-11,0代表1月)
    let today = now.getDate() //获取当前日(1-31)
    let hour = now.getHours() //获取当前小时数(0-23)
    let minute = now.getMinutes() //获取当前分钟数(0-59)
    let second = now.getSeconds() //获取当前秒数(0-59)
    let nowTime = ""
    nowTime = year + split + this.fillZero(month) + split + this.fillZero(today) + " " + this.fillZero(hour) + split2 + this.fillZero(minute) + split2 + this.fillZero(second)
    return nowTime
  },
  /**
   * @param {Object} str 补0
   */

  fillZero(str) {
    var realNum
    if (str < 10) {
      realNum = "0" + str
    } else {
      realNum = str
    }
    return realNum
  },

  toWords(timespan) {
    let dateTime = new Date(timespan.replace(/-/g, "/")) // 将传进来的字符串或者毫秒转为标准时间
    let year = dateTime.getFullYear()
    let month = dateTime.getMonth() + 1
    let day = dateTime.getDate()
    let hour = dateTime.getHours()
    let minute = dateTime.getMinutes()
    // var second = dateTime.getSeconds()
    let millisecond = dateTime.getTime() // 将当前编辑的时间转换为毫秒
    let now = new Date() // 获取本机当前的时间
    let nowNew = now.getTime() // 将本机的时间转换为毫秒
    let milliseconds = 0
    let timeSpanStr
    milliseconds = nowNew - millisecond
    if (milliseconds <= 1000 * 60 * 1) {
      // 小于一分钟展示为刚刚
      timeSpanStr = "一分钟"
    } else if (1000 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60) {
      // 大于一分钟小于一小时展示为分钟
      timeSpanStr = Math.round(milliseconds / (1000 * 60)) + "分钟"
    } else if (1000 * 60 * 60 * 1 < milliseconds && milliseconds <= 1000 * 60 * 60 * 24) {
      // 大于一小时小于一天展示为小时
      timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60)) + "小时"
    } else if (1000 * 60 * 60 * 24 < milliseconds) {
      // 大于一天小于十五天展示位天
      timeSpanStr = Math.round(milliseconds / (1000 * 60 * 60 * 24)) + "天"
    } else if (milliseconds > 1000 * 60 * 60 * 24 * 15 && year === now.getFullYear()) {
      timeSpanStr = month + "-" + day + " " + hour + ":" + minute
    } else {
      timeSpanStr = year + "-" + month + "-" + day + " " + hour + ":" + minute
    }
    return timeSpanStr
  },
  // 时间戳转为时间
  toTime(time, pattern) {
    if (arguments.length === 0 || !time) {
      return null
    }
    const format = pattern || "{y}-{m}-{d} {h}:{i}:{s}"
    let date
    if (typeof time === "object") {
      date = time
    } else {
      if (typeof time === "string" && /^[0-9]+$/.test(time)) {
        time = parseInt(time)
      } else if (typeof time === "string") {
        time = time
          .replace(new RegExp(/-/gm), "/")
          .replace("T", " ")
          .replace(new RegExp(/\.[\d]{3}/gm), "")
      }
      if (typeof time === "number" && time.toString().length === 10) {
        time = time * 1000
      }
      date = new Date(time.replace(/-/g, "/"))
    }
    const formatObj = {
      y: date.getFullYear(),
      m: date.getMonth() + 1,
      d: date.getDate(),
      h: date.getHours(),
      i: date.getMinutes(),
      s: date.getSeconds(),
      a: date.getDay(),
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
      let value = formatObj[key]
      // Note: getDay() returns 0 on Sunday
      if (key === "a") {
        return ["日", "一", "二", "三", "四", "五", "六"][value]
      }
      if (result.length > 0 && value < 10) {
        value = "0" + value
      }
      return value || 0
    })
    return time_str
  },
  // 判断是否是json格式
  isJSON(str) {
    if (typeof str == "string") {
      try {
        var obj = JSON.parse(str)
        if (typeof obj == "object" && obj) {
          return true
        } else {
          return false
        }
      } catch (e) {
        return false
      }
    }
  },
  // 电话号码加密
  phonePas(string, type) {
    if (string) {
      let str = string.substring(3, 7)
      return string.replace(str, "****")
    }
  },
  checkPhone(phone) {
    let check = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/g.test(phone)
    return check
  },
  // 身份证校验
  checkIdNumber(idNum) {
    let c = /^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}[\dXx]$/g.test(idNum)
    return c
  },
  // 字符串截取,str，需要截取的字符串，num需要从第几位截取,endStr-结尾的字符串
  substr(str, num, endStr = "...") {
    if (str) {
      return str.length > num ? `${str.substring(0, num)}${endStr}` : str
    }
    // return `${str.substring(0,num)}${endStr}`
  },
  // 判断空对象
  isEmpty(obj) {
    return Object.keys(obj).length === 0
  },
  // 年月日转为日期格式
  // 日期转换
  formatYMD(data, format = "-") {
    if (data) {
      let time1 = data.split(" ")
      let time = time1[0].replace("年", "-").replace("月", "-").replace("日", " ")
      let arr = time.split("-")
      let y = arr[0]
      let m = arr[1] < 10 ? `0${arr[1]}` : arr[1]
      let r = arr[2] < 10 ? `0${arr[2]}` : arr[2]
      // 没有秒自动补全秒
      if (time1[1].split(":").length < 3) {
        return `${y}${format}${m}${format}${r}${time1[1] ? time1[1] + ":00" : ""}`
      }
      return `${y}${format}${m}${format}${r}${time1[1] ? time1[1] : ""}`
    } else {
      return null
    }
  },
  // 日期格式转年月日
  formatToYMD(data) {
    if (data) {
      let time1 = data.split(" ")
      let time = time1[0].replace("-", "年").replace("-", "月")
      let arr = time1[0].split("-")
      let y = arr[0]
      let m = arr[1] < 10 ? `${arr[1].split("0")[1]}` : arr[1]
      let enr = arr[2]
      let r = enr.split("0")[0] ? enr.split("0")[0] : enr.split("0")[1]
      return `${y}年${m}月${r}日 ${time1[1] ? time1[1] : ""}`
    } else {
      return null
    }
  },
  // 获取当前日期前一天或者后两天 date='2021-01-12',day=-1前一天，1，后一天
  getNextDate(date, day) {
    let result = []
    if (date) {
      let arr = date.split("-")
      if (arr[1] < 10 && arr[1].split("").length === 1) {
        if (arr[1].indexOf("0") === -1) {
          let str = arr[1].split("")
          arr[1] = `0${str[0]}`
        } else {
          arr[1] = arr[1]
        }
      }
      // 日小于10补0
      if (arr[2] < 10 && arr[2].split("").length === 1) {
        if (arr[2].indexOf("0") === -1) {
          let str = arr[2].split("")
          arr[2] = `0${str[0]}`
        } else {
          arr[2] = arr[2]
        }
      }
      result = arr.join("-")
    }
    var dd = new Date(result.replace(/-/g, "/"))
    dd.setDate(dd.getDate() + day)
    var y = dd.getFullYear()
    var m = dd.getMonth() + 1 < 10 ? "0" + (dd.getMonth() + 1) : dd.getMonth() + 1
    var d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate()
    // result = [y + "-" + m + "-" + d];
    return y + "-" + m + "-" + d
  },
  // 日期格式化
  formatDate: function (dateA, format = "yyyy-MM-dd HH:mm:ss", splitType = "-") {
    if (dateA) {
      const dateB = new Date(dateA.replace(/-/g, "/")).toJSON()
      const date = new Date(+new Date(dateB) + 8 * 3600 * 1000)
        .toISOString()
        .replace(/T/g, " ")
        .replace(/\.[\d]{3}Z/, "")
      let arr = date.split(" ")
      let dates
      switch (format) {
        case "yyyy-MM-dd HH:mm:ss":
          // dates = date
          dates = date.replace("-", splitType).replace("-", splitType)
          break
        case "yyyy-MM-dd":
          // dates = arr[0]
          dates = arr[0].replace("-", splitType).replace("-", splitType)
          break
        case "HH:mm:ss":
          dates = arr[1]
          break
        case "yyyy-MM-dd HH:mm":
          let arr2 = []
          arr2.push(date.split(":")[0])
          arr2.push(date.split(":")[1])
          // dates = arr2.join(":")
          dates = arr2.join(":").replace("-", splitType).replace("-", splitType)
          break
        case "MM-dd":
          let newArr = arr[0].split("-")
          let result = []
          if (newArr.length === 3) {
            newArr[1] < 10 ? (newArr[1] = newArr[1].split("0")[1]) : newArr[1]
            newArr[2] < 10 ? (newArr[2] = newArr[2].split("0")[1]) : newArr[2]
            result.push(newArr[1])
            result.push(newArr[2])
            dates = result.join(splitType)
          }
          break
        case "HH:mm":
          let newArr2 = arr[1].split(":")
          let arr3 = []
          arr3.push(newArr2[0])
          arr3.push(newArr2[1])
          dates = arr3.join(":")
          break
        case "MM-dd HH:mm":
          let oneArr = arr[0].split("-")
          let twoArr = arr[1].split(":")
          if (oneArr.length > 0 && twoArr.length > 0) {
            dates = `${oneArr[1]}${splitType}${oneArr[2]} ${twoArr[0]}:${twoArr[1]}`
          }
          break
        case "yyyy":
          let oneArr4 = arr[0].split("-")
          if (oneArr4.length > 0) {
            dates = `${oneArr4[0]}`
          }
          break
      }
      return dates
    }
  },
  /**
   * 数字转成汉字
   * @params num === 要转换的数字
   * @return 汉字
   * */
  numberToChinese(num) {
    var chineseNumArr = ["零", "一", "二", "三", "四", "五", "六", "七", "八", "九"]
    var chineseUnitArr = ["", "十", "百", "千", "万", "亿"]
    var chineseNum = ""
    var unitPos = 0
    var needZero = false
    if (num === 0) {
      return chineseNumArr[0]
    }
    while (num > 0) {
      var digit = num % 10
      if (digit === 0) {
        if (needZero) {
          chineseNum = chineseNumArr[0] + chineseNum
        }
        needZero = true
      } else {
        chineseNum = chineseNumArr[digit] + chineseUnitArr[unitPos] + chineseNum
        needZero = false
      }
      unitPos++
      num = Math.floor(num / 10)
    }
    return chineseNum
  },

  // 获取当前月的最后一天
  getLastDay(year, month) {
    const isLeapYear = (year % 4 == 0 && year % 100 != 0) || year % 400 == 0
    const maxDays = [1, 3, 5, 7, 8, 10, 12]
    const middleDays = [4, 6, 9, 11]
    month = Number(month)
    if (month == 2) {
      if (isLeapYear) {
        return 29
      } else {
        return 28
      }
    } else if (maxDays.includes(month)) {
      return 31
    } else if (middleDays.includes(month)) {
      return 30
    }
  },
  // 删除对象属性值为空的属性,obj-传入的对象
  removeProperty(obj) {
    Object.keys(obj).forEach((item) => {
      if (obj[item] === "" || obj[item] === undefined || obj[item] === null || obj[item] === "null") {
        Reflect.deleteProperty(obj, item)
      }
    })
    return obj
  },
  // 超过16位经字符串转number类型
  toNumber(str) {
    let result = null
    if (str) {
      let num = BigInt(str)
      result = String(num).split("n")[0]
    }
    return result
  },
  // base64转path
  base64ToPath(base64) {
    return new Promise(function (resolve, reject) {
      if (typeof window === "object" && "document" in window) {
        base64 = base64.split(",")
        var type = base64[0].match(/:(.*?);/)[1]
        var str = atob(base64[1])
        var n = str.length
        var array = new Uint8Array(n)
        while (n--) {
          array[n] = str.charCodeAt(n)
        }
        return resolve(
          (window.URL || window.webkitURL).createObjectURL(
            new Blob([array], {
              type: type,
            })
          )
        )
      }
      var extName = base64.match(/data\:\S+\/(\S+);/)
      if (extName) {
        extName = extName[1]
      } else {
        reject(new Error("base64 error"))
      }
      var fileName = Date.now() + "." + extName
      if (typeof plus === "object") {
        var bitmap = new plus.nativeObj.Bitmap("bitmap" + Date.now())
        bitmap.loadBase64Data(
          base64,
          function () {
            var filePath = "_doc/uniapp_temp/" + fileName
            bitmap.save(
              filePath,
              {},
              function () {
                bitmap.clear()
                resolve(filePath)
              },
              function (error) {
                bitmap.clear()
                reject(error)
              }
            )
          },
          function (error) {
            bitmap.clear()
            reject(error)
          }
        )
        return
      }
      if (typeof wx === "object" && wx.canIUse("getFileSystemManager")) {
        var filePath = wx.env.USER_DATA_PATH + "/" + fileName
        wx.getFileSystemManager().writeFile({
          filePath: filePath,
          data: base64.replace(/^data:\S+\/\S+;base64,/, ""),
          encoding: "base64",
          success: function () {
            resolve(filePath)
          },
          fail: function (error) {
            reject(error)
          },
        })
        return
      }
      reject(new Error("not support"))
    })
  },
}

function getLocalFilePath(path) {
  if (path.indexOf("_www") === 0 || path.indexOf("_doc") === 0 || path.indexOf("_documents") === 0 || path.indexOf("_downloads") === 0) {
    return path
  }
  if (path.indexOf("file://") === 0) {
    return path
  }
  if (path.indexOf("/storage/emulated/0/") === 0) {
    return path
  }
  if (path.indexOf("/") === 0) {
    var localFilePath = plus.io.convertAbsoluteFileSystem(path)
    if (localFilePath !== path) {
      return localFilePath
    } else {
      path = path.substr(1)
    }
  }
  return "_www/" + path
}

/* base64转文件路径 */
export function base64ToPath(base64) {
  return new Promise(function (resolve, reject) {
    if (typeof window === "object" && "document" in window) {
      base64 = base64.split(",")
      var type = base64[0].match(/:(.*?);/)[1]
      var str = atob(base64[1])
      var n = str.length
      var array = new Uint8Array(n)
      while (n--) {
        array[n] = str.charCodeAt(n)
      }
      return resolve(
        (window.URL || window.webkitURL).createObjectURL(
          new Blob([array], {
            type: type,
          })
        )
      )
    }
    var extName = base64.match(/data\:\S+\/(\S+);/)
    if (extName) {
      extName = extName[1]
    } else {
      reject(new Error("base64 error"))
    }
    var fileName = Date.now() + "." + extName
    if (typeof plus === "object") {
      var bitmap = new plus.nativeObj.Bitmap("bitmap" + Date.now())
      bitmap.loadBase64Data(
        base64,
        function () {
          var filePath = "_doc/uniapp_temp/" + fileName
          bitmap.save(
            filePath,
            {},
            function () {
              bitmap.clear()
              resolve(filePath)
            },
            function (error) {
              bitmap.clear()
              reject(error)
            }
          )
        },
        function (error) {
          bitmap.clear()
          reject(error)
        }
      )
      return
    }
    if (typeof wx === "object" && wx.canIUse("getFileSystemManager")) {
      var filePath = wx.env.USER_DATA_PATH + "/" + fileName
      wx.getFileSystemManager().writeFile({
        filePath: filePath,
        data: base64.replace(/^data:\S+\/\S+;base64,/, ""),
        encoding: "base64",
        success: function () {
          resolve(filePath)
        },
        fail: function (error) {
          reject(error)
        },
      })
      return
    }
    reject(new Error("not support"))
  })
}
