<!--
* @description 导航栏标题组件
* @fileName TitleCategory.vue
* @author liulian
* @date 2023/06/29 10:14:57
-->
<template>
  <div class="titleCategory">
    <div class="t_title" :style="titleStyle">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TitleCategory",
  props: {
    title: {
      type: String,
      default: "导航分类",
    },
    // 标题宽度
    titleStyle: {
      type: Object,
      default: () => {
        return {
          width: "240px",
          borderColor: "rgba(29,29,31,0.2)",
          color: "rgba(29,29,31,0.6)",
          fontSize: "12px",
          fontWeight: 400,
        }
      },
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
}
</script>

<style scoped lang="scss">
.titleCategory {
  .t_title {
    padding-bottom: 8px;
    border-bottom: 1px solid rgba(29, 29, 31, 0.2);
    min-height: 17px;
  }
}
</style>
