<!--
* @description banner
* @fileName Banner.vue
* @author liulian
* @date 2023/06/30 10:36:13
-->
<template>
  <div class="banner_container">
    <el-carousel :indicator-position="indicatorPosition" :loop="loop" :height="height">
      <el-carousel-item v-for="(item, index) in lists" :key="index">
        <img class="c_img cursor-pointer" :src="item.bannerUrl" alt="轮播图" @click="openUrl(item)" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "Banner",
  props: {
    height: {
      type: String,
      default: () => {
        return "500px"
      },
    },
    // 指示器的位置
    indicatorPosition: {
      type: String,
      default: () => {
        return "outside" // none
      },
    },
    // 是否循环显示
    loop:{
        type:Boolean,
        default:()=>{
            return true
        }
    },
    lists: {
      type: Array,
      default: () => {
        return [
          {
            url: "https://pic1.zhimg.com/v2-8d5ebb4f0fa67e76cbdd3c468f596e98_r.jpg",
          }, {
            url: "https://ts1.cn.mm.bing.net/th/id/R-C.1fc3b19804ccf90f9423d6221958593a?rik=rgZHYXdbxcONjA&riu=http%3a%2f%2fwww.kutoo8.com%2fupload%2fimage%2f91430511%2f001+(3).jpg&ehk=O29iaw1VDUT1poY3sWIhKxJuCsmcGFQXCD6Oxj5T4cM%3d&risl=&pid=ImgRaw&r=0",
          },
        ]
      },
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    /*
     * @Description: 打开链接
     * @MethodAuthor:  liulian
     * @Date: 2023-07-26 09:17:29
     * @param: '' 
    */
    openUrl(item){
      if(item.bannerLink){
      window.open(item.bannerLink,'_blank')
      }
    }
  },
}
</script>

<style scoped lang="scss">
.banner_container {
  .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
  .c_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
