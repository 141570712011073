// 定义英文语言包对象
export default {
  common: {
    KnowMore: "know more",
    NoData: "No data",
    Loading: "Loading",
    NoMore: "No further data available",
    paginationNext: "Next",
  },
  // 底部
  Footer: {
    ContactUs: "Contact Us",
    CompanyHotline: "Company Hotline",
    CustomerService1: "customer service: (025)84853032",
    CustomerService2: "customer service: 4000-025-828",
    CustomerService3: "Email: market@jsdnn.com",
    CustomerService4: "sales@jsdnn.com",
    CustomerService5: "support@jsdnn.com",
    CustomerService6: "develop@jsdnn.com",

    DigitalDesignConsulting: "Digital design consulting",
    DesignMember1: "Manager Li (Regional universities): +86 18618162992",
    DesignMember2: "Manager Xu (Southwest region): +86 17372261215",
    DesignMember3: "Manager Wu (Northwest region): +86 17730442539",
    DesignMember4: "Manager Xie (South China region): +86 18120179732",
    DesignMember5: "Manager Ai (North China region): +86 15840498611",
    DesignMember6: "",
    DesignMemberText: "",

    DigitalConstructionConsulting: "Digital construction consulting",
    ConstructionMember1: "Manager Sha：+65 90258773",
    ConstructionMember2: "Manager Zhang：+86 15852479508",
    ConstructionMemberText: "",

    Address: "Address",
    AddressDescribe: "7th Floor, Building 1, Qidi Science and Technology City Liye Park, No. 26 Zhishi Road, Jiangning District, Nanjing City, Jiangsu Province",
    WechatAccount: "",
  },
  // 头部菜单
  HeaderBar: {
    webtitle: 'Delauney',

    product_type1_title: "Digital construction product line",
    product_type1_item1_title: "Digital construction management platform",
    product_type1_item1_content: "Focusing on core businesses such as progress, quality, safety, investment, and archives in engineering construction",
    product_type1_item2_title: "Smart construction site management platform",
    product_type1_item2_content: 'Building an AIoT intelligent networked integrated solution for the "human-machine, material, method, and environment" of engineering construction',
    product_type1_item3_title: "Intelligent Factory Management Platform",
    product_type1_item3_content: "An intelligent factory that integrates industrial Internet of Things, digital twins, and production execution systems",
    product_type2_title: "Digital design software product line",
    product_type2_item1_title: "AIRoad —— Road Engineering Schematic Design System",
    product_type2_item1_content: "A 3D rapid scheme design software focused on scheme research",
    product_type2_item2_title: "EICAD —— Integrated Interactive Road And Interchange Design System",
    product_type2_item2_content: "Taking design efficiency and quality improvement as the core, introducing new digital design concepts",
    product_type2_item3_title: "TICAD Rail Transit Line Design System",
    product_type2_item3_content: "Focusing on the design of rail transit lines, meeting the needs of subways, light rails, trams, etc",
    product_type2_item4_title: "VRRoad ——Road Driving Simulation And Safety Evaluation System",
    product_type2_item4_content: "A software and hardware integrated solution for road geometry design and safety evaluation",
    product_type2_item5_title: "Delauney 3D reporting and presentation system",
    product_type2_item5_content: "A reporting tool for multi-genre projects based on a gameengine.",

    solution_type1_title: "SOLUTION",
    solution_type1_item1_title: "virtual construction",
    solution_type1_item2_title: "VR Technology",
    solution_type1_item3_title: "Oblique Photography",
    solution_type1_item4_title: "院校交通数字化与智能建造仿真实验室",


    cases_type1_title: "River-crossing Bridge",
    cases_type1_item1_title: "Zhang Jinggao Bridge BIM Collaborative Construction Management Platform",
    cases_type2_title: "Expressway",
    cases_type2_item1_title: "Shanghai-Wuzhou Expressway Reconstruction and Expansion BIM Collaborative Construction Management Platform",
    cases_type3_title: "River-crossing Tunnel",
    cases_type3_item1_title: "Jiangyin Jingjiang Tunnel BIM Collaborative Construction Management Platform",
    cases_type3_item2_title: "Hai Tai Yangtze River Tunnel BIM Collaborative Construction Management Platform",
    cases_type4_title: "Urban Expressway",
    cases_type4_item1_title: "Tenglong Avenue Intelligent Expressway Project Digital Construction Management Platform",
    cases_type5_title: "More",
    cases_type5_item1_title: "Huashe Yancheng Intelligent Manufacturing Industrial Park Digital Twin Platform",
    cases_type5_item2_title: "Fenhu Station Transportation Hub Supporting Project BIM Collaborative Construction Management Platform",

    support_type1_title: "Helpdocument",
    support_type2_title: "Didactic Presentation",
    support_type2_item1_title: "EICAD Didactic Presentation",
    support_type3_title: "Update Log",
  },
  // 首页
  pc_index: {
    Home: "HOME",
    Product: "PRODUCT",
    Solution: "SOLUTION",
    Cases: "CASES",
    NewsVideo: "NEWS & VIDEO",
    Download: "DOWNLOAD",
    Support: "SUPPORT",
    AboutUs: "ABOUT US",
    SaaS: "SAAS",

    MoreZixun: "More",
    Videos: "Videos",
    MoreVideos: "More videos",
    ConsultingAndEvents: "Consulting and events",

    Cpjzx_title: "The ultimate lightweight digital design",
    Cpjzx_title2: "and construction platform",
    Cpjzx_szsj: "Digital design",
    Cpjzx_szsj_title: "Digital design software product line",
    Cpjzx_szsj_AIRoad_title: "AIRoad rapid solution research system",
    Cpjzx_szsj_AIRoad_content: "The road rapid scheme design system relies on the self-developed BIM engine to build digital terrain based on GIS, aerial survey and remote sensing data, and quickly realizes scheme parameterization and intelligent design.It can improve the scheme design efficiency by 8 to 10 times and improve the overall scheme design efficiency by 20 to 30%.",
    Cpjzx_szsj_EICAD_title: "EICAD digital design series software",
    Cpjzx_szsj_EICAD_content: "The integrated interactive road and interchange design system EICAD, after nearly 30 years of development, has now covered highways, municipalities and rail transit, including field surveys, digital ground models, routes, interchanges, subgrade pavements, bridges, culverts, tunnels, Digital series design software for traffic engineering, water supply and drainage and other professional modules.",
    Cpjzx_szsj_VRRoad_title: "VRRoad road driving simulation and safety evaluation system",
    Cpjzx_szsj_VRRoad_content: "According to the OpenDrive international standard, quickly import design scheme models to create a simulated road network that accurately describes the features along the route. It implements a Level 3 lightweight traffic flow simulation algorithm, faithfully simulating the behavior of surrounding vehicles. It provides customers with a multi-degree-of-freedom VR simulated driving experience, capturing real-time data of the entire driving process.",
    Cpjzx_sjjz: "Digital construction",
    Cpjzx_sjjz_title: "Digital product construction line",
    Cpjzx_sjjz_szhjgpt_title: "Digital construction management platform",
    Cpjzx_sjjz_szhjgpt_content: "Digital Construction Management Platform is a comprehensive digital management platform for engineering construction aimed at collaborative management and office work among participating construction units during the transportation engineering construction cycle, providing a new technological means for the digital transformation of transportation engineering construction.",
    Cpjzx_sjjz_zhgd_title: "Smart construction site management platform",
    Cpjzx_sjjz_zhgd_content: "The intelligent factory management platform is based on advanced IoT, 5G, big data, BIM and other technologies. It uses sub -project as a refined management control object, and virtual construction as a technical means to carry out project quality and safety.",
    Cpjzx_sjjz_zhgc_title: "Intelligent factory management platform",
    Cpjzx_sjjz_zhgc_content: 'Follow the design of IS95 international standard MES, comprehensively use big data, cloud computing, 5G, IoT, AI, and BIM construction and other technologies. It aims to organize factory production, improve production efficiency, improve product quality, reduce production cycle, and finally realize intelligent management. Smart management and control, creating a "transparent factory", shifted from "manufacturing" to "intelligent manufacturing".',

    Szkcy_Title: "Digital, platform and ecological integrated solution",
    Szkcy_try: "Try it now",

    Szkcy_tab1_title: "Survey Cloud",
    Szkcy_tab1_subTab1_title: "Survey platform",
    Szkcy_tab1_subTab1_1_title: "Survey along the route",
    Szkcy_tab1_subTab1_1_content: "Provide rich geographical information and data support for line planning, design, construction and operation.",
    Szkcy_tab1_subTab1_2_title: "Numbers and places",
    Szkcy_tab1_subTab1_2_content: "Digitally model a real site and turn it into a digital model",
    Szkcy_tab1_subTab1_3_title: "3DGIS",
    Szkcy_tab1_subTab1_3_content: "Model, analyze, manage and visualize geospatial information based on 3D GIS",
    Szkcy_tab1_subTab1_4_title: "Electronic sand box",
    Szkcy_tab1_subTab1_4_content: "An interactive display platform integrating digital maps, 3D modeling, image simulation, data query, visual analysis and other functions",
    Szkcy_tab1_subTab1_5_title: "Smart video overlay",
    Szkcy_tab1_subTab1_5_content: "Overlay video images with other geospatial information to enable intelligent analysis and processing of video images",

    Szkcy_tab2_title: "Design Cloud",
    Szkcy_tab2_subTab1_title: "Digital design platform",
    Szkcy_tab2_subTab1_1_title: "Quick solution design",
    Szkcy_tab2_subTab1_1_content: "Through fast ideas, design and verification, quickly generate multiple solutions, and conduct detailed design and optimization",
    Szkcy_tab2_subTab1_2_title: "Road Engineering BIM",
    Szkcy_tab2_subTab1_2_content: "The process of digital modeling, collaborative design, construction and operation management of road engineering with BIM technology",
    Szkcy_tab2_subTab1_3_title: "Bridge Tunnel Project BIM",
    Szkcy_tab2_subTab1_3_content: "The process of digital modeling, collaborative design, construction and operation management of bridge tunnel projects with BIM technology",
    Szkcy_tab2_subTab1_4_title: "Port Channel Engineering BIM",
    Szkcy_tab2_subTab1_4_content: "Use BIM technology to digital modeling, collaborative design, construction and operation management of road ports",
    Szkcy_tab2_subTab1_5_title: "Intelligent wire selection design",
    Szkcy_tab2_subTab1_5_content: "Through digital technology and intelligent algorithms, optimize and solve complex design problems automatically",
    Szkcy_tab2_subTab1_6_title: "Smart cost quotation",
    Szkcy_tab2_subTab1_6_content: "Use digital technology and intelligent algorithms to analyze and budget design projects, so as to obtain accurate and reliable design quotes",

    Szkcy_tab3_title: "Construction Cloud",
    Szkcy_tab3_subTab1_title: "Digital construction platform",
    Szkcy_tab3_subTab1_1_title: "Digital twin large screen",
    Szkcy_tab3_subTab1_1_content: "Realize the comprehensive monitoring and management of buildings or engineering facilities, improve the operating efficiency and safety of facilities",
    Szkcy_tab3_subTab1_2_title: "Engineering business management",
    Szkcy_tab3_subTab1_2_content: "Use digital technology and information management systems to provide comprehensive and efficient business management support and decision -making basis for engineering projects",
    Szkcy_tab3_subTab1_3_title: "Land acquisition and demolition management",
    Szkcy_tab3_subTab1_3_content: "Realize the comprehensive monitoring and management of land acquisition and demolition projects, improve the efficiency, fairness and transparency of land acquisition and demolition projects",
    Szkcy_tab3_subTab1_4_title: "BIM+GIS Engine",
    Szkcy_tab3_subTab1_4_content: "Comprehensive monitoring and management of buildings and surrounding environments, improve design efficiency, reduce errors, reduce construction costs and risks",
    Szkcy_tab3_subTab1_5_title: "App",
    Szkcy_tab3_subTab1_5_content: "Realize the functions of mobile office, on -site management, data collection, improve work efficiency and management level",
    Szkcy_tab3_subTab2_title: "Smart factory platform",
    Szkcy_tab3_subTab2_1_title: "Big-data cockpit",
    Szkcy_tab3_subTab2_1_content: "Realize the comprehensive monitoring and management of the project, improve, and improve Management efficiency, accuracy and reliability",
    Szkcy_tab3_subTab2_2_title: "Smart Video Monitoring",
    Szkcy_tab3_subTab2_2_content: "Real -time monitoring and analysis of construction sites, equipment, personnel, etc.",
    Szkcy_tab3_subTab2_3_title: "Construction safety monitoring",
    Szkcy_tab3_subTab2_3_content: "For comprehensive monitoring and testing of the project, timely discover and solve the hidden safety hazards during construction, and improve the safety and efficiency in the construction process",
    Szkcy_tab3_subTab2_4_title: "Construction Machinery Monitoring",
    Szkcy_tab3_subTab2_4_content: "Discover and solve the hidden safety hazards in the use of construction machinery, improve the safety and efficiency of construction machinery",
    Szkcy_tab3_subTab2_5_title: "Concrete mixing together monitoring",
    Szkcy_tab3_subTab2_5_content: "Real -time monitoring and testing of the production process and equipment of the concrete field to ensure the quality and safety of the production of concrete production",
    Szkcy_tab3_subTab2_6_title: "Material inventory storage",
    Szkcy_tab3_subTab2_6_content: "Use IoT technology, sensors, RFID and other technologies to monitor and manage the inventory and warehousing of building materials",
    Szkcy_tab3_subTab3_title: "Smart construction platform",
    Szkcy_tab3_subTab3_1_title: "Production execution system",
    Szkcy_tab3_subTab3_1_content: "Using production automation technology, Internet of Things technology, cloud computing technology, etc., real -time monitoring and management of the production process",
    Szkcy_tab3_subTab3_2_title: "Intelligent tensor",
    Szkcy_tab3_subTab3_2_content: "Real -time monitoring and control of the tensor and grill process in the prestressed concrete structure",
    Szkcy_tab3_subTab3_3_title: "Steam maintenance monitoring",
    Szkcy_tab3_subTab3_3_content: "Real -time monitoring and testing of the maintenance process of concrete to ensure the quality and safety of concrete",
    Szkcy_tab3_subTab3_4_title: "Mobile collection terminal",
    Szkcy_tab3_subTab3_4_content: "se mobile devices and related software to achieve the collection, editing, analysis and management of engineering information at the construction site",
    Szkcy_tab3_subTab3_5_title: "Factory intelligent decision",
    Szkcy_tab3_subTab3_5_content: "Real -time monitoring, analysis and prediction of various data in the production process of the factory, and realize the intelligent decision -making and optimization of the production process",
    Szkcy_tab3_subTab3_6_title: "Digital twin factory",
    Szkcy_tab3_subTab3_6_content: "Realize the comprehensive monitoring and management of the production process, optimize the production process, improve production efficiency and quality, reduce production costs",

    Szkcy_tab4_title: "Management Cloud",
    Szkcy_tab4_subTab1_title: "Digital highway management",
    Szkcy_tab4_subTab1_1_title: "BIM lightweight modeling",
    Szkcy_tab4_subTab1_1_content: "Realize the comprehensive monitoring and management of the management process, improve the efficiency and quality of the pipeline, and reduce the cost of pipeline maintenance",
    Szkcy_tab4_subTab1_2_title: "Laser point cloud",
    Szkcy_tab4_subTab1_2_content: "Get facilities point cloud data through laser scanning technology to achieve the detection and maintenance of facilities",
    Szkcy_tab4_subTab1_3_title: "InSAR-Monitor",
    Szkcy_tab4_subTab1_3_content: "Under the condition of large -scale, high accuracy, high time and space resolution, the monitoring of surface deformation",
    Szkcy_tab4_subTab1_4_title: "Archive knowledge map",
    Szkcy_tab4_subTab1_4_content: "The archives information of the building or infrastructure will be modeling and managing the form of knowledge maps.",
    Szkcy_tab4_subTab1_5_title: "Data production service",
    Szkcy_tab4_subTab1_5_content: "The production, update, maintenance and management of information models in order to facilitate information management, maintenance and analysis in digital pipe care",
    Szkcy_tab4_subTab2_title: "Digital city management",
    Szkcy_tab4_subTab2_1_title: "Urban lightweight modeling",
    Szkcy_tab4_subTab2_1_content: "The city market view is digital modeling, and the modeling results are presented and applied in the form of lightweight",
    Szkcy_tab4_subTab2_2_title: "Digital municipality",
    Szkcy_tab4_subTab2_2_content: "Use digital technology to digital modeling, management and intelligent operations of urban infrastructure and public services",
    Szkcy_tab4_subTab2_3_title: "Digital water",
    Szkcy_tab4_subTab2_3_content: "Apply digital technology to digital modeling and intelligent management of water resources management, scheduling, allocation, monitoring, and protection",
    Szkcy_tab4_subTab2_4_title: "Smart community",
    Szkcy_tab4_subTab2_4_content: "Digital modeling and intelligent management of all aspects of the community to improve the quality of life of community residents and the efficiency of community management",
    Szkcy_tab4_subTab2_5_title: "Smart park",
    Szkcy_tab4_subTab2_5_content: "All aspects of the park, such as park planning, park construction, park management, park services, etc.",

    Hyal_Title: "Industry cases",
    Hyal_describe: "For different customer groups, we provide products and services that better match your business attributes.",
    Hyal_text1: "Would you like to understand industry solutions and application cases for the entire process, lifecycle, and all elements of infrastructure surveying and design, construction, operation, and maintenance?",
    Hyal_text2: "Welcome to contact the sales consultant of Jiangsu Delaunay for information.",
    Hyal_ContactUs: "Contact Us",
    Hyal_img1_src: require("@/assets/imagesEn/hyalImg/1@2x.png"),
    Hyal_img1_fillsrc: require("@/assets/imagesEn/hyalImg/1_sel@2x.png"),
    Hyal_img2_src: require("@/assets/imagesEn/hyalImg/2@2x.png"),
    Hyal_img2_fillsrc: require("@/assets/imagesEn/hyalImg/2_sel@2x.png"),
    Hyal_img3_src: require("@/assets/imagesEn/hyalImg/3@2x.png"),
    Hyal_img3_fillsrc: require("@/assets/imagesEn/hyalImg/3_sel@2x.png"),
    Hyal_img4_src: require("@/assets/imagesEn/hyalImg/4@2x.png"),
    Hyal_img4_fillsrc: require("@/assets/imagesEn/hyalImg/4_sel@2x.png"),

    Gywm_Title: "About Us",
    // Gywm_text1: `Jiangsu DinoNi is a high-tech enterprise dedicated to the research and development of digital and intelligent software solutions for the entire lifecycle of infrastructure. Established in 2018, the company's mission is to "build China's core for digital infrastructure" and provide comprehensive solutions for the digital transformation of the industry. The company's "Ziyun Infrastructure Digitalization Product Line" includes three major product matrices: design, construction, and operation and maintenance, aiming to achieve domestic substitution in the field of industrial infrastructure software. It integrates the entire lifecycle of engineering projects, activates data elements, and constructs digital twin models of infrastructure.`,
    // Gywm_text2: "Jiangsu DinoNi, a well-known brand in domestic infrastructure design software, has independently developed two major series of software products: Ziyun Infrastructure Digital Design Product Line and Ziyun Infrastructure Digital Construction Product Line. The digital design product line includes EICAD/EIBIM, an integrated interactive road and interchange design system, AIRoad, a rapid road scheme design system for highway engineering, VRRoad 1.0, a road driving simulation and safety evaluation system, and ZiCity, a digital reporting system for cities. The digital construction product line includes the Digital Construction Management Cloud Platform, Smart Construction Site Cloud Platform, Smart Beam Factory Cloud Platform, and CIM Management Platform, among others. These software products feature comprehensive functionality, advanced core algorithms, cross-platform integration, and wide application coverage.",
    // Gywm_text1: `Jiangsu Delauney Information Technology LLC was founded in 2018. The company is dedicated to providing engineers with software products and solutions for infrastructure digital design and intelligent construction. Our products are applied to the whole life cycle of planning, design, construction and operation and maintenance in the infrastructure industry (especially transportation and urban construction). Our products enhance work and collaboration efficiency, help customers accumulate valuable data assets, mine and activate data elements, and build digital twin models of infrastructure.`,
    // Gywm_text2: "As a famous brand of infrastructure digitization in China, Jiangsu Delauney's products include two product solutions: Digital Design and Intelligent Construction. The main products of digital design product solutions are: AIRoad (Road Engineering Road Rapid Schematic Design System), EICAD (Delauney Integrated Interactive Road and Interchange Design System), VRRoad (Road Driving Simulation and Safety Evaluation System), ZiCity3D (ZiCity 3D Digitized Reporting System), ZCDP (ZiCloud Design Collaboration and Result Management System). Intelligent construction product line includes: Digital Construction Management Platform, Intelligent Construction Site Platform, Intelligent Beam Factory Platform, UAV Laser Point Cloud Digital Survey System, and so on.",
    // Gywm_text3: "Along with the large-scale development of China's highway and urban construction, Delauney's software products have a development history of more than 20 years. Delauney's design software products have served more than 1,000 client companies and more than 20,000 designer users. In recent years, Delauney has helped clients complete road design programs with a total mileage of about 30,000 kilometers and more than 1,000 interchanges; Delauney's Intelligent Construction product line has been put into use in the construction of more than 40 major infrastructure projects, and it has provided highways, mega bridges and tunnels with BIM + GIS 3D visualization management, remote collaborative management APP, plan management and progress tracking, on-site inspection and quality control. GIS 3D visualization management, remote collaborative management APP, plan management and progress tracking, on-site inspection and quality control, intelligent safety supervision, and so on.",
    Gywm_text1: `　Jiangsu Delauney Information Technology Co., Ltd. was established in 2018. The company is dedicated to providing intelligent software products and solutions for the full lifecycle of infrastructure for engineers. Our products are applied in the infrastructure industry (particularly in transportation and urban construction) for planning, design, construction, and operation & maintenance across the entire lifecycle. Our products enhance work and collaboration efficiency, help clients accumulate valuable data assets, explore and activate data elements, and build digital twin models of infrastructure.`,
    Gywm_text2: `As a renowned brand in China's infrastructure digitalization, Delauney has two main product lines: digital design and intelligent construction. The design product line mainly includes AIRoad (Rapid Road Engineering Design System), EICAD (Integrated Interactive Road and Interchange Design System), VRRoad (Road Driving Simulation and Safety Evaluation System), Report 3D (3D Presentation System), Delivery 3D (Design Result Management System), and more. The construction product line includes the Digital Construction Management Platform, Smart Construction Site Platform, Smart Beam Factory Platform, and others.`,
    Gywm_text3: ` With the large-scale development of highways and urban construction in China, Delauney's software products have a development history of over 20 years. We have obtained 11 invention patents and 39 software copyrights. Delauney's software products serve over a thousand client enterprises and more than 20,000 designer users. In recent years, we have helped clients complete road design schemes totaling approximately 30,000 kilometers and over 1,000 interchanges. Delauney’s intelligent construction products have been used in over 40 major infrastructure projects, providing BIM+GIS 3D visualization, collaborative management, schedule management and tracking, on-site inspection and quality control, intelligent safety supervision, electronic archives, and more for highways, large bridges, and tunnel projects.`,

    Hzhb_Title: "Business partner",
    Hzhb_describe: "Jiangsu DinoNi has established deep cooperative relationships with more than 50 enterprises, including China Communications Planning and Design Institute Co., Ltd., China Communications Tunnel and Bridge Design and Research Institute Co., Ltd., Zhejiang Communications Construction Group Co., Ltd., and Tianjin Municipal Institute of Urban Planning and Design.",

  },
  // 产品-数字化建设管理平台
  productSzhjsgl: {
    banner: require("@/assets/imagesEn/productImg/banner.png"),

    section1_title: "Platform Introduction",
    section1_content: "The Ziyun Infrastructure Digital Construction Management Platform is a digital comprehensive management platform for engineering construction aimed at collaborative management and office work among participating construction units during the transportation engineering construction cycle. Focusing on core businesses such as progress quality, safety, measurement, and archives in engineering construction, we deeply integrate BIM technology to achieve digital transformation of engineering construction projects, providing a new technological means for the digital transformation of transportation engineering construction.",
    section2_title: 'Typical project "one bridge, one road, one tunnel"',
    section3_title: "Core competencies",
    section3_tab1_title: "BIM Collaboration",
    section3_tab1_content1: "Synchronized display of core",
    section3_tab1_content2: "business data BIM models",
    section3_tab1_content3: "",
    section3_tab2_title: "Planned Progress",
    section3_tab2_content1: "Project Plan Preparation",
    section3_tab2_content2: "Regular Aerial Comparison of Progress",
    section3_tab2_content3: "Actual progress feedback",
    section3_tab3_title: "Quality Control",
    section3_tab3_content1: "Process Inspection | Material Traceability",
    section3_tab3_content2: "Hidden Danger Management",
    section3_tab3_content3: "",
    section3_tab4_title: "Measurement & Payment",
    section3_tab4_content1: "Contract Management",
    section3_tab4_content2: "Measurement and Payment",
    section3_tab4_content3: "Change Management",
    section3_tab5_title: "Safety Management",
    section3_tab5_content1: "Security System | Risk Control",
    section3_tab5_content2: "Major Hazardous Projects",
    section3_tab5_content3: "Hazard investigation",
    section3_tab6_title: "Electronic Archives",
    section3_tab6_content1: "Quality Data System | Test Data System",
    section3_tab6_content2: "Statistical analysis | Electronic signatures",
    section3_tab6_content3: "Automated circulation",
    section4_title: "Platform Highlights",

    ptlds_default1: require("@/assets/imagesEn/productImg/ptld_1.png"),
    ptlds_cover1: require("@/assets/imagesEn/productImg/ptld_1@2x.png"),
    ptlds_default2: require("@/assets/imagesEn/productImg/ptld_2.png"),
    ptlds_cover2: require("@/assets/imagesEn/productImg/ptld_2@2x.png"),
    ptlds_default3: require("@/assets/imagesEn/productImg/ptld_3.png"),
    ptlds_cover3: require("@/assets/imagesEn/productImg/ptld_3@2x.png"),
    ptlds_default4: require("@/assets/imagesEn/productImg/ptld_4.png"),
    ptlds_cover4: require("@/assets/imagesEn/productImg/ptld_4@2x.png"),
    ptlds_default5: require("@/assets/imagesEn/productImg/ptld_5.png"),
    ptlds_cover5: require("@/assets/imagesEn/productImg/ptld_5@2x.png"),
    ptlds_default6: require("@/assets/imagesEn/productImg/ptld_6.png"),
    ptlds_cover6: require("@/assets/imagesEn/productImg/ptld_6@2x.png"),
    ptlds_default7: require("@/assets/imagesEn/productImg/ptld_7.png"),
    ptlds_cover7: require("@/assets/imagesEn/productImg/ptld_7@2x.png"),
    ptlds_default8: require("@/assets/imagesEn/productImg/ptld_8.png"),
    ptlds_cover8: require("@/assets/imagesEn/productImg/ptld_8@2x.png"),
    ptlds_default9: require("@/assets/imagesEn/productImg/ptld_9.png"),
    ptlds_cover9: require("@/assets/imagesEn/productImg/ptld_9@2x.png"),

  },
  // 产品-智慧工地
  zhgd: {
    banner: require("@/assets/imagesEn/productImg/zhgd_banner@2x.png"),

    section1_title: "Platform Introduction",
    section1_content: 'The "Ziyun Infrastructure · Smart Construction Site Management Platform" is based on advanced I technology О T. 5G, big data, BIM and other technologies, with sub projects as fine control objects and virtual construction as technical means, carry out visual, integrated, and collaborative management of project quality and safety.',
    section2_title: "Platform Construction Principles",
    section2_content: 'Build an "AIoT comprehensive perception construction site" by monitoring and monitoring hardware; A standardized, digital, and intelligent control model is implemented around the "human-machine material method environment" to support software and hardware application systems for construction site management.',
    section2_img1: require("@/assets/imagesEn/productImg/ptjsyz@2x.png"),
    section3_title: "Smart Transportation Solutions",
    section3_img1: require("@/assets/imagesEn/productImg/zhysjjfa@2x.png"),
    section3_content: 'Mainly includes: 3600 panoramic active safety prevention and control, intelligent monitoring of cargo box, driver behavior analysis, enterprise supervision platform. ',
    section4_title: "Digital Construction · Intelligent Sensing",
    section4_title2: "Big Data Analysis · AI Assisted Decision Making",

    section4_list1_item1: "Positioning of Personnel",
    section4_list1_item2: "Equipment Condition Monitoring",
    section4_list1_item3: "Intelligent Weighbridge",
    section4_list1_item4: "Laboratory Data Control",
    section4_list1_item5: "Mixing Plant<br/>Data Control",
    section4_list1_item6: "Transportation Monitoring of Mixes",
    section4_list1_item7: "Intelligent Paving",
    section4_list1_item8: "Intelligent Crush",
    section4_list1_item9: "Video Surveillance<br/>AI Image Recognition",
    section4_list1_item10: "Environmental Monitoring",
    section4_list1_item11: "Monitoring Measurement",

    section4_list2_item1: "People management",
    section4_list2_content1: "Personnel information management<br/>Attendance management 丨 Labor management",
    section4_list2_item2: "Materials Management",
    section4_list2_content2: "Receiving and inspection management<br/>QR code collaterals 丨 Material management system",
    section4_list2_item3: "Integrated management",
    section4_list2_content3: "Organization management 丨 Contract management<br/>Progress management 丨 Office management",
    section4_list2_item4: "Quality Management",
    section4_list2_content4: "Process management 丨 Test and inspection control<br/>Roadbed construction control",
    section4_list2_item5: "ECO-Friendly",
    section4_list2_content5: "Dust and noise monitoring<br/>Water and electricity energy consumption monitoring ",
    section4_list2_item6: "Security Management",
    section4_list2_content6: "Risk source management 丨 Dangerous engineering control<br/>Video management 丨 Safety education",
    section4_list2_item7: "Equipment Management",
    section4_list2_content7: "Bridge crane safety monitoring<br/>Gantry crane safety monitoring ",
    section4_list2_item8: "BIM Management",
    section4_list2_content8: "Construction simulation<br/>Progress visualization management",
    section4_list2_item9: "Basic Configuration",
    section4_list2_content9: "Production modeling configuration<br/>Product process configuration",
    section4_list2_item10: "On-site data acquisition",
    section4_list2_content10: "Reinforcement cage installation collection<br/>Formwork assembly collection",
    section4_list2_item11: "Scheduling and Dispatching",
    section4_list2_content11: "Contract order management 丨 Job plan management<br/>Intelligent production line scheduling",
    section4_list2_item12: "Quality Management",
    section4_list2_content12: "Incoming material IQC inspection<br/>Production process inspection",
    section4_list2_item13: "Security Management",
    section4_list2_content13: "Human-vehicle positioning 丨 Safety inspection list<br/>Hidden danger management丨 Safety statistics ",
    section4_list2_item14: "Collaborative Message",
    section4_list2_content14: "Daily notification 丨 Scheduling release<br/>Process operation reminder 丨 Abnormal quality alarm",
    section4_list2_item15: "Warehouse Logistics Management",
    section4_list2_content15: "Receiving and warehousing management<br/>Distribution and material managemen",
    section4_list2_item16: "Equipment Management",
    section4_list2_content16: "Equipment files 丨 Equipment maintenance management<br/>Equipment repair management",
    section4_list2_item17: "Reporting Center",
    section4_list2_content17: "Order completion and shipment statistics<br/>Scheduling Gantt Chart",
    section4_list2_item18: "Workshop material management",
    section4_list2_content18: "Work order material distribution management<br/>Workshop lineside inventory management",

  },
  // 产品-智能工厂
  zngc: {
    banner: require("@/assets/imagesEn/productImg/zngc_banner@2x.png"),

    section1_title: "Platform Introduction",
    section1_content: '"Purple Cloud Infrastructure - Intelligent Factory Management Platform" follows the ISA95 international standard MES design, and comprehensively applies the technologies of big data, cloud computing, 5G, IoT, AI and BIM construction, aiming at organizing the factory scheduling, improving the production efficiency, enhancing the product quality, reducing the production cycle, and ultimately realizing the intelligent management, intelligent management and control, and creating a transparent factory, and shifting from "manufacturing" to "smart manufacturing".',
    section2_title: "Digital Factory Intelligent Sensing",
    section2_content: "All-round intelligent analysis, all-round intelligent monitoring, all-weather real-time monitoring and management, supporting intelligent hardware and software, the system can be integrated monitoring, to achieve intelligent management. Intelligent control to create a transparent factory.",
    section2_list1_item1: "Human-vehicle<br/>Locating",
    section2_list1_item2: "Lifting<br/>Inspection",
    section2_list1_item3: "Intelligent<br/>Weighbridge",
    section2_list1_item4: "AI Hazard<br/>Recognition",
    section2_list1_item5: "Warehouse<br/>Management",
    section2_list1_item6: "Access<br/>Control",
    section2_list1_item7: "Box Monitoring",
    section2_list1_item8: "Atmospheric<br/>Monitoring",
    section2_list1_item9: "Water<br/>Monitoring",
    section2_list1_item10: "Battery Level<br/>Monitoring",
    section2_list1_item11: "Spray<br/>Control",
    section2_list1_item12: "Video<br/>Surveillance",
    section2_list1_item13: "Mold Support<br/>Monitoring",
    section2_list1_item14: "Component<br/>Tracking",
    section2_list1_item15: "Water Quality<br/>Monitoring",

    section3_title: "Platform Features",
    section3_list1_item1: 'Based on the rules of mold table configuration, automatic order splitting and scheduling, automatic configuration of weekly production capacity, automatic scheduling of production plan to the production line, to provide the function of withdrawing and transferring the order, and one-click release of the work assignment.',
    section3_list1_item2: "The unique identity of the component is used for life, and the component archive can be scanned at any time.",
    section3_list1_item3: "Accurate records of the production process, accurate tracking of product files; rapid location of problematic batches of materials; accurate statistical analysis of the product's complete production process file, to ensure product quality.",
    section3_list1_item4: "Inventory data is updated in real time to quickly locate building blocks; mobile dumps are captured in inventory; and pedestal recommendations are automatically made based on rules.",
    section3_list1_item5: "Instant collaborative messaging to guide production operations.",
    section3_list1_item6: "Immersive virtual reality scenes.",
    section3_list1_item7: "Build real-time and efficient data statistics and analysis for managers to assist decision-making.",
    section3_list1_item8: "Real-time control of personnel status.",
    section3_list1_item9: "Establishment of the construction of a complete production file for quality analysis and evaluation.",
    section3_list1_item10: "Full life cycle management of production equipment.",

  },
  // 产品-AIRoad
  airoad: {
    banner: require("@/assets/imagesEn/productImg/airoad_banner@2x.png"),

    section1_title: "Product Introduction",
    section1_content: `"AIRoad Road Engineering Schematic Design System" is a 3D rapid scheme design software focusing on preliminary research. Based on the 3D rendering engine with independent intellectual property rights, the software follows the 2D design habit and incorporates the concept of automatic design, realizing the overall solution for the whole profession from design input → rapid scheme layout → scheme display, comparison and selection → result output.`,
    section2_title: "Function Highlights",
    section2_list1_item1: "Fast program deployment and easy collaborative modifications",
    section2_list1_content1: "Program layout through simple parameter input to achieve automatic layout, the designer can be clicked and dragged by the mouse, you can quickly edit and modify the relevant program, to achieve one-click collaboration.",
    section2_list1_item2: "Easy design input",
    section2_list1_content2: "It supports downloading and uploading of various terrain data, importing of CAD drawings and JPG images, etc., which meets the requirements for inputting terrain and control factors for engineering feasibility and program studies.",
    section2_list1_item3: "The output is complete",
    section2_list1_content3: "The output includes workable drawings, scene models, roaming videos, data files, major quantities and Converse diagrams, scheme comparison, scheme presentation and requirements for the transfer of design data to the detailed design stage.",
    section2_list1_item4: "Multi-professional synergy",
    section2_list1_content4: "方案布设时通过简单的参数输入，实现自动布设，设计者可通过鼠标的点击和拖拽，就可以迅速的对相关方案进行编辑和修改，实现一键协同。",
    section2_list1_content4_title1: "Interchange design",
    section2_list1_content4_content1: "沿用了 EICAD模式法设计思路，并且可以实现自动拉坡，一键生成所有的立交端部实体。",
    section2_list1_content4_title2: "Bridge Design ",
    section2_list1_content4_content2: "",
    section2_list1_content4_title3: "Tunnel Design",
    section2_list1_content4_content3: "",
    section2_list1_content4_title4: "Intersection network design",
    section2_list1_content4_content4: "",
  },
  // 产品-EICad
  eicad: {
    banner: require("@/assets/imagesEn/productImg/eacid_banner@2x.png"),

    section1_title: "Product Introduction",
    section1_content1: 'EICAD digital design software series" is based on the basic module of integrated interactive road and interchange design, and after years of development, it has formed a whole family of digital design software covering highway, municipal and rail transportation, including field survey, digital ground model, route, interchange, roadbed pavement, bridge, tunnel, traffic engineering, water supply and drainage, etc. EICAD design software has always insisted on "improving design efficiency and design quality", and continuously innovated and enriched product functions to create value for customers and provide quality digital software products for infrastructure construction. EICAD design software always adheres to the principle of "improving design efficiency and design quality", continues to innovate and enrich product functions, creates value for customers, and provides high-quality digital software products for infrastructure construction.',
    section1_content2: 'As the mainstream design software in the transportation industry, EICAD series software has established a wide range of long-term win-win relationship with the users, and its partners include hundreds of design institutes, universities and construction management units of highway, municipal, railway and urban construction in various provinces, cities and autonomous regions across the country.',
    section1_img: require("@/assets/imagesEn/productImg/model_bg@2x.png"),

    section2_title: "Software Features and Benefits",
    section2_item1: "Flat vertical and horizontal rapid design",
    section2_item2: "Data linkage Easy to modify",
    section2_item3: "Complex routes and interchanges Adaptable",
    section2_item4: "With automatic design module",
    section2_item5: "Flat vertical and horizontal rapid design",
    section2_item6: "Three-position positive design",
    section2_item7: "Specialty Coverage",
    section2_item8: "Automated drawing and drawing management",
    section2_img: require("@/assets/imagesEn/productImg/eicad_img@2x.png"),

    section3_title: "Interchange Design",
    section3_content1: 'EICAD5.0 Interchange module brings together the domestic leading route curve type design ideas, the user can use data input, mouse drag and other methods to deal with line position more flexibly. The arbitrary dragging and dropping of a number of pinch points on the road centerline is like fiddling with a rope on the desktop, which is convenient for users to quickly and accurately complete the interchange wiring and mapping.',
    section3_content2: 'The software also realizes fully automatic wiring of common interchange forms such as single horn, double horn, diamond, cloverleaf, etc. Using this function, the completion time of a plan view is less than 1 minute.',

    section4_title: "Design of Slope Joints",
    section4_content1: "EICAD 5.0 Interchange connection design is both intuitive and efficient.",

    section5_title: "Functional Module",
    section5_img_default1: require("@/assets/imagesEn/productImg/eicad_item1.png"),
    section5_img_cover1: require("@/assets/imagesEn/productImg/gnmk_1@2x.png"),
    section5_img_default2: require("@/assets/imagesEn/productImg/eicad_item2.png"),
    section5_img_cover2: require("@/assets/imagesEn/productImg/gnmk_2@2x.png"),
    section5_img_default3: require("@/assets/imagesEn/productImg/eicad_item3.png"),
    section5_img_cover3: require("@/assets/imagesEn/productImg/gnmk_3@2x.png"),
    section5_img_default4: require("@/assets/imagesEn/productImg/eicad_item4.png"),
    section5_img_cover4: require("@/assets/imagesEn/productImg/gnmk_4@2x.png"),
    section5_img_default5: require("@/assets/imagesEn/productImg/eicad_item5.png"),
    section5_img_cover5: require("@/assets/imagesEn/productImg/gnmk_5@2x.png"),
    section5_img_default6: require("@/assets/imagesEn/productImg/eicad_item6.png"),
    section5_img_cover6: require("@/assets/imagesEn/productImg/gnmk_6@2x.png"),
    section5_img_default7: require("@/assets/imagesEn/productImg/eicad_item7.png"),
    section5_img_cover7: require("@/assets/imagesEn/productImg/gnmk_7@2x.png"),
    section5_img_default8: require("@/assets/imagesEn/productImg/eicad_item8.png"),
    section5_img_cover8: require("@/assets/imagesEn/productImg/gnmk_8@2x.png"),
    section5_img_default9: require("@/assets/imagesEn/productImg/eicad_item9.png"),
    section5_img_cover9: require("@/assets/imagesEn/productImg/gnmk_9@2x.png"),

  },
  // 产品-TICad
  ticad: {
    banner: require("@/assets/imagesEn/productImg/ticad_banner@2x.png"),

    section1_title: "Product Introduction",
    section1_content1: '"TICAD Railway Line Design Software" can be used in various stages of line design for underground railroads, light railways, streetcars and other lines, adapting to the needs of users at different design stages. On the basis of the current relevant technical specifications, the software is closely related to the actual needs of design; it embodies new ideas and methods of line design, and through continuous upgrading and iteration, the user interface is more friendly and easier to learn.',
    section1_content2: 'Turnout design, editing and querying | Direct and curved crossing design | Drawing and labeling of stations and turnouts | Automatic search for double track spacing | Automatic generation of new offsets',
  },
  // 产品-VRRoad
  vrRoad: {
    banner: require("@/assets/imagesEn/productImg/vrroad_banner.png"),

    section1_title: "Product Introduction",
    section1_content1: 'VRRoad, a road driving simulation and safety evaluation system, is a software and hardware integrated solution for road geometric design and safety evaluation. The system can quickly import the design results, and refer to the OpenDrive standard to generate a simulated road network that accurately describes the characteristics of the lanes and roads along the road, realizes the synchronous collection of experimental data with complete information, and supports the drive of multi-degree-of-freedom motion platforms to provide a realistic driving sense of body.',

    section1_img1: require("@/assets/imagesEn/productImg/vrRoadImg1.png"),
    section1_img2: require("@/assets/imagesEn/productImg/vrRoadImg2.png"),
    section1_img3: require("@/assets/imagesEn/productImg/vrRoadImg3.png"),
    section1_img4: require("@/assets/imagesEn/productImg/vrRoadImg4.png"),

  },
  // 产品-Report3D
  Report3D: {
    banner: require("@/assets/images/productImg/bannerReport3Dlen.png"),

    section1_content: "Report3D, a 3D reporting and presentation system, is a reporting tool developed based on a game engine for multiple types of projects. It realises efficient scene creation with real-time rendering and complex production functions of PPT, enabling users to view and manipulate digital twin models in real time. No complex installation or configuration is required, lowering the threshold of 3D scene use and enabling more people to easily participate in 3D scene building and PPT reporting.",
    section2_content: "The system explores the new mode of results presentation in the digital era with a three-dimensional, dynamic and interactive reporting method, to tell the project in the form of a microfilm and create an immersive experience scene.",
    section3_title: "Digital twin editor based on a game engine",
    section3_content: "The software is developed based on the game engine and supports efficient scene creation, which can lay people, vehicles and trees in the scene to improve the authenticity and aesthetics of the scene; it meets a variety of needs in the application of engineering projects; and it also supports the production of reporting pages in the software, inserting text, pictures, tables, videos, etc., to meet the requirements of reporting.",
    section3_content2: "The software provides an intuitive, interactive visual editing environment that enables users to easily create, edit and manage scene models. This visual environment includes drag-and-drop models, real-time previews, interactive adjustment of parameters, and other features that enable users to quickly and intuitively build complex reporting scenarios. PurpleCity integrates lane information delivered by AIRoad+VRRoad software and allows for traffic flow simulation to further enhance the simulation.",
    section4_title: "Easy to create 3D immersive presentation PPTs",
    section4_content: "Report3D is locally installed and deployed, thus reducing the difficulty of use and ensuring higher data security. As a tool-level system with low learning cost, the software retains the 2D interaction habit and is consistent with the operation of PPT software, which makes it more convenient for users to create immersive storyboards and significantly reduces the learning cost. Designers can quickly master the production method and operate independently, which has the basis for rapid popularisation and promotion of application.",

    img_title1: 'Report3D Project Manager',
    img_title2: 'Report3D Material Plaza',

    section1_img1: require("@/assets/images/productImg/Report3DImg1.png"),
    section1_img2: require("@/assets/images/productImg/Report3DImg2.png"),
    section1_img3: require("@/assets/images/productImg/Report3DImg3.png"),
    section1_img4: require("@/assets/images/productImg/Report3DImg4.png"),
    section1_img5: require("@/assets/images/productImg/Report3DImg5.png"),
    section1_img6: require("@/assets/images/productImg/Report3DImg6.png"),
    section1_img7: require("@/assets/images/productImg/Report3DImg7.png"),
    section1_img8: require("@/assets/images/productImg/Report3DImg8.png"),

    videoUrl: '2024-07-05/788c5e66-4616-4320-adea-9b0c7afc9daa.mp4',

    cover1: require("@/assets/images/productImg/Report3DCoverlen.png"),
  },
  // 解决方案-虚拟建设
  virtualConstruction: {
    section1_title: "Virtual Construction",
    section1_content1: 'In traditional project management, due to the lack of means and tools, only diagrams and charts can be used to describe the construction process, scheduling and resource allocation. It is difficult to consider the complex site conditions in the organization plan, and it is impossible to express the complex relationship between the work interfaces. Dynamic changes in the construction process, the data required for optimal allocation of resources and the real conditions of the construction site can not be expressed. This results in unrealistic construction progress plan, untimely response to site changes, uneven distribution of resources, etc. This empirical management mode cannot adapt to the requirements of modern infrastructure refinement construction management.',
    section1_content2: "Enterprises use the BIM model to collect, analyze and store the data required in construction, and then associate the time dimension 4DBIM progress management model, with the relevant BIM software to simulate the progress of the project, reasonably formulate the construction plan, accurately master the construction process, optimize the use of construction resources and scientifically lay out the site, and unify the management of the entire project's construction progress, resources and quality. The construction progress, resources and quality of the whole project can be managed and controlled in a unified way, so as to shorten the construction period, reduce the cost and improve the quality.",
    section1_content3: 'The whole process of pipe sheet production in pipe sheet factory: in the production workshop of pipe sheet factory, the steel bar will be cut off, bent, welded, composed of reinforcing steel cage after lifting, and then clean up the prefabricated molds of pipe sheet, spraying release agent, measuring the size, and then the reinforcing steel cage into the mold, molding, installation of embedded parts, followed by the concrete pouring, surface collection, and finally the pipe sheet will be steamed, demolded, flipped, water-conditioned, and shipped. It simulates the whole process of real pipe sheet prefabrication production, reflecting interferences and conflicts visually and optimizing the construction process continuously.',
  },
  // 解决方案-VR技术
  vr: {
    section1_title: "Virtual Reality Technology and Applications",
    section1_content1: 'Nowadays, virtual reality technology is widely used in highway and bridge construction as well as all kinds of engineering construction.',
    section1_content2: 'The technology is strong, easy to operate, powerful, highly visualized, what you see is what you get, his highway and road and bridge construction has a very broad application prospects, such as engineering background introduction, section profile, technical data, cross-section, etc., electronic map, sound, images, animation, and with the core of the virtual technology to produce interactions, so as to achieve the demonstration of the scene of the navigation, positioning and background information introduction and so on. Many practical and convenient functions.',
  },
  // 解决方案-倾斜摄影
  photo: {
    section1_title: "Modeling and Application of UAV Tilt Photography",
    section1_content1: ' With the development of science and technology, the surveying and mapping industry is demanding more and more accuracy and timeliness of geographic information data, and labor cost and time cost also bring great pressure and burden to the industry, so the surveying and mapping industry needs solutions that can quickly, efficiently, and accurately obtain geographic information and data.',
    section1_content2: 'Massive amount of inclined model data meets the needs of real estate, land, urban management, smart city, planning, water conservancy, energy extraction and other industry applications to provide a basic platform. Meanwhile, the combination of GPU-based 3D spatial analysis function and the high accuracy of the tilt photography model can quickly react to the detailed information around the place of occurrence in case of emergency, which can sometimes even play a decisive role for the safety of personnel and property in emergency operations.',
  },
  // 行业案例-跨江大桥
  crossRiverBridge: {
    section1_title: "Zhang Jinggao Yangtze River Bridge Intelligent Construction BIM Collaborative Management Platform",
    section1_content1: "The Zhang Jinggao Yangtze River Bridge is a dual-purpose public-railway bridge across the Yangtze River between Yichang City and Xiangyang City in Hubei Province, China. Built in 1992 and opened to traffic in 1995, it is China's first independently designed, constructed and financed large suspension bridge for both public and railroads, and the first dual-purpose bridge across the Yangtze River in China.",
    section1_content2: 'Zhang Jinggao Yangtze River Bridge is about 4.572 kilometers long, including the main bridge and approach bridge. The main bridge adopts a suspension bridge structure, with a main span of 1088 meters, and 8 cable-stayed bridges on each side, with a total width of 33.2 meters, and the bridge deck is divided into two levels, the upper level for the highway, and the lower level for the railroad. The approach bridge is about 2 kilometers long, of which the highway bridge is about 1.1 kilometers long and the railroad bridge is about 0.9 kilometers long.',
    section1_content3: 'Zhang Jinggao Yangtze River Bridge adopts a number of advanced technologies, including large steel structure manufacturing technology, cable tensioning technology, vibration testing technology, etc. It is a milestone in the history of Chinese bridge construction. The completion of the bridge has shortened the travel time between the two sides of the Yangtze River, promoted the economic development of the central region, and has become one of the landmarks of engineering technology in China.',
    section1_content4: "",
    section1_content5: "",
    section1_content6: "",
    section1_content7: "",
    section1_content8: "",

    section2_title: "Smart Construction BIM Collaboration Platform",
    section2_content1: 'Zhang Jinggao Yangtze River Bridge is characterized by large project scale, diverse components and complex technology. The project includes the construction of towers, beams, cables, anchors and other components, including the ground wall, sinkholes and other foundation forms and steel structure, prefabricated parts manufacturing and other construction processes, at the same time, the project has a large number of parties involved in the construction and the work points are scattered, which has put forward a new challenge to the construction management, and therefore needs to realize the whole process of the construction period, the whole element of the digital management by means of new technology, and Huashe used the intelligent construction BIM collaborative platform to provide technical support for the construction and refinement of the management of the project. Huashi uses the intelligent construction BIM collaboration platform to provide technical support for the project construction.',

    section3_title: '"One Cloud, Three Ends" the whole process of intelligent managementv',
    section3_content1: "Zhang Jinggao construction management platform focuses on the characteristics of the mega-span suspension bridge project, based on the 'three ends and one cloud' architecture to realize the progress of the construction period, quality, safety, measurement, archives and other key business intelligent collaborative management; integration of the project's intelligent construction site, intelligent construction, intelligent factories, and other systems, the formation of collaborative management, intelligent construction site, intelligent construction of the integration of digital platforms, multi-objective, all elements of the creation of the national level of intelligent bridge digital construction demonstration project.",
  },
  // 行业案例-沪武高速
  expresswayHw: {
    section1_title: "Shanghai-Wuzhou Expressway Taicang-Changzhou",
    section1_title2: "Section Reconstruction and Expansion BIM",
    section1_title3: "Collaborative Construction Management Platform",
    section1_content1: 'The Taicang-Changzhou section of Shanghai-Wuzhou Expressway is an important part of Shanghai-Wuzhou National Freeway (G4221), of which the section from Suzhou-Shanghai border westward to Dongbang Junction is a common line section with Shenhai Expressway (G15). This project is an important part of the "horizontal eight" of the "fifteen shoots, six verticals and ten horizontals" in the highway network planning of Jiangsu Province (2017-2035), connecting Southern Jiangsu and Shanghai, and is one of the main access routes between Jiangsu Province and Shanghai.',
    section1_content2: 'The whole line along the existing Huwu high-speed widening and expansion, of which, Changzhou Xinnan Village to Meigang Village section of about 5.754 kilometers using the existing 232 Provincial Highway Weidun Bridge using the roadbed unilateral splicing width, the rest of the section are used on both sides of the roadbed splicing width. The design of new and widened bridges and culverts adopts highway load level - Ⅰ, using existing bridges to follow the original load standard, other technical indicators according to the "Highway Engineering Technical Standards" (JTG B01-2014) of the relevant provisions of the implementation.',
    section1_content3: 'The whole line in-situ expansion of Taicang, Banqiao (former Provincial Highway 339), Taicang North (hub), Shaxi, Shaxi (hub), Dongbang (hub), Changshu, Phoenix, Zhangjiagang (hub), Zhangjiagang, Yangshe (hub), Xinqiao, Huaxi, craggy (hub), Henglin (hub), Qishuyan, Changzhou South, a total of 17 interchanges; shift to build a new Changshu North, Changshou (former Xiamen), two interchanges; Demolition of Qingyang interchange; new Zhitang, Dayi, Xu Xiake, Furong, Lijia total of 5 interchanges. In-situ expansion of 2 service areas in Xinqiao and Furong.',
    section1_content4: 'Aiming at the actual construction management of Jiangsu Province Transportation Engineering and Construction Bureau highway (new construction and expansion), focusing on the characteristics of highway new construction and expansion projects, and closely focusing on the key elements of man, machine, material, law and environment, the BIM collaborative management platform for the construction of Jiangsu Province Transportation Engineering and Construction Bureau highway (new construction and expansion) is built to realize intelligent management of key business and data during the construction period by comprehensively applying the informationization means of BIM technology, big data, Internet of Things and cloud computing. It comprehensively applies BIM technology, big data, Internet of Things, cloud computing and other information technology means, unifies data standards and interface standards, realizes intelligent management of key business and data during the construction period, assists in the construction of Jiangsu Provincial Bureau of Transportation Engineering and Construction Expressway (New and Expanded) Intelligent Construction Site, solves the problem of real-time data linkage between businesses, and realizes the full sharing of information resources within the scope of the project and the display of the image.',
    section1_content5: 'The platform covers the management contents of project progress, quality, safety and investment during the construction period of highway (new and expanded) of Jiangsu Provincial Transportation Engineering Construction Bureau, and includes project management contents such as archive management, green and low-carbon, BIM traffic guidance and assisting in the construction of smart construction sites according to project characteristics. Comprehensive use of BIM, GIS, IoT and other technologies to build a highway (new and expanded) intelligent construction BIM management platform, optimize the workflow, and further improve the ability to control the project construction management and realize the project refinement management.',
    section1_content6: "This platform will realize:",
    section1_content7: "(1) Collaborative management: establish a collaborative project management platform based on BIM.",
    section1_content8: "Collaborative management: establish an online work platform between construction units and participating units, collaborate business processes between units and within units, communicate information in a timely manner, and improve work efficiency.",
    section1_content9: "(2) Resource sharing: establish a BIM information resource sharing platform",
    section1_content10: "Establish a data integration, exchange and sharing platform for project spatial basic geographic information, BIM information model, quality, safety and progress supervision data, and project daily management information, forming a project information resource center and realizing a construction management system with efficient collaboration of data and information. ",
    section1_content11: "(3) Process management: engineering construction management application system",
    section1_content12: "Construct an application system covering the whole process of project construction management, realizing information sharing, business flow, reporting and approval, process management, and statistical analysis.",
    section1_content13: "(4) Intelligent Construction: Implementing on-site quality control to create an intelligent construction site. ",
    section1_content14: "Adopt BIM technology, GPS technology, sensing technology, panoramic photogrammetry technology for on-site quality control; use mobile terminals such as cell phones and tablet PCs to collect on-site test and inspection data and quality inspection data in real time, form various types of test ledgers, and realize real-time collection of on-site quality inspection data, improve the efficiency of data collection, and reduce work intensity.",
    section1_content15: "(5) Information Visualization: Establishment of BIM visualization management platform",
    section1_content16: "Based on BIM+GIS three-dimensional environment, the BIM model is used as the management unit to carry various business data of project management, real-time display of project management status, and transfer management information to the outside world in a visualized way. ",
    section1_content17: "(6) Combine with the actual project and promote the application comprehensively.",
    section1_content18: "Jiangsu Province Transportation Engineering Construction Bureau Expressway (new and expanded) intelligent construction BIM cooperative management platform, the main management core point is the use of BIM technology, that is, three-dimensional information model visualization, associativity, traceability and other characteristics of the traditional construction management business modules for in-depth integration to achieve the efficient storage and sharing of data and information on the project construction process, for the key nodes, risk early warning and simulation. The platform is used as a handhold to enhance the owner's ability of collaborative management of the project. Build an intelligent, integrated and interconnected digital environment for all parties involved in the construction. Finally, at the time of completion and acceptance, the three-dimensional model associated with the whole process of construction information is delivered to the operation and maintenance stage, which provides strong support in terms of data and information for the management of the operating company in the later stage. At the same time, Jiangsu Provincial Transportation Engineering Construction Bureau highway (new and expanded) intelligent construction BIM collaborative management platform in the overall goal of the system, for each unit at all levels of managers to provide supporting digital control solutions.",
  },
  // 行业案例-江阴靖江长江隧道
  riverTunnelJyjj: {
    section1_title: "Jiangyin Jingjiang Yangtze River Tunnel Intelligent Construction",
    section1_title2: "BIM Collaborative Management Platform",
    section1_content1: "The Jiangyin Jingjiang Yangtze River Tunnel, a highway tunnel between Jiangyin City and Jingjiang City in Jiangsu Province, China, is approximately 16.2 kilometers long, including an undersea tunnel portion of 9.4 kilometers, making it one of the longest undersea road tunnels in the world. The tunnel was officially opened to traffic on November 30, 2019, and is the first undersea highway tunnel in China's Yangtze River Basin to be designed and built independently.",
    section1_content1_1: 'The construction of the Jiangyin Jingjiang Yangtze River Tunnel is mainly aimed at easing the traffic pressure between the upper and lower reaches of the Yangtze River, improving the efficiency of transportation and promoting the development of the Yangtze River Economic Belt. The opening of the Jiangyin Jingjiang Yangtze River Tunnel will further promote the development of the Yangtze River Economic Belt, shorten the transportation time between the upstream and downstream of the Yangtze River, and strengthen the connection and cooperation between different parts of the Yangtze River Basin, which is of great strategic significance and economic value.',
    section1_content2: "The tunnel adopts the world's leading undersea tunnel boring technology, including shield boring technology and tunnel engineering material technology, as well as BIM technology for design and construction management.",
    section1_content2_1: "The Jiangyin Jingjiang Yangtze River Tunnel adopts advanced tunnel design and construction technologies, such as large-diameter shield tunneling technology, pressure-balanced tunneling technology, geo-radar exploration technology, and BIM collaborative management technology. During the construction process, the tunnel also emphasizes on environmental protection and ecological restoration, adopting a series of environmental protection measures, such as green construction, underwater noise control, and submarine water quality monitoring.",

    section1_content3: "Jiangyin Jingjiang Yangtze River Tunnel BIM collaborative management platform is a kind of management platform adopted during the construction of the tunnel, which mainly applies the BIM technology and the concept of collaborative management to realize the collaborative management of various aspects such as design, construction, supervision, quality control and safety management.",
    section1_content4: "The main functions of the platform include the following aspects:",
    section1_content5: "Management of Building Information Model (BIM): The platform uses BIM technology to digitally model tunnel structures, equipment, materials and other information to achieve information integration and visualization. This helps various departments to understand the tunnel construction and work better together.",
    section1_content6: "Collaborative design management: The platform provides a collaborative design management module, which utilizes BIM technology to realize the collaborative design and management of the design scheme, including the formulation, review and modification of the design scheme, so as to ensure the quality and rationality of the design scheme.",
    section1_content7: "Collaborative construction management: The platform provides collaborative construction management module, which utilizes BIM technology to realize collaborative management and supervision of the construction process, including the formulation of construction plan, progress tracking, quality control, etc., to ensure the quality and progress of construction.",
    section1_content8: "Collaborative Supervision Management: The platform provides collaborative supervision management module, which utilizes BIM technology to realize collaborative management and supervision of the supervision process, including review, supervision, rectification and other aspects of the supervisory personnel, so as to ensure the quality and safety of the construction.",
    section1_content9: "Collaborative safety management: The platform provides collaborative safety management module to realize collaborative management and supervision of the safety management process, including safety inspection, hidden danger investigation, accident handling, etc., in order to ensure the safety of the construction process.",
    section1_content10: "The application of BIM collaborative management platform of Jiangyin Jingjiang Yangtze River Tunnel enables collaborative management of various aspects such as design scheme, construction plan and supervision process, avoiding information islands and duplication of work. At the same time, the platform can also realize real-time monitoring and adjustment of construction progress, avoiding delays and waste caused by unreasonable construction plans. In addition, the platform can realize the optimized selection and unified procurement of materials and equipment, which reduces the cost and improves the efficiency.",
    section1_content11: "The application of the BIM collaborative management platform of Jiangyin Jingjiang Yangtze River Tunnel can improve the efficiency of the construction process, realizing the collaborative management of various aspects such as design, construction, supervision, quality control, safety management, etc., which can help to improve the quality of the construction and effectively control the cost. It also provides an advanced management mode and technology for China's tunnel construction industry, which is of great significance for demonstration and promotion.",
    section1_content12: "",
    section1_content13: "",
    section1_content14: "",
    section1_content15: "",
    section1_content16: "",
    section1_content17: "",
    section1_content18: "",
    section1_content19: "",
  },
  // 行业案例-海太长江隧道
  riverTunnelHt: {
    section1_title: "Hai Tai Yangtze River Tunnel Intelligent Construction",
    section1_title2: "BIM Collaborative Management Platform",
    section1_content1: "The Hai Tai Yangtze River Tunnel, known as the Haikou-Taizhou Yangtze River dual-purpose public-railway cross-sea bridge (abbreviated as: Hai Tai Yangtze River Tunnel), is a dual-purpose public-railway cross-sea bridge between the island of Hainan, China and the city of Taizhou, Zhejiang Province, and is China's first dual-purpose public-railway cross-sea bridge to utilize the combination of a tunnel and a bridge. The tunnel portion is about 5.3 kilometers long and the bridge is about 16.34 kilometers long, with a total investment of more than RMB 100 billion.",
    section1_content2: "The construction of the tunnel aims to shorten the transportation time between Hainan Island and the Yangtze River Delta region and improve the economic development of Hainan Island. The tunnel portion utilizes the world's longest undersea tunnel boring technology, making it one of the longest public-railway tunnels in the world. The bridge portion adopts a series of innovative technologies, including prefabricated box girder construction technology and large-span cable-stayed bridge technology.",
    section1_content3: "The Hai Tai Yangtze River Tunnel officially opens to traffic on June 1, 2021, making it the first dual-purpose public-railway cross-sea bridge in China to use a tunnel-bridge combination, and the world's first undersea tunnel to pass through a coral reef community, which is of great transportation and economic significance.",
    section1_content4: "Hai Tai Yangtze River Tunnel Intelligent Construction BIM Collaborative Management Platform is an advanced construction management platform adopted during the construction process of Hai Tai Yangtze River Tunnel.BIM (Building Information Modeling) technology is a kind of technology that digitizes, visualizes and integrates building information, and through BIM technology, it can realize the whole process of collaborative management of architectural design, construction, and operation, BIM technology can realize the collaborative management of the whole process of building design, construction and operation. BIM technology can realize the collaborative management of the whole process of building design, construction, supervision, quality control, safety management, material management, etc., and realize the sharing and exchange of information, which effectively improves the efficiency and quality of construction management.",
    section1_content5: "Hai Tai Yangtze River Tunnel Intelligent Construction BIM Collaborative Management Platform is a collaborative management platform based on BIM technology, which realizes the collaborative management of all aspects of building design, construction, supervision, quality control, safety management, material management, etc. through the integration and visualization of digital building information. The main features of the platform include the following:",
    section1_content6: "Digitization of building information: The platform adopts BIM technology to digitize building information, including building structure, equipment, materials and other information, realizing the integration and visualization of information.",
    section1_content7: "Collaborative design management: The platform provides a design collaborative management module, which realizes the collaborative design and management of design plans, including the formulation, review and modification of design plans.",
    section1_content8: "Collaborative construction management: The platform provides a collaborative construction management module, which realizes the collaborative management and supervision of the construction process, including the formulation of construction plan, progress tracking, quality control, etc.",
    section1_content9: "Collaborative Supervision Management: The platform provides a collaborative supervision management module, which realizes the collaborative management and supervision of the supervision process, including the audit, supervision and rectification of the supervisory personnel.",
    section1_content10: "Collaborative material management: The platform provides a collaborative material management module, which realizes the collaborative management of material procurement, warehousing, warehousing, use and other aspects.",
    section1_content11: "Collaborative safety management: The platform provides a safety management module, which realizes the collaborative management and supervision of the safety management process, including safety inspection, hidden danger investigation, accident handling and other links.",
    section1_content12: "The application of the BIM collaborative management platform for the intelligent construction of Hai Tai Yangtze River Tunnel improves the management efficiency and quality of the construction process, and at the same time provides an advanced management mode and technology for China's construction industry, which is of great significance for demonstration and promotion.",
  },
  // 行业案例-腾龙大道智慧快速路工程数字化建设管理平台
  tlAvenue: {
    section1_title: "Tenglong Avenue Intelligent Expressway Project",
    section1_title2: "Digital Construction Management Platform",
    section1_content1: "Tenglong Avenue Expressway Project is a transportation infrastructure project located in Longgang District, Shenzhen City, Guangdong Province, China, invested by the Shenzhen Municipal Transportation Commission and officially opened to traffic in 2020.",
    section1_content2: "The project mainly constructed an expressway of about 14 kilometers in length, connecting the central district of Longgang and Tenglong New District, which is an important part of the eastern expressway of Longgang District. The expressway was designed and constructed to a high standard.",
    section2_title: "",
    section2_content1: "The digital construction management platform of the Tenglong Avenue Smart Expressway Project is a digital construction management system used in the project, aiming to optimize the construction process and improve the quality and efficiency of the project. The platform mainly includes the following aspects:",
    section2_content2: "BIM modeling platform: BIM technology is used to model the engineering construction, including the modeling of roads, bridges, tunnels, drainage and other aspects. Through BIM modeling, engineering design and construction planning can be carried out more accurately, improving the quality and efficiency of engineering construction.",
    section2_content3: "Engineering management platform: The digital construction management platform realizes the management and monitoring of the whole process of engineering construction, including the management of various aspects of engineering design, construction, supervision and so on. Through the real-time monitoring and data analysis of the platform, problems and risks in engineering construction can be discovered and dealt with in a timely manner to improve the quality and efficiency of engineering construction.",
    section2_content4: "Supply Chain Management Platform: The digital construction management platform realizes the management and monitoring of the supply chain, including the management of each link such as material procurement, logistics and distribution. Through the real-time monitoring and data analysis of the platform, the efficiency and cost of the supply chain can be optimized, and the quality and efficiency of construction can be improved.",
    section2_content5: "Mobile APP platform: through the mobile APP platform, the engineering construction personnel can obtain real-time data and information of engineering construction on their cell phones anytime and anywhere for real-time monitoring and management. Through the mobile APP platform, it can improve the response speed and work efficiency of engineering construction.",
  },
  // 行业案例-华设盐城智能制造产业园
  hsyc: {
    section1_title: "Huashe Yancheng Intelligent Manufacturing Industrial Park prefabricated to ",
    section1_title2: "build production management and digital twin development platforms",
    section1_content1: 'Huashe Yancheng Intelligent Manufacturing Industrial Park is an intelligent manufacturing industrial park located in Jianhu County, Yancheng City, Jiangsu Province, which is invested and constructed by Huashe Group, and will be officially opened in 2021. The park covers an area of about 300 mu, with a total investment of 6 billion RMB, and is an important strategic investment of Huashi Group in East China. With the main direction of "New Generation Information Technology + Intelligent Manufacturing", the industrial park aims to become an intelligent manufacturing industrial base integrating R&D, production, training and exhibition.',
    section1_content2: "The main business of the industrial park includes:",
    section1_content3: "Intelligent Equipment Manufacturing: There is an Intelligent Equipment Manufacturing Workshop in the park, which focuses on the production of various types of high-precision and highly automated intelligent manufacturing equipment and parts.",
    section1_content4: "Intelligent solutions: There is an intelligent solutions R&D center in the park, providing intelligent upgrading solutions and services for all kinds of enterprises.",
    section1_content5: "Skill training and technological innovation: The park has a skill training center and a technological innovation center to provide various kinds of skill training and technological innovation support for enterprises and employees.",
    section1_content6: "Industrial Exhibition and Communication: There is an industrial exhibition center and communication center in the park, providing a platform for various enterprises and customers to display their products and communicate with each other.",
    section1_content7: "Huashe Yancheng Intelligent Manufacturing Industrial Park adopts advanced intelligent manufacturing technologies and equipments, which are characterized by high efficiency, intelligence and sustainability, and will help promote the upgrading and transformation of China's manufacturing industry. At the same time, the industrial park will also drive the development of local economy and employment and promote the rapid development of regional economy.",
  },
  // 行业案例-汾湖站交通枢纽配套工程
  fhz: {
    section1_title: "Whole Process BIM Consulting Service for Fenhu Station",
    section1_title2: "Transportation Hub Supporting Project",
    section1_content1: "The Fenhu Station Transportation Hub Supporting Project is a transportation hub construction project located in Fenhu Town, Qingxu County, Taiyuan City, Shanxi Province, China, with a total project investment of approximately RMB 6 billion. The project is designed to improve the convenience and efficiency of local transportation and promote regional economic development.",
    section1_content2: "The whole process BIM consulting service for Fenhu Station Transportation Hub Supporting Project has the following features and advantages:",
    section1_content3: "Improvement of construction quality and efficiency: modeling and collaborative work through BIM technology can improve construction quality and efficiency, and avoid problems and errors in the construction process.",
    section1_content4: "Reducing construction cost and risk: through BIM simulation, problems can be found and dealt with in advance, reducing construction risk and thus reducing construction cost.",
    section1_content5: "Realize digital construction and intelligent management: through BIM modeling and visualization display, digital construction and intelligent management can be realized, providing more scientific and intelligent management and monitoring means for engineering construction.",
    section1_content6: "Support collaborative work and information sharing: Through BIM collaboration and visualization display, it realizes collaborative work and information sharing among various departments and improves work efficiency.",
    section1_content7: "Improve project management level: through the application of BIM technology, it can realize the whole process management and monitoring of the project, and improve the project management level and efficiency.",
    section1_content8: "The implementation of this consulting service will help promote the development of digital construction and intelligent management, improve the quality and efficiency of construction, reduce construction costs and risks, and contribute to the rapid development of the regional economy.",
  },

  // 资讯与活动
  information: {
    hd_text1: "NEWS",
    hd_text2: "",
    hd_text3: "& VIDEO",

    menu_title1: "Newsletters",
    menu_title2: "Company News",
    menu_title3: "Notification Bulletin",
    menu_title4: "Industry News",
    menu_title5: "Highlight Video",
  },

  // 联系我们
  about: {
    section1_title: "Contact Us",
    section1_title2: "",
    section1_text: "Submit your request online",
    section1_text2: "We will call you within 24 hours.",

    section1_from1: "Your Name",
    section1_from2: "Your Phone/Mobile",
    section1_from3: "Your e-mail",
    section1_from4: "Please fill in your request",
    section1_submit1: "submit",
    from_rule1: "your name cannot be empty",
    from_rule2: "your phone/mobile cannot be empty",
    from_rule3: "your email cannot be empty",
    from_rule4: "please enter the correct email address",
    from_rule5: "requirement cannot be empty",
    submit_tips: "submitted successfully",

    section2_title: "Business Negotiation",
    section2_item1_title: "Digital Construction Product Line",
    section2_item1_text: "Associates",
    section2_item1_phone1: "Manager Dong 15280106801",
    section2_item1_phone2: "Manager Fu 18505110818",

    section2_item2_title: "Digital Design Software Product Line",
    section2_item2_text: "Associates",
    section2_item2_phone1: "Manager Xu 17372261215",
    section2_item2_phone2: "Manager Li 18618162992",
    section2_item2_phone3: "Manager Wu 17730442539",

    section3_title: "We are here for you！",
    section3_content1: "Building 1, Liye Park, Qidi Science and Technology City, Jiangning District,",
    section3_content2: "Nanjing, Jiangsu Province, P.R. China",
  },

  // EICAD-演示教学
  demonstrationTeaching: {
    text1: "people have watched",
    text2: "duration",
    text3: "video upload time",
    text4: "content validity",
    text5: "other videos",
  },

  // 更新日志
  updateLog: {
    section1_title: "Update Log",

  },

};
