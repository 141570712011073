<!--
* @description 产品建造线
* @fileName cpjzx.vue
* @author liulian
* @date 2023/07/11 15:18:09
-->
<template>
  <div class="cpjzx_contaienr">
    <div class="c_title font-size-32 font-weight-600 font-color-black-301D09 text-align-center">{{ $t("pc_index.Cpjzx_title") }}<br/>{{ $t("pc_index.Cpjzx_title2") }}</div>
    <div class="c_nav flex-row-between align-center">
      <div class="c_n_item cursor-pointer flex-row-center align-center" :class="activeIndex === item.key ? 'c_n_item_active' : ''" v-for="(item, index) in navList" :key="index" @mouseenter="clickNav(item)">
        <img class="c_n_i_icon" :src="activeIndex === item.key ? item.selIcon : item.icon" alt="icon" />
        <span class="c_n_i_span font-size-24 font-weight-500" :class="activeIndex === item.key ? 'font-color-yellow-FC8F01' : 'font-color-gray-666666'">
          {{ $t(`pc_index.${item.name}`) }}
        </span>
      </div>
    </div>
    <div class="c_content flex-row" :class="{'en': $i18n.locale == 'English'}">
      <div class="c_c_left">
        <div v-if="activeIndex === '1'" class="c_c_l_title font-size-24 font-weight-600 font-color-black-301D09 clamp-1">{{ $t("pc_index.Cpjzx_szsj_title") }}</div>
        <div v-else-if="activeIndex === '2'" class="c_c_l_title font-size-24 font-weight-600 font-color-black-301D09 clamp-1">{{ $t("pc_index.Cpjzx_sjjz_title") }}</div>
        <div class="c_c_l_list">
          <div class="c_c_l_l_item" :class="item.expend ? 'c_c_l_l_item_active ' : ''" v-for="(item, index) in lists" :key="index" @click="clickItem(item, index)">
            <div class="l_l_title cursor-pointer flex-row-between align-center">
              <span class="l_l_t_span font-size-20 font-weight-400 font-color-black-301D09">{{ $t(`pc_index.${item.name}`) }}</span>
              <img v-if="!item.expend" class="l_l_icon" src="@/assets/images/homeImg/arrow_black_icon@2x.png" alt="展开更多图标" />
            </div>
            <!-- 详细内容 -->
            <template v-if="item.expend">
             <div class="c_c_l_i_con ">
              <div class="l_l_con text-align-justify font-size-14 font-weight-400">{{ $t(`pc_index.${item.content}`) }}</div>
              <span class="l_l_more cursor-pointer font-size-14 font-weight-400 font-color-yellow-FC8F01" @click="goDetail(item)">{{ $t("common.KnowMore") }} ></span>
             </div>
            </template>
          </div>
        </div>
      </div>
      <div class="c_c_right">
        <!-- <img class="c_c_r_img" :src="activeCover" alt="img" /> -->
        <div class="c_c_r_img">
          <video class="c_l_v_box" muted="muted" ref="videoPlayer" controls autoplay >
              <source class="sourse_viede" id="video" v-if="activeUrl" :src="activeUrl" type="video/mp4" />
            </video>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cpjzx",
  components: {},
  data() {
    return {
      navList: [
      {
          name: "Cpjzx_szsj",
          key: "1",
          icon: require("../../../assets/images/homeImg/szsj_icon@2x.png"),
          selIcon: require("../../../assets/images/homeImg/szsj_icon_sel@2x.png"),
          children: [
            {
              name: "Cpjzx_szsj_AIRoad_title",
              content: "Cpjzx_szsj_AIRoad_content",
              cover:require("../../../assets/images/homeImg/cpjzx_4@2x.png"),
              path:'airoad?type=product',
              videoUrl:this.$videBaseUrl + '数字设计/AIRoad.mp4'
            },
            {
              name: "Cpjzx_szsj_EICAD_title",
              content: "Cpjzx_szsj_EICAD_content",
              cover:require("../../../assets/images/homeImg/cpjzx_5@2x.png"),
              path:'eicad?type=product',
              videoUrl:this.$videBaseUrl + '数字设计/EICAD.mp4'

            },
            {
              name: "Cpjzx_szsj_VRRoad_title",
              content: "Cpjzx_szsj_VRRoad_content",
              cover:require("../../../assets/images/homeImg/cpjzx_6@2x.png"),
              path:'ticad?type=product',
              videoUrl:this.$videBaseUrl + '数字设计/VRRoad.mp4'
            },
          ],
        },
        {
          name: "Cpjzx_sjjz",
          key: "2",
          icon: require("../../../assets/images/homeImg/szjz_icon@2x.png"),
          selIcon: require("../../../assets/images/homeImg/szjz_sel_icon@2x.png"),
          children: [
            {
              name: "Cpjzx_sjjz_szhjgpt_title",
              content: "Cpjzx_sjjz_szhjgpt_content",
              cover:require("../../../assets/images/homeImg/cpjzx_1@2x.png"),
              path:'productSzhjsgl?type=product',
              videoUrl:this.$videBaseUrl + '数字建造/数字建管平台.mp4'
            },
            {
              name: "Cpjzx_sjjz_zhgd_title",
              content: "Cpjzx_sjjz_zhgd_content",
              cover:require("../../../assets/images/homeImg/cpjzx_2@2x.png"),
              path:'zhgd?type=product',
              videoUrl:this.$videBaseUrl + '数字建造/智慧工地平台.mp4'
            },
            {
              name: "Cpjzx_sjjz_zhgc_title",
              content: "Cpjzx_sjjz_zhgc_content",
              cover:require("../../../assets/images/homeImg/cpjzx_3@2x.png"),
              path:'zngc?type=product',
              videoUrl:this.$videBaseUrl + '数字建造/智能梁厂.mp4'
            },
          ],
        },
      ],
      activeIndex: "1",
      activeCover:'',
      lists: [],
    }
  },
  computed: {},
  created() {
    this.init()
       console.log('2$videBaseUrl22 ',this.$videBaseUrl )
  },
  mounted() {},
  methods: {
    /*
     * @Description: 跳转对应的页面
     * @MethodAuthor:  liulian
     * @Date: 2023-08-02 12:05:00
     * @param: '' 
    */
    goDetail(item){
      // this.$router.push(item.path)
      this.$uniFun.openUrl(`/dnnweb/${item.path}`)
    },
    /*
     * @Description: 点击展开详情
     * @MethodAuthor:  liulian
     * @Date: 2023-07-11 17:08:41
     * @param: ''
     */
    clickItem(item, index) {
      // this.activeCover = item.cover
   
      this.lists.map((i, index1) => {
        this.$set(i, "expend", index === index1)
      })
      this.activeUrl = item.videoUrl
      this.$refs.videoPlayer.load() // 加载新视频
    },
    /*
     * @Description:初始化
     * @MethodAuthor:  liulian
     * @Date: 2023-07-11 16:49:53
     * @param: ''
     */
    init() {
      this.lists = this.navList[0].children
      this.$set(this.lists[0], "expend", true)
      // this.activeCover = this.lists[0].cover
      this.activeUrl = this.lists[0].videoUrl
      console.log(123, this.activeUrl)
    },

    clickNav(item) {
      this.activeIndex = item.key
      this.lists = item.children
      // this.activeCover = this.lists[0].cover
      this.activeUrl = this.lists[0].videoUrl
      this.$refs.videoPlayer.load() // 加载新视频

    //   默认第一个
      this.lists.map((i, index) => {
        this.$set(i, "expend", index === 0)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.cpjzx_contaienr {
  .c_title {
    margin-top: 64px;
  }
  .c_nav {
    height: 91px;
    margin-top: 32px;
    box-shadow: 0px 4px 12px 0px rgba(169, 109, 32, 0.11);
    border-radius: 8px;
    background-color: #fff;
    .c_n_item {
      min-width: 255px;
      height: 100%;
      position: relative;
      .c_n_i_icon {
        width: 32px;
        height: 32px;
        margin-right: 16px;
      }
    }

    .c_n_item_active::before {
      content: "";
      width: 100%;
      height: 6px;
      background: #fc8f01;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .c_n_item:first-child {
      margin-left: 196px;
      .c_n_i_icon {
        height: 33px;
      }
    }
    .c_n_item:last-child {
      margin-right: 196px;
      .c_n_i_icon {
        height: 36px;
      }
    }
  }
  .c_content {
    margin-top: 12px;
    min-height: 528px;
    box-shadow: 0px 7px 24px 0px rgba(169, 109, 32, 0.12);
    border-radius: 8px;
    background-color: #fff;
    &.en {
      min-height: 600px;
    }
    .c_c_left {
      margin-left: 37px;
      margin-right: 47px;
      width: 480px;
      .c_c_l_title {
        margin-top: 50px;
        padding-left: 19px;
        padding-right: 19px;
      }
      .c_c_l_list {
        margin-top: 27px;
      }

      .c_c_l_l_item {
        padding-left: 19px;
        padding-right: 19px;
        background: rgba(250, 246, 242, 0.3);
        position: relative;
        display: flex;
        flex-direction: column;

        .l_l_title {
          margin: 14px 0;
          // min-height: 62.4px;
          box-sizing: border-box;

          .l_l_icon {
            width: 20px;
            height: 20px;
          }
        }
        .c_c_l_i_con{
          height: 100%;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .l_l_con {
          
color: rgba(51,51,51,0.72);
          letter-spacing: 1px;
          line-height: 24px;
          margin-bottom: 19px;
        }
        .l_l_more {
          padding-bottom: 21px;
          display: inline-block;
        }
      }

      .c_c_l_l_item::after {
        content: "";
        position: absolute;
        width: 100%;
        bottom: 0px;
        height: 1px;
        left: 0px;
        background-color: rgba(221, 221, 221, 0.5);
      }

      .c_c_l_l_item:first-child::before {
        content: "";
        position: absolute;
        width: 100%;
        top: 0px;
        height: 1px;
        left: 0px;
        background-color: rgba(221, 221, 221, 0.5);
      }
      .c_c_l_l_item_active {
        background: #faf6f2;
        min-height: 236.4px;
      }
      .scale-up-ver-top {
        -webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
        animation: scale-up-ver-top 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
      }

      @-webkit-keyframes scale-up-ver-top {
        0% {
          -webkit-transform: scaleY(0.4);
          transform: scaleY(0.4);
          -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
        }
        100% {
          -webkit-transform: scaleY(1);
          transform: scaleY(1);
          -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
        }
      }
      @keyframes scale-up-ver-top {
        0% {
          -webkit-transform: scaleY(0.4);
          transform: scaleY(0.4);
          -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
        }
        100% {
          -webkit-transform: scaleY(1);
          transform: scaleY(1);
          -webkit-transform-origin: 100% 0%;
          transform-origin: 100% 0%;
        }
      }
    }
  }
  .c_c_right {
    .c_c_r_img {
      width: 596px;
    height: 388px;
    margin-top: 107px;
    background: #333333;
    border: 1px solid;
      .c_l_v_box{
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
