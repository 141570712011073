<template>
  <div class="hyal_contaienr">
    <div class="h_title font-size-32 font-weight-600 font-color-black-301D09 text-align-center">{{ $t("pc_index.Hyal_Title") }}</div>
    <div class="h_p text-align-center">{{ $t("pc_index.Hyal_describe") }}</div>
    <!-- 卡片区域 -->
    <div class="h_card flex-row-center">
      <!-- <div class="h_c_item" v-for="(item,index) in lists" :key="index">
            <img :src="item.cover" alt="案例封面图">
        </div> -->
      <div class="carousel">
        <div class="image-container" @mouseenter="pauseCarousel" @mouseleave="startCarousel">
          <div class="img_box cursor-pointer" v-for="(image, index) in images" :key="index" :class="{ selected: index === selectedIndex }" @click="goUrl(image)">
            <img v-if="index === selectedIndex" class="i_b_img" :src="$t(`pc_index.${image.fillSrc}`)" />
            <img v-else class="i_b_img" :src="$t(`pc_index.${image.src}`)" />
          </div>
        </div>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="lxwm">
      <div class="l_con font-color-white-ffffff flex-row-center align-center" :class="{'en': $i18n.locale == 'English'}">
        <p class="font-size-28 font-weight-500 text-align-center">{{ $t("pc_index.Hyal_text1") }}</p>
        <p class="text-align-center font-size-24 font-weight-400">{{ $t("pc_index.Hyal_text2") }}</p>
        <span class="l_btn font-size-24 cursor-pointer font-weight-500" @click="toLink">{{ $t("pc_index.Hyal_ContactUs") }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      images: [
        {
          src: "Hyal_img1_src",
          fillSrc: "Hyal_img1_fillsrc",
          path:'expresswayHw?type=industry'
        },
        {
          src: "Hyal_img2_src",
          fillSrc: "Hyal_img2_fillsrc",
          path:'riverTunnelHt?type=industry'
        },
        {
          src: "Hyal_img3_src",
          fillSrc: "Hyal_img3_fillsrc",
          path:'crossRiverBridge?type=industry'
        },
        {
          src: "Hyal_img4_src",
          fillSrc: "Hyal_img4_fillsrc",
          path:'hsyc?type=industry'
        },
        // Add more image objects as needed
      ],
      selectedIndex: 0,
      intervalId: null,
    }
  },
  mounted() {
    this.startCarousel()
  },
  destroyed() {
    console.log("+++==")
  },
  methods: {
    /*
     * @Description: 跳转到对应页面
     * @MethodAuthor:  liulian
     * @Date: 2023-08-04 14:37:21
     * @param: '' 
    */
    goUrl(item){
      // this.$router.push(item.path)
      this.$uniFun.openUrl('/dnnweb/'+item.path)
    },
    /*
     * @Description: 跳转到联系我们页面
     * @MethodAuthor:  liulian
     * @Date: 2023-08-02 14:12:04
     * @param: ''
     */
    toLink() {
      // this.$router.push("/about?type=about")
      this.$uniFun.openUrl('/dnnweb/about?type=about')
    },
    startCarousel() {
      this.intervalId = setInterval(() => {
        this.selectedIndex = (this.selectedIndex + 1) % this.images.length
      }, 2500)
    },
    pauseCarousel() {
      clearInterval(this.intervalId)
    },
  },
}
</script>
<style scoped lang="scss">
.hyal_contaienr {
  background-color: #fff;

  .h_p {
    margin-top: 15px;
color: rgba(48,29,9,0.59);
  }
  .h_card {
    margin-top: 51px;
    .h_c_item {
      margin-left: 16px;
      margin-right: 16px;
    }
  }
}
.carousel {
  height: 480px;
  overflow: hidden;
  width: 1200px;
  margin: auto;
}
.image-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  transition: transform 0.5s;
  .img_box {
    width: 200px;
    height: 480px;
    .i_b_img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }
}
.image-container .img_box {
  width: 200px;
  height: 480px;
  object-fit: cover;
  transition: transform 0.5s, width 0.5s, height 0.5s;
}
.image-container .selected {
  width: 504px;
  height: 480px;
  .i_b_img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
}
.lxwm {
  height: 360px;
  background-image: url("../../../assets/images/hyalImg/lxwm_bg@2x.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 80px;
  position: relative;

  .l_con {
    width: 952px;
    margin: auto;
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.en {
      width: 1370px;
    }


    p:first-child {
      line-height: 48px;
      margin-bottom: 28px;
      margin-top: 66px;
    }
    p {
      z-index: 1;
      // position: absolute;
      color: #fff;
      // top: 0;
      width: 100%;
    }
    p:nth-child(2) {
      // top: 124px;
      display: block;
      width: 100%;
    }
    .l_btn {
      width: 240px;
      height: 56px;
      background: #fc8f01;
      margin: 42px auto;
      display: block;
      border-radius: 4px;
      text-align: center;
      line-height: 56px;
      z-index: 1;
      // position: absolute;
      color: #fff;
      // top: 146px;
    }
  }
}
.lxwm::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
</style>
