import {
    Button,
    Input,
    Radio,
    Table,
    Form,
    Carousel,
    CarouselItem,
    Message
  } from 'element-ui'
  
  const coms = [
    Button, 
    Input, 
    Radio, 
    Table,
    Form,
    Carousel,
    CarouselItem,
    Message
  ]
  
  export default {
    install(Vue, options){
      coms.map(c => {
        Vue.component(c.name, c)
      })
    }
  }
  