import Vue from "vue"
import VueRouter from "vue-router"
import pcIndex from "../views/Home.vue"
import phoneIndex from "../views/phoneHome.vue"
import Main from '../Layout/main.vue'
Vue.use(VueRouter)
let base = (function () {
  let path = location.pathname;
  console.log(9, path);
  let reg = /^\/dnnweb/;
  console.log(10, reg.test(path));
  if (reg.test(path)) {
    return '/dnnweb/';
  }
  return '/';
})();
const routes = [
  {
    path: "/",
    component: Main,
    redirect: "/pc_index",
    children: [
      {
        path: "/pc_index",
        name: "pcIndex",
        component: pcIndex,
      },
      {
        path: "/phone_index",
        name: "phoneIndex",
        component: phoneIndex,
      },
      /******产品中心******/
      {
        path: "/productSzhjsgl",
        name: "szhjsgl",
        component: () => import("../views/web/product/szhjsgl.vue"),
      },
      {
        path: "/zhgd",
        name: "zhgd",
        component: () => import("../views/web/product/zhgd.vue"),
      },
      {
        path: "/zngc",
        name: "zngc",
        component: () => import("../views/web/product/zngc.vue"),
      },
      {
        path: "/airoad",
        name: "airoad",
        component: () => import("../views/web/product/airoad.vue"),
      },
      {
        path: "/eicad",
        name: "eicad",
        component: () => import("../views/web/product/eicad.vue"),
      },
      {
        path: "/ticad",
        name: "ticad",
        component: () => import("../views/web/product/ticad.vue"),
      },
      {
        path: "/vrRoad",
        name: "vrRoad",
        component: () => import("../views/web/product/vrRoad.vue"),
      },
      {
        path: "/Report3D",
        name: "Report3D",
        component: () => import("../views/web/product/Report3D.vue"),
      },


      /******资讯与活动******/
      {
        path: "/information",
        name: "information",
        component: () => import("../views/web/information/index.vue"),
      },
      // 资讯与活动新闻详情
      {
        path: "/newsDetail",
        name: "newsDetail",
        component: () => import("../views/web/information/newsDetail.vue"),
      },
      /******资讯与活动视频详情******/
      {
        path: "/zxyhdVideoDetail",
        name: "zxyhdVideoDetail",
        component: () => import("../views/web/information/zxyhdVideoDetail.vue"),
      },
      /******联系我们******/
      {
        path: "/about",
        name: "about",
        component: () => import("../views/web/about/index.vue"),
      },
      /*******SaaS云平台*****/
      {
        path: "/saaS",
        name: "SaaS",
        component: () => import("../views/web/saaS/index.vue"),
      },


      /****** 解决方案-虚拟建造 ******/
      {
        path: "/virtualConstruction",
        name: "virtualConstruction",
        component: () => import("../views/web/programme/virtualConstruction.vue"),
      },
      /****** 解决方案-VR技术 ******/
      {
        path: "/vr",
        name: "vr",
        component: () => import("../views/web/programme/vr.vue"),
      },
      /****** 解决方案-倾斜摄影 ******/
      {
        path: "/photo",
        name: "photo",
        component: () => import("../views/web/programme/photo.vue"),
      },
      /****** 解决方案-院校交通数字化与智能建造仿真实验室 ******/
      {
        path: "/laboratory",
        name: "laboratory",
        component: () => import("../views/web/programme/laboratory.vue"),
      },



      /****** 行业案例-跨江大桥 ******/
      {
        path: "/crossRiverBridge",
        name: "crossRiverBridge",
        component: () => import("../views/web/industry/crossRiverBridge.vue"),
      },
      /****** 行业案例-海太长江隧道 ******/
      {
        path: "/riverTunnelHt",
        name: "riverTunnelHt",
        component: () => import("../views/web/industry/riverTunnelHt.vue"),
      },
      /****** 行业案例-江阴靖江长江隧道 ******/
      {
        path: "/riverTunnelJyjj",
        name: "riverTunnelJyjj",
        component: () => import("../views/web/industry/riverTunnelJyjj.vue"),
      },
      /****** 行业案例-京沪高速 ******/
      {
        path: "/expressway",
        name: "expressway",
        component: () => import("../views/web/industry/expressway.vue"),
      },
      /****** 行业案例-沪武高速 ******/
      {
        path: "/expresswayHw",
        name: "expresswayHw",
        component: () => import("../views/web/industry/expresswayHw.vue"),
      },
      /****** 行业案例-华设盐城智能制造产业园 ******/
      {
        path: "/hsyc",
        name: "hsyc",
        component: () => import("../views/web/industry/hsyc.vue"),
      },
      /****** 行业案例-汾湖站交通枢纽配套工程 ******/
      {
        path: "/fhz",
        name: "fhz",
        component: () => import("../views/web/industry/fhz.vue"),
      },
      /****** 行业案例-腾龙大道智慧快速路工程数字化建设管理平台 ******/
      {
        path: "/tlAvenue",
        name: "tlAvenue",
        component: () => import("../views/web/industry/tlAvenue.vue"),
      },

      /****** 软件下载 ******/
      {
        path: "/download",
        name: "download",
        component: () => import("../views/web/download/index.vue"),
      },

      /****** 用户支持-更新日志 ******/
      {
        path: "/updateLog",
        name: "updateLog",
        component: () => import("../views/web/user/updateLog.vue"),
      },
      /****** 用户支持-更新日志-详情 ******/
      {
        path: "/updateLogDetail",
        name: "updateLogDetail",
        component: () => import("../views/web/user/updateLogDetail.vue"),
      },
      /******用户支持-演示教学******/
      {
        path: "/demonstrationTeaching",
        name: "demonstrationTeaching",
        component: () => import("../views/web/user/demonstrationTeaching.vue"),
      },
      /******视频播放详情******/
      {
        path: "/videoDetail",
        name: "videoDetail",
        component: () => import("../views/web/user/videoDetail.vue"),
      }
    ]
  },
  /******用户支持-帮助文档******/
  {
    path: "/helpDocuments",
    name: "helpDocuments",
    component: () => import("../views/web/helpDocuments/index.vue"),
  },

]

const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  base: base,
  routes,
})

router.beforeEach((to, from, next) => {
  console.log('to', to);
  console.log('from', from);
  // debugger
  let path = []
  let check = path.some((i) => {
    return i === to.path
  })
  if (!check) {
    let sTop = document.documentElement.scrollTop || document.body.scrollTop
    if (sTop > 0) {
      document.body.scrollTop = document.documentElement.scrollTop = 0
    }
  }
  next()
})

export default router