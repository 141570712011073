<template>
  <div class="home_container">
    <!-- banner -->
    <div class="h_banner">
      <Banner v-if="banners.length > 0" :lists="banners" indicatorPosition="none"></Banner>
    </div>
    <!-- 资讯与活动 -->
    <div class="zxyhd">
      <div class="h_title font-size-32 font-weight-600 font-color-black-301D09">{{ $t("pc_index.ConsultingAndEvents") }}</div>
      <Zxyhd></Zxyhd>
    </div>
    <!-- 视频 -->
    <div class="sp_con" v-if="dedios.length>0">
      <div class="h_title h_width font-size-24 font-weight-400 font-color-black-301D09">{{ $t("pc_index.Videos") }}</div>
      <Sp :listsData="dedios"></Sp>
    </div>
    <!-- 产品建造线条 -->
    <div class="cpjzx" :class="{'en': $i18n.locale == 'English'}">
      <div class="con">
        <Cpjzx></Cpjzx>
      </div>
    </div>
    <!-- 数字勘测云 -->
    <div class="szkcy" :class="{'en': $i18n.locale == 'English'}">
      <Szkcy></Szkcy>
    </div>
    <!-- 行业案例 -->
    <div class="hyal_con">
      <Hyal></Hyal>
    </div>
    <!-- 关于我们 -->
    <div class="gywm_con" :class="{'en': $i18n.locale == 'English'}">
      <Gywm></Gywm>
    </div>
    <!-- 合作伙伴 -->
    <div class="hzhb_con">
     <keep-alive><Hzhb></Hzhb></keep-alive>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner.vue"
import Zxyhd from "@/views/web/home/zxyhd.vue"
import Sp from "@/views/web/home/sp.vue"
import Gywm from "@/views/web/home/gywm.vue"
import Cpjzx from "@/views/web/home/cpjzx.vue"
import Szkcy from "@/views/web/home/szkcy.vue"
import Hzhb from "@/views/web/home/hzhb.vue"
import Hyal from "@/views/web/home/hyal.vue"

import { clientBannerListApi, listByIdsApi, clientHomeVideoApi } from "@/api/api"
export default {
  name: "Home",
  components: {
    Banner,
    Zxyhd,
    Sp,
    Gywm,
    Cpjzx,
    Szkcy,
    Hzhb,
    Hyal,
  },
  data() {
    return {
      scrollingText: "This is the scrolling text",
      scrollingSpeed: 1,
      banners: [],
      dedios:[]
    }
  },
  created() {
    this.getBanner()
    this.getVedios()
  },
  methods: {
    // 获取首页视频列表
    async getVedios() {
      let datas = await this.getVedioApi()
      this.dedios = datas
    },
    /*
     * @Description: 首页视频
     * @MethodAuthor:  liulian
     * @Date: 2023-07-26 11:15:06
     * @param: ''
     */
     getVedioApi() {
      return new Promise((resolve, reject) => {
        clientHomeVideoApi().then((res) => {
          if (res.code === 200) {
            resolve(res.rows)
          }else{
            this.$message.error(res.msg)
          }
        })
      })
    },
    async getBanner() {
      let data = await this.bannerApi()
      // let arrs = []
      // data &&
      //   data.length > 0 &&
      //   data.map((i) => {
      //     arrs.push(i.bannerLinkId)
      //     arrs.push("")
      //   })
      // let query = arrs.filter(Boolean)
      // if (query.length > 0) {
      //   let imgArr = await this.getlistByIdsApi(query)
      //   if (imgArr && imgArr.length > 0) {
      //     data.map((i) => {
      //       let obj = imgArr.find((j) => j.ossId === i.bannerLinkId)
      //       i.url = obj.url
      //     })
      //   }
      // }
      this.banners = data
    },
    /*
     * @Description: ossid获取图片
     * @MethodAuthor:  liulian
     * @Date: 2023-07-25 17:56:14
     * @param: ''
     */
    // getlistByIdsApi(query) {
    //   return new Promise((resolve, reject) => {
    //     listByIdsApi(query.join(",")).then((res) => {
    //       if (res.code === 200) {
    //         resolve(res.data)
    //       }
    //     })
    //   })
    // },

    /*
     * @Description: 获取banner信息
     * @MethodAuthor:  liulian
     * @Date: 2023-07-25 17:15:49
     * @param: ''
     */
    bannerApi() {
      return new Promise((resolve, reject) => {
        clientBannerListApi().then((res) => {
          if (res.code === 200) {
            resolve(res.rows)
          }
        })
      })
    },
  },

  watch: {
    '$i18n.locale': function() {
      this.getBanner()
      this.getVedios()
    }
  }
}
</script>

<style scoped lang="scss">
.home_container {
  min-height: 100vh;

  .h_width {
    width: 1188px;
  }
  .h_title {
    margin-bottom: 16px;
    margin: auto;
  }
  .zxyhd {
    text-align: center;
  }
  .zxyhd {
    padding-top: 32px;
    margin-bottom: 16px;
  }
  .sp_con {
    margin-top: 32px;
  }
  .cpjzx {
    height: 734px;
    position: relative;
    display: flex;
    justify-content: center;
    margin-top: 64px;
    background: #ffffff;
    .con {
      // height: 766px;
      width: 1200px;
      position: absolute;
      top: 0;
    }

    &.en {
      height: 820px;
    }
  }
  .szkcy {
    background-color: #fff;
    height: 821px;
    &.en {
      height: 960px;
    }
  }
  .gywm_con {
    // height: 644px;
    height: 848px;
    &.en {
      height: 1144px;
    }
  }
  .hzhb_con {
    background-color: #fff;
  }
}
</style>
