// 定义中文语言包对象
export default {
  common: {
    KnowMore: "查看详情",
    NoData: "暂无数据",
    Loading: "加载中",
    NoMore: "没有更多了",
    paginationNext: "下一页",
  },
  // 底部
  Footer: {
    ContactUs: "联系我们",
    CompanyHotline: "公司热线",
    CustomerService1: "客 服: (025)84853032",
    CustomerService2: "客 服: 4000-025-828",

    DigitalDesignConsulting: "数字设计咨询",
    DesignMember1: "李经理(高校区域)：18618162992",
    DesignMember2: "徐经理(西南区域)：17372261215",
    DesignMember3: "吴经理(西北区域)：17730442539",
    DesignMember4: "谢经理(华南区域)：18120179732",
    DesignMember5: "艾经理(华北区域)：15840498611",
    DesignMember6: "徐经理(长三角区域)：17372261215",
    DesignMemberText: "(微信同号)",

    DigitalConstructionConsulting: "数字建造咨询",
    ConstructionMember1: "董经理：15280106801",
    ConstructionMember2: "伏经理：18505110818",
    ConstructionMemberText: "(微信同号)",

    Address: "地址",
    AddressDescribe: "江苏省南京市江宁区智识路26号启迪科技城立业园1幢7楼",
    WechatAccount: "公众号",
  },
  // 头部菜单
  HeaderBar: {
    webtitle: '江苏狄诺尼',

    product_type1_title: "数字化建造产品线",
    product_type1_item1_title: "数字化建设管理平台",
    product_type1_item1_content: "聚焦工程建设中进度、质量、安全、投资、档案等核心业务",
    product_type1_item2_title: "智慧工地管理平台",
    product_type1_item2_content: "面向工程施工“人机料法环”，构建AIoT智能网联一体化解决方案",
    product_type1_item3_title: "智能工厂管理平台",
    product_type1_item3_content: "集工业物联网、数字孪生、生产执行系统于一体的智能工厂",
    product_type2_title: "数字化设计软件产品线",
    product_type2_item1_title: "AIRoad道路工程快速方案设计系统",
    product_type2_item1_content: "专注于方案研究的三维快速方案设计软件",
    product_type2_item2_title: "EICAD狄诺尼集成交互式道路与立交设计系统",
    product_type2_item2_content: "以设计效率、质量提升为核心，引入数字设计新理念",
    product_type2_item3_title: "TICAD轨道交通线路设计系统",
    product_type2_item3_content: "专注于轨道交通线路设计，满足地铁、轻轨、有轨电车等",
    product_type2_item4_title: "VRRoad道路驾驶模拟与安全性评价系统",
    product_type2_item4_content: "面向道路几何设计及安全性评价的软、硬件集成解決方案",
    product_type2_item5_title: "Report3D狄诺尼三维汇报演示系统",
    product_type2_item5_content: "一款基于游戏引擎研发的、面向多类型工程的汇报工具",

    solution_type1_title: "解决方案",
    solution_type1_item1_title: "虚拟建设",
    solution_type1_item2_title: "VR技术",
    solution_type1_item3_title: "倾斜摄影",
    solution_type1_item4_title: "院校交通数字化与智能建造仿真实验室",

    cases_type1_title: "跨江大桥",
    cases_type1_item1_title: "张靖皋长江大桥智慧建设BIM协同管理平台",
    cases_type2_title: "高速公路",
    cases_type2_item1_title: "沪武高速改扩建BIM协同建设管理平台",
    cases_type3_title: "过江隧道",
    cases_type3_item1_title: "江阴靖江隧道BIM协同建设管理平台",
    cases_type3_item2_title: "海太长江隧道BIM协同建设管理平台",
    cases_type4_title: "城市快速路",
    cases_type4_item1_title: "腾龙大道智慧快速路工程数字化建设管理平台",
    cases_type5_title: "其他",
    cases_type5_item1_title: "华设盐城智能制造产业园数字孪生平台",
    cases_type5_item2_title: "汾湖（苏州南）站交通枢纽配套工程全过程BIM咨询服务",

    support_type1_title: "帮助文档",
    support_type2_title: "演示教学",
    support_type2_item1_title: "EICAD",
    support_type3_title: "更新日志",
  },
  // 首页
  pc_index: {
    Home: "首页",
    Product: "产品中心",
    Solution: "解决方案",
    Cases: "行业案例",
    NewsVideo: "资讯与活动",
    Download: "软件下载",
    Support: "用户支持",
    AboutUs: "联系我们",
    SaaS: "SaaS云平台",

    MoreZixun: "更多资讯",
    Videos: "视频",
    MoreVideos: "更多视频",
    ConsultingAndEvents: "资讯与活动",

    Cpjzx_title: "极致轻便的数字设计建造平台",
    Cpjzx_title2: "",
    Cpjzx_szsj: "数字设计",
    Cpjzx_szsj_title: "紫云基建·数字化设计软件产品线",
    Cpjzx_szsj_AIRoad_title: "AIRoad公路工程道路快速方案设计系统",
    Cpjzx_szsj_AIRoad_content: "道路快速方案设计系统，依托自主研发的BIM引擎，基于GIS、航测遥感等数据构建数字化地形，快速实现方案参数化和智能化设计。通过动态交互式布线，自动构建方案模型。持续挖掘AI大语言模型和内容生成技术，在创意方案、效果表达和辅助成图等应用场景，提升设计效率。能够提升方案设计效率8～10倍，提升方案设计综合效率20～30%。",
    Cpjzx_szsj_EICAD_title: "EICAD集成交互式道路与互通立交设计系统",
    Cpjzx_szsj_EICAD_content: "集成交互式道路与互通立交设计系统 EICAD，经过近30年的发展，目前已形成覆盖公路、市政及轨道交通，包括外业调查、数字地面模型、路线、互通立交、路基路面、桥涵、隧道、交通工程、给排水等多个专业模块的数字化系列设计软件全家桶。EICAD 设计软件始终坚持 “提升设计效率和设计品质，持续创新，不断丰富产品功能，为客户创造价值”。",
    Cpjzx_szsj_VRRoad_title: "VRRoad道路驾驶模拟与安全评价系统",
    Cpjzx_szsj_VRRoad_content: "道路驾驶模拟与安全性评价系统，按照OpenDrive国际标准，快速导入设计方案模型，形成精确描述沿线特征的模拟路网；实现了Level3级的轻量级车流仿真算法，真实模拟周边车辆运行行为。为客户提供多自由度的VR模拟驾驶体感；实时采集驾驶全过程数据，为道路方案的几何设计及其安全性评价提供科学分析的软硬件集成系统。",
    Cpjzx_sjjz: "数字建造",
    Cpjzx_sjjz_title: "紫云基建·数字化产品建造线",
    Cpjzx_sjjz_szhjgpt_title: "数字化建设管理平台",
    Cpjzx_sjjz_szhjgpt_content: "紫云基建·数字化建设管理平台,是针对交通工程建设周期内各参与建设单位协同管理和办公的工程;建设数字化综合管理平台。聚焦工程建设中的进度、质量、安全、计量、档案等核心业务，深度融合;BIM技术实现工程建设项目的数字孪生,为交通工程建设数字化转型提供全新的技术手段。",
    Cpjzx_sjjz_zhgd_title: "智慧工地管理平台",
    Cpjzx_sjjz_zhgd_content: "基于先进的IoT、5G、大数据、BIM等技术，以分项工程为精细化管控对象，以虚拟施工为技术手段，进行项目质量、安全等可视化集成化、协同化管理。",
    Cpjzx_sjjz_zhgc_title: "智能工厂管理平台",
    Cpjzx_sjjz_zhgc_content: "遵循IS95国际标准MES设计，综合运用大数据、云计算、5G、IoT、AI和BIM建造等技术，旨在组织工厂排产、提高生产效率、提升产品质量、减少生产周期，最终实现智能管理、智慧管控，打造“透明工厂”，由“制造”转向“智造”。",

    Szkcy_Title: "紫云基建 · 数字化、平台化、生态化的一体式解决方案",
    Szkcy_try: "立即体验",

    Szkcy_tab1_title: "数字勘测云",
    Szkcy_tab1_subTab1_title: "数字勘测平台",
    Szkcy_tab1_subTab1_1_title: "沿线调查",
    Szkcy_tab1_subTab1_1_content: "为线路的规划、设计、建设和运营提供丰富的地理信息和数据支持",
    Szkcy_tab1_subTab1_2_title: "数字与场地",
    Szkcy_tab1_subTab1_2_content: "对一个现实场地进行数字化建模，将其转化为数字模型",
    Szkcy_tab1_subTab1_3_title: "3DGIS",
    Szkcy_tab1_subTab1_3_content: "基于三维地理信息系统（3D GIS）技术对地理空间信息进行建模、分析、管理和可视化",
    Szkcy_tab1_subTab1_4_title: "电子沙盘",
    Szkcy_tab1_subTab1_4_content: "集成了数字地图、三维建模、影像模拟、数据查询、可视化分析等功能的交互式展示平台",
    Szkcy_tab1_subTab1_5_title: "智能视频叠加",
    Szkcy_tab1_subTab1_5_content: "将视频图像与其他地理空间信息进行叠加，从而实现对视频图像的智能分析和处理",

    Szkcy_tab2_title: "数字设计云",
    Szkcy_tab2_subTab1_title: "数字设计平台",
    Szkcy_tab2_subTab1_1_title: "快速方案设计",
    Szkcy_tab2_subTab1_1_content: "通过快速的构想、设计和验证，迅速生成多个方案，并进行详细设计和优化",
    Szkcy_tab2_subTab1_2_title: "道路工程BIM",
    Szkcy_tab2_subTab1_2_content: "利用BIM技术对道路工程进行数字化建模、协同设计、施工和运营管理的过程",
    Szkcy_tab2_subTab1_3_title: "桥隧工程BIM",
    Szkcy_tab2_subTab1_3_content: "利用BIM技术对桥隧工程进行数字化建模、协同设计、施工和运营管理的过程",
    Szkcy_tab2_subTab1_4_title: "港航工程BIM",
    Szkcy_tab2_subTab1_4_content: "利用BIM技术对道路港行进行数字化建模、协同设计、施工和运营管理的过程",
    Szkcy_tab2_subTab1_5_title: "智能选线设计",
    Szkcy_tab2_subTab1_5_content: "通过数字化技术和智能算法，自动化地对复杂设计问题进行优化和求解",
    Szkcy_tab2_subTab1_6_title: "智能造价报价",
    Szkcy_tab2_subTab1_6_content: "利用数字技术和智能算法，对设计项目进行造价分析和预算，从而得出准确、可靠的设计报价",

    Szkcy_tab3_title: "数字建造云",
    Szkcy_tab3_subTab1_title: "数字建管平台",
    Szkcy_tab3_subTab1_1_title: "数字孪生大屏",
    Szkcy_tab3_subTab1_1_content: "实现对建筑物或工程设施的全面监控和管理，提高设施的运行效率和安全性",
    Szkcy_tab3_subTab1_2_title: "工程业务管理",
    Szkcy_tab3_subTab1_2_content: "利用数字技术和信息管理系统，为工程项目提供全面、高效的业务管理支持和决策依据",
    Szkcy_tab3_subTab1_3_title: "征地拆迁管理",
    Szkcy_tab3_subTab1_3_content: "实现对征地拆迁项目的全面监控和管理，提高征地拆迁项目的效率、公正性和透明度",
    Szkcy_tab3_subTab1_4_title: "BIM+GIS引擎",
    Szkcy_tab3_subTab1_4_content: "对建筑物和周边环境的全面监控和管理，提高设计效率、减少错误，降低建设成本和风险",
    Szkcy_tab3_subTab1_5_title: "移动端App",
    Szkcy_tab3_subTab1_5_content: "实现移动办公、现场管理、数据采集等功能，提高工作效率和管理水平",
    Szkcy_tab3_subTab2_title: "智慧工地平台",
    Szkcy_tab3_subTab2_1_title: "大数据驾驶舱",
    Szkcy_tab3_subTab2_1_content: "实现对项目的全面监控和管理，提高建筑物和行业趋势管理的效率、准确性和可靠性",
    Szkcy_tab3_subTab2_2_title: "智能视频监控",
    Szkcy_tab3_subTab2_2_content: "对建筑工地、设备、人员等进行实时监控和分析，以提高安全性和管理效率",
    Szkcy_tab3_subTab2_3_title: "施工安全监测",
    Szkcy_tab3_subTab2_3_content: "对项目全面监测和检测，及时发现和解决施工中的安全隐患，提高施工过程中的安全性和效率",
    Szkcy_tab3_subTab2_4_title: "工程机械监控",
    Szkcy_tab3_subTab2_4_content: "及时发现和解决工程机械使用中的安全隐患，提高工程机械的安全性和效率",
    Szkcy_tab3_subTab2_5_title: "砼拌合场监控",
    Szkcy_tab3_subTab2_5_content: "对砼拌合场的生产过程和设备进行实时监测和检测，以保障砼生产的质量和安全",
    Szkcy_tab3_subTab2_6_title: "材料库存仓储",
    Szkcy_tab3_subTab2_6_content: "利用物联网技术、传感器、RFID等技术，对建筑材料的库存和仓储进行实时监测和管理",
    Szkcy_tab3_subTab3_title: "智能工厂平台",
    Szkcy_tab3_subTab3_1_title: "生产执行系统",
    Szkcy_tab3_subTab3_1_content: "利用生产自动化技术、物联网技术、云计算技术等，对生产过程进行实时监控和管理",
    Szkcy_tab3_subTab3_2_title: "智能张拉压浆",
    Szkcy_tab3_subTab3_2_content: "对预应力混凝土结构中的张拉和压浆过程进行实时监测和控制",
    Szkcy_tab3_subTab3_3_title: "蒸汽养护监控",
    Szkcy_tab3_subTab3_3_content: "对混凝土的养护过程进行实时监测和检测，以保障混凝土的质量和安全",
    Szkcy_tab3_subTab3_4_title: "移动采集终端",
    Szkcy_tab3_subTab3_4_content: "运用移动设备和相关软件，实现在建筑现场对工程信息进行采集、编辑、分析和管理",
    Szkcy_tab3_subTab3_5_title: "工厂智能决策",
    Szkcy_tab3_subTab3_5_content: "对工厂生产过程中的各项数据进行实时监测、分析和预测，实现生产过程的智能化决策和优化",
    Szkcy_tab3_subTab3_6_title: "数字孪生工厂",
    Szkcy_tab3_subTab3_6_content: "实现对生产过程的全面监测和管理，优化生产流程，提高生产效率和质量，降低生产成本",

    Szkcy_tab4_title: "数字管养云",
    Szkcy_tab4_subTab1_title: "数字公路管养",
    Szkcy_tab4_subTab1_1_title: "BIM轻量建模",
    Szkcy_tab4_subTab1_1_content: "实现对管养过程的全面监测和管理，提高管养效率和质量，降低管养成本",
    Szkcy_tab4_subTab1_2_title: "激光点云",
    Szkcy_tab4_subTab1_2_content: "通过激光扫描技术获取设施点云数据，实现对设施的检测和维护",
    Szkcy_tab4_subTab1_3_title: "InSAR监控",
    Szkcy_tab4_subTab1_3_content: "能够在大范围、高精度、高时空分辨率的情况下，实现地表形变的监测",
    Szkcy_tab4_subTab1_4_title: "档案知识图谱",
    Szkcy_tab4_subTab1_4_content: "将建筑物或基础设施的档案信息以知识图谱的形式进行建模和管理",
    Szkcy_tab4_subTab1_5_title: "数据生产服务",
    Szkcy_tab4_subTab1_5_content: "信息模型的制作、更新、维护和管理等服务，以便于数字管养中的信息管理、维护和分析",
    Szkcy_tab4_subTab2_title: "数字城市管养",
    Szkcy_tab4_subTab2_1_title: "城市轻量建模",
    Szkcy_tab4_subTab2_1_content: "城市场景进行数字化建模，并将建模结果以轻量级的形式进行呈现和应用",
    Szkcy_tab4_subTab2_2_title: "数字市政",
    Szkcy_tab4_subTab2_2_content: "运用数字化技术，对城市的基础设施和公共服务进行数字化建模、管理和智能化运营的过程",
    Szkcy_tab4_subTab2_3_title: "数字水务",
    Szkcy_tab4_subTab2_3_content: "应用数字化技术，对水资源的管理、调度、分配、监测和保护等进行数字化建模和智能化管理",
    Szkcy_tab4_subTab2_4_title: "智慧社区",
    Szkcy_tab4_subTab2_4_content: "对社区的各个方面进行数字化建模和智能化管理，以提高社区居民的生活质量和社区管理的效率",
    Szkcy_tab4_subTab2_5_title: "智慧园区",
    Szkcy_tab4_subTab2_5_content: "涉及园区的各个方面，如园区规划、园区建设、园区管理、园区服务等",

    Hyal_Title: "行业案例",
    Hyal_describe: "面向不同的客户群体，我们提供与您业务属性更匹配的产品服务",
    Hyal_text1: "想要了解基础设施勘测设计、施工建造、运维管养全过程、全周期、全要素的 行业解决方案与应用案例？",
    Hyal_text2: "欢迎咨询江苏狄诺尼销售顾问",
    Hyal_ContactUs: "联系我们",
    Hyal_img1_src: require("@/assets/images/hyalImg/1@2x.png"),
    Hyal_img1_fillsrc: require("@/assets/images/hyalImg/1_sel@2x.png"),
    Hyal_img2_src: require("@/assets/images/hyalImg/2@2x.png"),
    Hyal_img2_fillsrc: require("@/assets/images/hyalImg/2_sel@2x.png"),
    Hyal_img3_src: require("@/assets/images/hyalImg/3@2x.png"),
    Hyal_img3_fillsrc: require("@/assets/images/hyalImg/3_sel@2x.png"),
    Hyal_img4_src: require("@/assets/images/hyalImg/4@2x.png"),
    Hyal_img4_fillsrc: require("@/assets/images/hyalImg/4_sel@2x.png"),

    Gywm_Title: "关于我们",
    // Gywm_text1: "江苏狄诺尼是从事基础设施全生命周期数字化、智能化软件研发的高科技企业。公司成立于2018年，以“打造数字基建的中国芯”为初心，为行业数字化转型提供全方位的解决方案。公司的“紫云基建•数字化产品线”包含设计、建造和运维三大产品矩阵，努力在工业基础软件领域实现国产替代；贯通工程全生命周期，激活数据要素，构建基础设施数字孪生模型。公司于2018年由华设设计集团控股，是华设设计集团的控股子公司，也是集团重点扶持的科技型企业之一。",
    // Gywm_text2: "江苏狄诺尼作为国内基础设施设计软件的知名品牌，自主研发了紫云基建•数字设计产品线和紫云基建•数字化建造产品线两大系列软件产品。其中数字产品线包含：狄诺尼集成交互式道路与立交设计系统EICAD/EIBIM、公路工程道路快速方案设计系统 AIRoad、道路驾驶模拟与安全评价系统 VRRoad 1.0、紫城数字化汇报系统ZiCity。数字建造产品线包含：数字建设管理云平台、智慧工地云平台、智慧梁厂云平台；CIM管理平台等。软件产品具有功能齐全、自主核心算法先进、跨平台融合、应用覆盖面广等技术特点。",
    Gywm_text1: "江苏狄诺尼信息技术有限责任公司成立于2018年。公司致力于为工程师提供基础设施全生命周期的数智化软件产品和解决方案。我们的产品应用于基础设施行业（特别是交通、城建）规划、设计、建造和运维全生命周期。我们的产品提升工作和协同效率，帮助客户积累宝贵的数据资产，挖掘和激活数据要素，构建基础设施的数字孪生模型。",
    Gywm_text2: "江苏狄诺尼作为中国基础设施数字化的知名品牌，已有数字设计和智能建造两个产品线。设计产品线主要有：AIRoad(道路工程快速方案设计系统)、EICAD(集成交互式道路与立交设计系统)、VRRoad（道路驾驶模拟与安全评价系统）、Report 3D（三维汇报演示系统）、Delivery 3D（设计成果管理系统），等等。建造产品线包含：数字建设管理平台、智慧工地平台、智慧梁厂平台，等等。",
    Gywm_text3: "伴随着中国公路和城市建设的大规模发展，狄诺尼的软件产品有着20余年的发展历史。共取得发明专利11项，软件著作权39项。狄诺尼的软件产品服务于千余家客户企业，服务20000多名设计师用户。近年来，帮助客户完成道路设计方案总里程约30000公里，互通立交1000余座。狄诺尼的智能建造产品在40多个重大基础设施工程建设中投入使用，为高速公路、特大型桥梁和隧道工程提供BIM+GIS三维可视化、协同管理、计划管理和进度跟踪、现场报验和质量控制、智能化安全监督，电子档案，等等。",

    Hzhb_Title: "合作伙伴",
    Hzhb_describe: "江苏狄诺尼与中交公规院、中交隧道局、浙江交工、天津市政院等50余家企业建立深度合作关系",

  },
  // 产品-数字化建设管理平台
  productSzhjsgl: {
    banner: require("@/assets/images/productImg/banner@2x.png"),

    section1_title: "平台介绍",
    section1_content: "「紫云基建·数字建设管理平台」，是针对交通工程建设周期内各参与建设单位协同管理和办公的工程 建设数字化综合管理平台 。聚焦工程建设中的进度质量、安全、计量、档案等核心业务，深度融合 BIM 技术实现工程建设项目的数字李生，为交通工程建设数字化转型提供全新的技术手段。",
    section2_title: "典型工程「一桥一路一隧」",
    section3_title: "核心能力",
    section3_tab1_title: "BIM协同",
    section3_tab1_content1: "核心业务数据BIM模型同步展示",
    section3_tab1_content2: "工程管理三维可视化",
    section3_tab1_content3: "",
    section3_tab2_title: "计划进度",
    section3_tab2_content1: "项目计划编制 丨 进度定期航拍对比",
    section3_tab2_content2: "实际进度反馈",
    section3_tab2_content3: "",
    section3_tab3_title: "质量管理",
    section3_tab3_content1: "工序报验 丨 材料溯源",
    section3_tab3_content2: "隐患管理 丨 质检资料报审",
    section3_tab3_content3: "",
    section3_tab4_title: "计量支付",
    section3_tab4_content1: "合同管理 丨 计量支付",
    section3_tab4_content2: "变更管理",
    section3_tab4_content3: "",
    section3_tab5_title: "安全管理",
    section3_tab5_content1: "安保体系 丨 风险管控 丨 危大工程",
    section3_tab5_content2: "隐患排查 丨 人员、设备管理",
    section3_tab5_content3: "",
    section3_tab6_title: "电子档案",
    section3_tab6_content1: "质量资料体系 丨 试验资料体系",
    section3_tab6_content2: "统计分析 丨 电子签章 丨自动化流转",
    section3_tab6_content3: "",
    section4_title: "平台亮点",

    ptlds_default1: require("@/assets/images/productImg/ptld_1.png"),
    ptlds_cover1: require("@/assets/images/productImg/ptld_1@2x.png"),
    ptlds_default2: require("@/assets/images/productImg/ptld_2.png"),
    ptlds_cover2: require("@/assets/images/productImg/ptld_2@2x.png"),
    ptlds_default3: require("@/assets/images/productImg/ptld_3.png"),
    ptlds_cover3: require("@/assets/images/productImg/ptld_3@2x.png"),
    ptlds_default4: require("@/assets/images/productImg/ptld_4.png"),
    ptlds_cover4: require("@/assets/images/productImg/ptld_4@2x.png"),
    ptlds_default5: require("@/assets/images/productImg/ptld_5.png"),
    ptlds_cover5: require("@/assets/images/productImg/ptld_5@2x.png"),
    ptlds_default6: require("@/assets/images/productImg/ptld_6.png"),
    ptlds_cover6: require("@/assets/images/productImg/ptld_6@2x.png"),
    ptlds_default7: require("@/assets/images/productImg/ptld_7.png"),
    ptlds_cover7: require("@/assets/images/productImg/ptld_7@2x.png"),
    ptlds_default8: require("@/assets/images/productImg/ptld_8.png"),
    ptlds_cover8: require("@/assets/images/productImg/ptld_8@2x.png"),
    ptlds_default9: require("@/assets/images/productImg/ptld_9.png"),
    ptlds_cover9: require("@/assets/images/productImg/ptld_9@2x.png"),

  },
  // 产品-智慧工地
  zhgd: {
    banner: require("@/assets/images/productImg/zhgd_banner@2x.png"),

    section1_title: "平台介绍",
    section1_content: "「紫云基建· 智慧工地管理平台」基于先进的IОT、5G、大数据、BIM等技术 , 以分项工程为精细化管控对象,以虚拟施工为技术手段 , 进行项目质量、安全等可视化、集成化、协同化管理。",
    section2_title: "平台建设原则",
    section2_content: "通过监测监控硬件,构建「AIoT综合感知工地」;围绕“人机料法环”,实现标准化、数字化和智能化的管控模型,支持施工现场管理的软硬件应用系统。",
    section2_img1: require("@/assets/images/productImg/ptjsyz@2x.png"),
    section3_title: "智慧运输解决方案",
    section3_img1: require("@/assets/images/productImg/zhysjjfa@2x.png"),
    section3_content: "主要包括:3600全景主动安全防控、货箱智能监控、驾驶员行为分析、企业监管平台。为驾驶员提供主动安全驾驶辅助、驾驶行为检测、盲区监测告警; 为企业和管理部门提供位置、路径监控 , 超载超限、沿途散落、偷倒乱倒等违章取证 , 运行调度和统计管理功能。",
    section4_title: "数字化施工·智能感知 ·大数据分析 ·A1辅助决策",
    section4_title2: "",

    section4_list1_item1: "人员定位",
    section4_list1_item2: "设备状态监测",
    section4_list1_item3: "智能地磅",
    section4_list1_item4: "实验室数据管控",
    section4_list1_item5: "拌合站生产数据<br/>采集管控",
    section4_list1_item6: "混合料运输监控",
    section4_list1_item7: "智能摊铺",
    section4_list1_item8: "智能碾压",
    section4_list1_item9: "视频监控+AI图像识别",
    section4_list1_item10: "环境监测",
    section4_list1_item11: "监控测量",

    section4_list2_item1: "人员管理",
    section4_list2_content1: "人员信息管理 丨 考勤管理 丨 劳务管理<br/>人员定位 丨 培训教育",
    section4_list2_item2: "物料管理",
    section4_list2_content2: "收验货管理 丨 二维码领料 丨 材料管理系统<br/>智能地磅系统",
    section4_list2_item3: "综合管理",
    section4_list2_content3: "组织管理 丨 合同管理 丨 进度管理<br/>计量管理 丨 办公管理",
    section4_list2_item4: "质量管理",
    section4_list2_content4: "工序管理 丨 试验检测管控 丨 路基施工管控<br/>面层施工管控 丨 桥涵隧道施工管控",
    section4_list2_item5: "生态环保",
    section4_list2_content5: "扬尘噪音监测 丨 水电能耗监测 丨 污水监测<br/>土壤监测",
    section4_list2_item6: "安全管理",
    section4_list2_content6: "风险源管理 丨 危大工程管控 丨 视频管理<br/>安全教育",
    section4_list2_item7: "设备管理",
    section4_list2_content7: "架桥机安全监测 丨 龙门吊安全监测 丨 塔吊安全监测<br/>升降机安全监测",
    section4_list2_item8: "BIM管理",
    section4_list2_content8: "施工模拟 丨 进度可视化管理 丨 质量可视化管理<br/>安全可视化管理",
    section4_list2_item9: "基础配置",
    section4_list2_content9: "生产建模配置 丨 产品工艺配置 丨 生产人员管理<br/>质量管理配置 丨 仓储建模配置",
    section4_list2_item10: "现场数据采集",
    section4_list2_content10: "钢筋笼安装采集 丨 模板组装采集<br/>混凝土浇筑采集 丨 蒸汽养生采集<br/>张拉压浆采集",
    section4_list2_item11: "计划排产派工",
    section4_list2_content11: "合约订单管理 丨 作业计划管理 丨 生产线智能排产<br/>派工单管理 丨 工单生产监控",
    section4_list2_item12: "质量管理",
    section4_list2_content12: "来料IQC 检验 丨 生产过程检验 丨 不良评审管理<br/>质量追溯档案 丨 质量统计SPC",
    section4_list2_item13: "安全管理",
    section4_list2_content13: "人车定位 丨 安全巡检单 丨 安全隐患管理<br/>安全隐患统计 丨 安全活动管理",
    section4_list2_item14: "协同消息",
    section4_list2_content14: "日常通知 丨 排产发布 丨 工序作业提醒<br/>质量异常报警 丨 装车发运通知",
    section4_list2_item15: "仓储物流管理",
    section4_list2_content15: "收货入库管理 丨 配送发料管理 丨 库内业务管理<br/>库存盘点管理 丨 精确库存管理",
    section4_list2_item16: "设备管理",
    section4_list2_content16: "设备档案 丨 设备保养管理 丨 设备维修管理<br/>设备监控管理 丨 设备知识库管理",
    section4_list2_item17: "报表中心",
    section4_list2_content17: "订单完工与发运统计 丨 排产甘特图<br/>派工单履约率统计 丨 检验合格率统计<br/>堆场库容分析统计",
    section4_list2_item18: "车间物料管理",
    section4_list2_content18: "工单物料配送管理 丨 车间线边库存管理<br/>车间WIP管理 丨 车间库存监控 丨 堆场库存管理",

  },
  // 产品-智能工厂
  zngc: {
    banner: require("@/assets/images/productImg/zngc_banner@2x.png"),

    section1_title: "平台介绍",
    section1_content: "「紫云基建· 智能工厂管理平台」遵循ISA95国际标准MES设计, 综合运用大数据、云计算、5G、IoT、AI和BIM建造等技术,旨在组织工厂排产、提高生产效率、提升产品质量、减少生产周期,最终实现智能管理、智慧管控,打造透明工厂,由 “制造”转向 “智造”。",
    section2_title: "数字工厂 智能感知",
    section2_content: "全方位的智能分析、全方面的智能监控，全天候的实时监控管理,配套智能软硬件,系统可以进行集成监控,实现智能管理.智慧管控 , 打造透明工厂。",
    section2_list1_item1: "人车定位",
    section2_list1_item2: "起重检测",
    section2_list1_item3: "智能地磅",
    section2_list1_item4: "AI危险识别",
    section2_list1_item5: "仓储管理",
    section2_list1_item6: "门禁闸机",
    section2_list1_item7: "电箱监测",
    section2_list1_item8: "大气监测",
    section2_list1_item9: "用水监测",
    section2_list1_item10: "用电监测",
    section2_list1_item11: "喷淋控制",
    section2_list1_item12: "视频监控",
    section2_list1_item13: "支模监测",
    section2_list1_item14: "构件跟踪",
    section2_list1_item15: "水质监测",

    section3_title: "平台特点",
    section3_list1_item1: "基于模台配置规则的自动拆单排产，自动配置每周生产能力，自动排产计划到生产线，提供撤销、调序功能 , 一键发布派工。",
    section3_list1_item2: "构件唯一身份终身使用，可随时扫码查看构件档案。",
    section3_list1_item3: "生产过程精准记录，产品档案精确追踪；快速定位问题批次物料；精确统计分析产品的完整生产过程档案  , 保证产品质量。",
    section3_list1_item4: "库存数据实时更新，快速定位构件；移动端转储入库采集；根据规则自动进行台座推荐。",
    section3_list1_item5: "即时协同消息，指导生产作业。",
    section3_list1_item6: "沉浸式虚拟现实场景。",
    section3_list1_item7: "为管理者构建实时高效的数据统计和分析，辅助决策。",
    section3_list1_item8: "实时掌控人员状态。",
    section3_list1_item9: "建立构建完整的生产档案，进行质量分析评定。",
    section3_list1_item10: "生产设备全生命周期管理。",

  },
  // 产品-AIRoad
  airoad: {
    banner: require("@/assets/images/productImg/airoad_banner@2x.png"),

    section1_title: "产品介绍",
    section1_content: "「AIRoad道路工程快速方案设计系统」是一款专注于前期研究的三维快速方案设计软件，软件基于自主知识产权的三维渲染引擎，遵循二维设计习惯，融入自动设计理念，实现从设计输入→快速方案布设→方案展示、比选→成果输出的全专业整体解决方案。软件主要功能包括三维环境、地形模型的创建 , 道路三维平纵横联动设计 , 桥隧参数化快速设计，互通立交自动设计以及交安设施自动布置等功能，设计数据与 EICAD互通，可满足从方案研究到初步设计到施工图设计的全过程、全专业的设计需求。",
    section2_title: "功能亮点",
    section2_list1_item1: "方案布设快速  协同修改方便",
    section2_list1_content1: "方案布设时通过简单的参数输入，实现自动布设，设计者可通过鼠标的点击和拖拽，就可以迅速的对相关方案进行编辑和修改，实现一键协同。",
    section2_list1_item2: "设计输入便捷",
    section2_list1_content2: "支持多种地形数据的下载与上传，支持CAD图、JPG图片的导入等等，满足工可及方案研究地形及控制因素输入的要求。",
    section2_list1_item3: "输出的内容完备",
    section2_list1_content3: "输出内容包括工可图纸、场景模型、漫游视频、数据文件、主要工程量和匡图、方案比选、方案展示及设计数据向详细设计阶段传递的要求。",
    section2_list1_item4: "多专业协同",
    section2_list1_content4: "方案布设时通过简单的参数输入，实现自动布设，设计者可通过鼠标的点击和拖拽，就可以迅速的对相关方案进行编辑和修改，实现一键协同。",
    section2_list1_content4_title1: "互通立交设计",
    section2_list1_content4_content1: "沿用了 EICAD模式法设计思路，并且可以实现自动拉坡，一键生成所有的立交端部实体。",
    section2_list1_content4_title2: "桥梁设计设计",
    section2_list1_content4_content2: "",
    section2_list1_content4_title3: "隧道设计",
    section2_list1_content4_content3: "",
    section2_list1_content4_title4: "交叉口路网设计",
    section2_list1_content4_content4: "",
  },
  // 产品-EICad
  eicad: {
    banner: require("@/assets/images/productImg/eacid_banner@2x.png"),

    section1_title: "产品介绍",
    section1_content1: "「EICAD数字设计系列软件」基于集成交互式道路与互通立交设计的基础模块上，经过多年的发展，目前已形成覆盖公路、市政及轨道交通，包括外业调查、数字地面模型、路线、互通立交、路基路面、桥涵、隧道、交通工程、给排水等多个专业模块的数字设计软件全家桶。EICAD设计软件始终坚持“提升设计效率和设计品质”，持续创新，不断丰富产品功能，为客户创造价值，为基础设施建设提供优质的数字化软件产品。",
    section1_content2: "EICAD系列软件作为交通行业主流设计软件，与用户建立了广泛的、长期的双赢合作关系，合作伙伴遍及全国各省、市、自主区的公路、市政、铁道、城建等数百家设计院、高校和建设管理单位。",
    section1_img: require("@/assets/images/productImg/model_bg@2x.png"),

    section2_title: "软件特色与优势",
    section2_item1: "平纵横快速设计",
    section2_item2: "数据联动 修改便捷",
    section2_item3: "复杂线路和立交适应性强",
    section2_item4: "拥有自动设计模块效率高",
    section2_item5: "平纵横快速设计",
    section2_item6: "三维正向设计 品质高",
    section2_item7: "专业覆盖全",
    section2_item8: "自动化出图与图纸管理",
    section2_img: require("@/assets/images/productImg/eicad_img@2x.png"),

    section3_title: "互通立交设计",
    section3_content1: "EICAD5.0 互通立交模块汇集了国内领先的路线曲线型设计思想，用户可以使用数据输入、鼠标拖动等方法，更加灵活地处理线位。道路中线上的若干夹点的任意拖拽，如同摆弄桌面上的一根绳索，方便用户快速、准确地完成互通立交布线和成图。",
    section3_content2: "软件同时实现了单喇叭、双喇叭、菱形、苜蓿叶型等常见的互通立交形式的全自动布线，使用该功能，一个平面方案图的完成时间小于1分钟。",

    section4_title: "边坡连接部设计",
    section4_content1: "EICAD5.0 互通立交连接部设计既直观又高效。",

    section5_title: "功能模块",
    section5_img_default1: require("@/assets/images/productImg/eicad_item1.png"),
    section5_img_cover1: require("@/assets/images/productImg/gnmk_1@2x.png"),
    section5_img_default2: require("@/assets/images/productImg/eicad_item2.png"),
    section5_img_cover2: require("@/assets/images/productImg/gnmk_2@2x.png"),
    section5_img_default3: require("@/assets/images/productImg/eicad_item3.png"),
    section5_img_cover3: require("@/assets/images/productImg/gnmk_3@2x.png"),
    section5_img_default4: require("@/assets/images/productImg/eicad_item4.png"),
    section5_img_cover4: require("@/assets/images/productImg/gnmk_4@2x.png"),
    section5_img_default5: require("@/assets/images/productImg/eicad_item5.png"),
    section5_img_cover5: require("@/assets/images/productImg/gnmk_5@2x.png"),
    section5_img_default6: require("@/assets/images/productImg/eicad_item6.png"),
    section5_img_cover6: require("@/assets/images/productImg/gnmk_6@2x.png"),
    section5_img_default7: require("@/assets/images/productImg/eicad_item7.png"),
    section5_img_cover7: require("@/assets/images/productImg/gnmk_7@2x.png"),
    section5_img_default8: require("@/assets/images/productImg/eicad_item8.png"),
    section5_img_cover8: require("@/assets/images/productImg/gnmk_8@2x.png"),
    section5_img_default9: require("@/assets/images/productImg/eicad_item9.png"),
    section5_img_cover9: require("@/assets/images/productImg/gnmk_9@2x.png"),

  },
  // 产品-TICad
  ticad: {
    banner: require("@/assets/images/productImg/ticad_banner@2x.png"),

    section1_title: "产品介绍",
    section1_content1: "「TICAD轨道交通线路设计软件」能够用于地下铁路、轻轨、有轨电车等线路专业的各阶段设计，适应用户的不同设计阶段的需求。软件结合现行相关技术规范的基础上，紧密联系设计实际需求；体现线路设计新思想、新方法，通过不断的升级迭代，用户操作界面更加友好、更加简便易学。",
    section1_content2: "道岔设计、编辑和查询 | 直接式和曲线式渡线设计 | 车站、道岔的绘制与标注 | 自动搜索双线轨道间距 | 自动生成新的偏置线路",

  },
  // 产品-VRRoad
  vrRoad: {
    banner: require("@/assets/images/productImg/vrroad_banner.png"),

    section1_title: "产品介绍",
    section1_content1: "道路驾驶模拟与安全评价系统VRRoad，是面向道路几何设计及安全性评价的软、硬件集成解决方案。系统可快速导入设计成果，并参照OpenDrive标准生成精确描述车道及道路沿线特征的模拟路网，实现信息完备的实验数据同步采集，支持多自由度运动平台驱动，提供逼真的驾驶体感。",

    section1_img1: require("@/assets/images/productImg/vrRoadImg1.png"),
    section1_img2: require("@/assets/images/productImg/vrRoadImg2.png"),
    section1_img3: require("@/assets/images/productImg/vrRoadImg3.png"),
    section1_img4: require("@/assets/images/productImg/vrRoadImg4.png"),
  },
  // 产品-Report3D
  Report3D: {
    banner: require("@/assets/images/productImg/bannerReport3D.png"),

    section1_content: "狄诺尼三维汇报演示系统Report3D，是一款基于游戏引擎研发的、面向多类型工程的汇报工具，实现实时渲染的高效场景创造、PPT的复杂制作功能，使用户能够实时查看和操作数字孪生模型。不需要复杂的安装或配置，降低三维场景使用门槛，让更多的人能够轻松参与三维场景搭建与PPT汇报。",
    section2_content: "系统以一种三维的、动态的、可交互的汇报方式，探索数字时代成果展示的新模式，以微电影的形式去讲述项目，打造沉浸式体验场景。",
    section3_title: "基于游戏引擎的数字孪生编辑器",
    section3_content: "软件基于游戏引擎开发，支持高效场景创作，可在场景中布设人、车、树，提高场景的真实性、美观性；满足工程项目应用中的多种需求；同时支持在软件中制作汇报页面，插入文字、图片、表格、视频等，满足汇报的各项要求。",
    section3_content2: "软件提供了直观、交互式的可视化编辑环境，使用户能够轻松地创建、编辑和管理场景模型。这种可视化环境包括拖放式的模型、实时预览、交互式调整参数等功能，使用户能够快速而直观地构建复杂的汇报场景。紫城集成AIRoad+VRRoad软件传递的车道信息，并可进行车流模拟，进一步增强仿真效果。",
    section4_title: "轻松制作三维沉浸式汇报PPT",
    section4_content: "Report3D进行本地化安装部署，从而降低使用难度，保证了更高的数据安全性。作为一款工具级系统，学习成本低，软件保留二维交互习惯，与PPT软件操作一致，让用户在制作沉浸式故事板时更加便捷，大幅降低学习成本。设计师能快速掌握制作方法，自主操作，具备快速普及推广应用的基础。",

    img_title1: 'Report3D项目管理器',
    img_title2: 'Report3D素材广场',

    section1_img1: require("@/assets/images/productImg/Report3DImg1.png"),
    section1_img2: require("@/assets/images/productImg/Report3DImg2.png"),
    section1_img3: require("@/assets/images/productImg/Report3DImg3.png"),
    section1_img4: require("@/assets/images/productImg/Report3DImg4.png"),
    section1_img5: require("@/assets/images/productImg/Report3DImg5.png"),
    section1_img6: require("@/assets/images/productImg/Report3DImg6.png"),
    section1_img7: require("@/assets/images/productImg/Report3DImg7.png"),
    section1_img8: require("@/assets/images/productImg/Report3DImg8.png"),

    videoUrl: '2024-07-05/e3df05a3-0200-42ef-8385-18f43a7c86fd.mp4',

    cover1: require("@/assets/images/productImg/Report3DCover.png"),
  },
  // 解决方案-虚拟建设
  virtualConstruction: {
    section1_title: "虚拟建造",
    section1_content1: "传统项目管理中，由于手段、工具匮乏，只能采用图表方式描述施工工艺过程、进度安排及资源配置。组织计划难以考虑复杂的现场条件，无法表达工作界面之间的复杂关系。对施工过程中动态变化过程，优化分配资源所需的数据以及施工场地真实状况等都无法表达。造成了施工进度计划不切实际，现场变化反应不及时，资源分配不均等现象，这种凭经验的管理模式不能适应现代基础设施精细化施工管理的要求。",
    section1_content2: "企业利用BIM模型将施工中所需的数据进行采集、分析、存储，再关联时间维度4DBIM进度管理模型，配合相关的BIM软件对项目进行进度施工模拟，合理制定施工计划、精确掌握施工进程，优化使用施工资源以及科学地进行场地布置，对整个工程的施工进度、资源和质量进行统一管理和控制，以缩短工期，降低成本，提高质量。",
    section1_content3: "管片厂生产管片全工艺流程：在管片厂生产车间将钢筋切断，弯制，焊接，组成钢筋笼后起吊，然后清理管片预制模具，喷脱模剂，测量尺寸，再将钢筋笼入模，合模，安装预埋件，随后混凝土浇筑，收面，最后将管片蒸养，脱模，翻转，水养，发运。模拟真实的管片预制生产的全工艺流程，直观反映干涉和冲突，不断优化施工工艺流程。",
  },
  // 解决方案-VR技术
  vr: {
    section1_title: "虚拟现实技术与应用",
    section1_content1: "如今虚拟现实技术在高速公路与桥梁建设以及各类工程建设中得到广泛应用。",
    section1_content2: "该技术用性强、操作简单、功能强大、高度可视化、所见即所得，他的在高速公路和道路桥梁建设方面有着非常广阔的应用前景，如工程背景介绍,标段概况,技术数据,截面等,电子地图,声音、图像、动画，并与核心的虚拟技术产生交互，从而实现演示场景中的导航、定位与背景信息介绍等诸多实用、便捷的功能。",
  },
  // 解决方案-倾斜摄影
  photo: {
    section1_title: "无人机倾斜摄影建模与应用",
    section1_content1: "随着科技的发展，测绘行业对于地理信息数据的精确性、时效性要求越来越高，人工成本和时间成本也为行业带来巨大的压力和负担，因此，测绘行业需要能够快速、高效、准确的获取地理信息和数据的解决方案。",
    section1_content2: "海量的倾斜模型数据满足了房产、国土、城管、智慧城市、规划、水利、能源开采等行业应用提供了基础平台。同时基于GPU的三维空间分析功能与倾斜摄影模型的高精度的结合，可以在突发事件时快速反应事发地周围的详细信息，在应急行动中对人员及财产的安全有时甚至能起到决定性作用。",
  },
  // 解决方案-院校交通数字化与智能建造仿真实验室
  laboratory: {
    banner: require("@/assets/images/laboratoryImg/banner.png"),
    section1_title: "院校交通数字化与智能建造仿真实验室",
    section1_content: "面向院校师生，狄诺尼提供交通数字化与智能建造仿真实验室解决方案，主要包括两大部分：数字化设计实训平台和智能建造仿真平台，旨在提升学生对道桥隧数字化设计和建造全流程的理论知识运用与项目实操能力，拓展学生就业渠道，赋能高校应用型、复合型人才的培养。",
    section1Bg1: require("@/assets/images/laboratoryImg/section1Bg1.png"),

    section2_title: '1. 数字化设计实训平台',
    section2_content: "数字化设计实训是集“地形创建、勘测模拟仿真，道桥及立交二三维设计实训，道路驾驶模拟仿真及数字化汇报”于一体的实训平台，完全满足高校数字化设计教学实训需求，通过沉浸式教学体验，提升学生实践能力与创新能力。",
    section2_content2: '数字化设计实训平台”已覆盖了高校教学与行业设计的全过程。“数字化设计实训平台”中的软件产品作为交通行业主流设计软件，用于公路与市政从方案设计、工可到施工图设计、出图和工程算量、设计仿真验证与数字化汇报，已形成完整的SaaS服务。与众多设计单位、高校建立了广泛的、长期的双赢合作关系。',
    section2_content3: '近两年来帮助设计单位完成立交方案900余座，道路方案总里程约43000公里，接近绕地球一圈。合作伙伴遍及全国各省、市、自治区上千家设计院、高校和建设管理单位。同时获得了很多的奖项荣誉与认证，如：“2023年度江苏省行业领域十大科技进展”中的“自主可控的公路工程数字设计与仿真关键技术研究”的认证等。',
    section2Bg1: require("@/assets/images/laboratoryImg/section2Bg1.png"),
    section2Bg2: require("@/assets/images/laboratoryImg/section2Bg2.png"),
    section3Bg2_title: '道路勘测模拟训练软件界面',

    section3_title: '1.1 道路勘测模拟训练软件',
    section3_content: "通过手持三维激光扫描仪对沙盘地形进行扫描，通过处理后得可以得到自主创建的地形数据资料，导入VRS道路勘测模拟训练软件内进行选线模拟，也可导入EICAD或AIROAD进行项目设计。",
    section3Bg1: require("@/assets/images/laboratoryImg/section3Bg1.png"),
    section3_content2: 'VRS道路勘测模拟训练软件，通过三维仿真技术模拟道勘训练场地，学生可在教师指导下或自学完成道勘测量训练和道路野外选线，以熟悉测量仪器操作、选线过程和定线计算方法，以及道路勘测课程相关知识点学习、复习与测试。满足土木工程专业学生对道路勘测模拟训练的要求。',
    section3Bg2: require("@/assets/images/laboratoryImg/section3Bg2.png"),

    section4_title: '1.2 道路数字设计软件',
    section4_content1: "（1）AIRoad三维快速方案设计系统",
    section4_content1_2: '基于自主可控的三维图形引擎研发，融合BIM+GIS 技术，提供信息化、数字化道路工程设计解决方案。软件涵盖地形处理、道路平纵横设计、桥梁、隧道、互通立交、沿线设施、景观及成果输出等功能模块，有助于学生了解前沿三维设计技术，并通过实际项目实训，快速完成设计思想的三维模型综合展示，给学生带来直观、可视的三维成果，大幅提升学习的获得感。',
    section4_content1_Bg1: require("@/assets/images/laboratoryImg/section4_content1_Bg1.png"),
    section4_content1_Bg1_title: 'AIROAD设计界面',
    section4_content1_Bg2: require("@/assets/images/laboratoryImg/section4Bg11.png"),
    section4_content1_Bg2_title: 'AIROAD三维模型成果展示',
    section4_content2: '（2）EICAD道路与互通立交设计系统',
    section4_content2_1: '满足公路、市政道路以及互通立交的各类工程设计项目，含外业调查、数字地面模型、路线、互通立交、路基路面、桥涵、隧道、交通工程、排水等专业的一款数字化设计软件。以最新的国家及行业设计技术规范为标准，满足初步设计、施工图设计的功能需求，对高校详细设计的教学具有很强的应用价值。',
    section4_content2_2: 'EICAD具有自动化、参数化和可视化的特性，采用图形化的交互理念，模型即数据，学生易于理解，学习难度低。依托EICAD软件完成实际项目的设计实训，能有效加深学生对理论知识的理解，提升学生项目设计的实操能力。',
    section4_content2_Bg1: require("@/assets/images/laboratoryImg/section4_content2_Bg1.png"),
    section4_content2_Bg1_title: 'EICAD设计流程图',
    section4_content2_Bg2: require("@/assets/images/laboratoryImg/section4_content2_Bg2.png"),
    section4_content2_Bg2_title: 'EICAD软件的人机交互界面',

    section5_title: '1.3 道路模拟驾驶与安全性评价系统',
    section5_content1: "VRRoad道路驾驶模拟与安全评价系统，是面向道路几何设计方案及其安全性评价的软、硬件集成解决方案。可用于沉浸式教学与科研，实现道路设计与仿真模拟的一体化应用。在驾驶模拟场景中，学生可亲身体验自己设计成果的实际驾驶感受。VRROAD参照OpenDrive标准生成精确描述车道及道路沿线特征的模拟路网，实现信息完备的实验数据同步采集，支持多自由度运动平台驱动，提供逼真的驾驶体感，快速实现设计方案的合理性和安全性的仿真验证。",
    section5_content1_bg1: require("@/assets/images/laboratoryImg/section5_content1_bg1.png"),
    section5_content1_Bg1_title: 'VRROAD道路驾驶模拟与安全评价系统',
    section5_content1_Bg2: require("@/assets/images/laboratoryImg/section5_content1_Bg2.png"),
    section5_content1_Bg2_title: 'VRROAD驾驶模拟数据分析平台',

    section6_title: '1.4 数字化汇报',
    section6_content1: "三维汇报演示系统Repor3D是一款基于游戏引擎自主研发的突破性的三维场景编辑与汇报软件。软件将三维引擎技术与传统PPT结合，融合BIM模型、内置丰富的模型库与标绘，提供灵活易用的场景编辑工具，学生可根据想象力自由添加建筑、车流、交通设施等资源丰富环境，搭建属于自己的游戏模型场景。",
    section6_content2: 'Report3D为各专业老师和学生提供全新的汇报创意与成果体验，在毕业设计、课程设计中进行可视化的汇报，在答辩汇报阶段，评委、教师与学生可以在三维场景中对模型进行可视化汇报与讨论，助力优秀毕业生的培养。',
    section6_content1_bg1: require("@/assets/images/laboratoryImg/section6_content1_bg1.png"),
    section6_content1_Bg2: require("@/assets/images/laboratoryImg/section6_content1_Bg2.png"),

    section7_title: '2. 智能建造仿真平台',
    section7_content1: "智能建造仿真平台，融合了数字化建管、数字化施工、智能化制造与数字仿真资源库，结合项目实践案例，旨在为高校交通智能建造实训及教学数字资源库的建设提供技术支持与服务。通过智能建造仿真平台的辅助教学，学生不仅可掌握相关技能，还能沉浸式体验数字化建造流程及生产一线的岗位职责与能力需求，对高校培养具有完备知识体系、高职业素质能力的复合型、应用型人才具有重要意义。",
    section7_content1_bg1: require("@/assets/images/laboratoryImg/section7_content1_bg1.png"),
    section7_content1_bg1_title: '智能建造仿真平台组成',
    section7_content2: "智能建造类平台，项目应用案例众多，系统积累了大量有价值的数据（模型、建造流程、施工工艺等）。已成为江苏省高速公路和过江通道建设协同管理统一平台，并获得了很多的奖项荣誉，如：第一届“姑苏杯”长三角智能交通创新技术应用大赛的一等奖、“中国交通运输协会科学技术奖”中“科技进步奖”的一等奖等。",
    section7_content1_Bg2: require("@/assets/images/laboratoryImg/section7_content1_Bg2.png"),

    section8_title: '2.1 数字化建管',
    section8_content1: "数字化建管板块，内置道桥隧虚拟项目的BIM模型（可根据需求定制虚拟项目），通过虚拟项目的导入、权限及角色的配置，可以让学生沉浸式体验建造过程，了解数字化管理前沿技术，体验各角色的职能需求和关注重点。",
    section8_content1_bg1: require("@/assets/images/laboratoryImg/section8_content1_bg1.png"),
    section8_content1_bg1_title: '数字化建管平台BIM协同界面',

    section9_title: '2.2 数字化施工',
    section9_content1: "数字化施工板块，内置道桥隧数字化施工实施方案、理论知识、应用场景及案例介绍。学生可在线进入某个正在施工的工地，了解与查看现场施工活动及数字化施工过程中积累的相关成果。提升学生在施工管理方向的实践能力与创新能力。",
    section9_content1_bg1: require("@/assets/images/laboratoryImg/section9_content1_bg1.png"),
    section9_content1_bg1_title: '狄诺尼智慧工地平台架构图',

    section10_title: '2.3 智能化制造',
    section10_content1: "智能化制造板块，内置道桥隧预制构件模型及其施工工艺、生产流程等教学实训资料，学生可以在线进入工厂管理系统，了解与查看工厂现场制造活动及生产、调度流程，实习时也可实地进入华设盐城智能制造厂参观学习。提升学生在智能制造方向的实践能力与创新能力。",
    section10_content1_bg1: require("@/assets/images/laboratoryImg/section10_content1_bg1.png"),
    section10_content1_bg1_title: '狄诺尼数字孪生智能梁厂平台',

    section11_title: '2.4 教学仿真资源库',
    section11_content1: "依托实际项目数字化服务的积累，智能建造教学仿真平台—数字仿真资源库板块，目前已有丰富的道、桥、隧BIM模型（可通过3D打印，制作实体模型）及其对应的施工工艺视频。通过工艺仿真库的教学，可以让学生快速熟悉道、桥、隧的构件，同时熟悉掌握各构件的施工工艺及相关技术要求，能有效提升学生专业能力，拓展知识面。交互式施工仿真软件：依据实际项目标准化、先进化的施工流程，打造的交互式施工仿真软件，可让学生沉浸式的参与施工实施，有效提升学生施工岗位的职业素养与就业竞争力。",
    section11_content1_bg1: require("@/assets/images/laboratoryImg/section11_content1_bg1.png"),
  },
  // 行业案例-跨江大桥
  crossRiverBridge: {
    section1_title: "张靖皋长江大桥智慧建设BIM协同管理平台",
    section1_content1: "张靖皋长江大桥处于长三角城市群中心，位于重点规划的锡常泰、（沪）苏通都市圈和沿江经济发展带的结合处，以苏州、南通、泰州和无锡四大城市作为依托，推动城市群跨江融合发展，直接连通南岸张家港和北岸如皋、靖江。",
    section1_content2: "张靖皋长江大桥跨江段包含两座航道桥和三段引桥，南航道桥为2300m的悬索桥，北航道桥为1208m的悬索桥，三段引桥采用预制拼装结构桥梁，项目建成后将成为世界最大跨径桥梁工程。其中，南航道桥作为张靖皋长江大桥跨江段控制性工程，创下六项“世界之最”。",
    section1_content3: "（1）世界最大跨度悬索桥：南航道桥主跨2300米；",
    section1_content4: "（2）世界最高悬索桥索塔：南航道桥主塔塔高350米；",
    section1_content5: "（3）世界最长高强度主缆：南航道桥主缆长度4400米，主缆强度2200MPa；",
    section1_content6: "（4）世界最大复合地连墙锚碇基础：南航道桥南锚碇基础长110米，宽75米，地连墙深83米，基坑开挖51米；",
    section1_content7: "（5）世界最长连续长度钢箱梁：南航道桥为2300+717米两跨吊连续钢箱梁悬索桥，最大连续长度3017米；",
    section1_content8: "（6）世界最大位移量伸缩装置：南航道桥钢箱梁两端设置伸缩量为3120毫米的伸缩装置。",

    section2_title: "智慧建设 BIM协同管理平台",
    section2_content1: "张靖皋长江大桥具有工程规模大，构件多样，工艺复杂的特点。项目包括塔、梁、缆、锚等各部件的施工，包含地连墙、沉井等多种基础形式和钢结构、预制件制造等多种施工工艺，同时项目参建方多，工点分散，这些都对建设管理提出了新的挑战，因此需要以新技术手段实现建设期全流程、全要素数字化管理，利用智慧建设BIM协同平台为项目建设精细化管理提供技术支撑。",

    section3_title: "“一云三端”，全过程智慧管理",
    section3_content1: "张靖皋建设管理平台聚焦特大跨径悬索桥工程特点，基于“一云三端”实现建设期的进度、质量、安全、计量、档案等关键业务的智慧化协同管理；融合项目智慧工地、智能建造、智慧工厂等系统，形成协同管理、智慧工地、智能建造一体化数字平台，多目标、全要素打造国家级智慧大桥数字化建设示范工程。",
  },
  // 行业案例-沪武高速
  expresswayHw: {
    section1_title: "沪武高速公路太仓至常州段改扩建BIM协同建设管理平台",
    section1_content1: "沪武高速公路太仓至常州段是沪武国家高速公路（G4221）的重要组成部分，其中苏沪界向西至董浜枢纽段为与沈海高速公路（G15）共线段。本项目是江苏省高速公路网规划（2017-2035）中“十五射六纵十横”中“横八”的重要组成部分，连接苏南和上海，是江苏省与上海市的主要出入通道之一。",
    section1_content2: "全线沿既有沪武高速加宽扩建，其中，常州新南村至梅港村段约5.754公里利用现有232省道圩墩大桥采用路基单侧拼宽，其余路段均采用路基两侧拼宽。新建及拼宽桥涵设计汽车荷载等级采用公路-Ⅰ级，利用既有桥梁沿用原荷载标准，其他技术指标按《公路工程技术标准》（JTGB01-2014）的有关规定执行。",
    section1_content3: "全线原位扩建太仓、板桥（原省道339）、太仓北（枢纽）、沙溪、沙溪（枢纽）、董浜（枢纽）、常熟、凤凰、张家港（枢纽）、张家港、杨舍（枢纽）、新桥、华西、峭岐（枢纽）、横林（枢纽）、戚墅堰、常州南共17处互通式立交；移位新建常熟北、长寿（原霞客）2处互通式立交；拆除青阳互通式立交；新建支塘、大义、徐霞客、芙蓉、礼嘉共5处互通式立交。全线原位扩建新桥、芙蓉共2处服务区。",
    section1_content4: "针对江苏省交通工程建设局高速公路（新建与扩建）建设管理实际，聚焦高速公路新建与改扩建工程特点，紧密围绕人、机、料、法、环关键要素，建设江苏省交通工程建设局高速公路（新建与扩建）智慧建设 BIM 协同管理平台，综合运用 BIM 技术、大数据、物联网、云计算等信息化技术手段，统一数据标准、接口标准，实现建设期关键业务及数据智慧化管理，辅助江苏省交通工程建设局高速公路（新建与扩建）智慧工地建设，解决业务之间实时数据联动难题，实现对工程范围内信息资源的充分共享和形象展示。",
    section1_content5: "平台涵盖江苏省交通工程建设局高速公路（新建与扩建）建设期项目进度、质量、安全、投资等管理内容，并根据项目特点包括档案管理、绿色低碳、BIM 交通导改和辅助智慧工地建设等项目管理内容。综合运用 BIM、GIS、IoT 等技术，搭建高速公路（新建与扩建）智慧建设 BIM 管理平台，优化工作流程，进一步提高对项目建设管理的管控能力，实现项目精细化管理。",
    section1_content6: "本平台将实现：",
    section1_content7: "（1）协同管理：建立基于BIM的项目协同管理工作平台",
    section1_content8: "建立建设单位与参建单位的在线工作平台，协同各单位之间、单位内部之间的业务流程，及时沟通信息，提升工作效率。",
    section1_content9: "（2）资源共享：建立BIM信息资源共享平台",
    section1_content10: "建立项目空间基础地理信息、BIM信息模型、质量安全进度监管数据、项目日常管理信息数据整合、交换和共享平台，形成项目信息资源中心，实现数据信息高效协同的建设管理体系。",
    section1_content11: "（3）流程管理：工程建设管理应用系统",
    section1_content12: "建设覆盖工程建设管理全过程的应用系统，实现信息共享、业务流转、报审批、流程管理、统计分析。",
    section1_content13: "（4）智慧建造：实施现场质量控制，打造智慧工地",
    section1_content14: "采用 BIM 技术、GPS技术、传感技术、全景摄影测量技术进行现场质量控制；使用手机、平板电脑等移动终端，实时采集现场试验检测数据、质检数据，形成各类试验台帐，实现现场质量检测数据的实时采集，提高数据采集效率，降低工作强度。",
    section1_content15: "（5）信息可视化：建立BIM可视化管理平台",
    section1_content16: "基于 BIM+GIS 三维环境，以 BIM模型为管理单元承载工程管理各项业务数据，实时展示工程管理状态，并以可视化的方式对外传递管理信息。",
    section1_content17: "（6）结合工程实际，全面推广应用",
    section1_content18: "江苏省交通工程建设局高速公路（新建与扩建）智慧建设BIM协同管理平台，主要管理核心要点是利用BIM技术，即三维信息模型的可视化，可关联，可追溯等特性与传统建设管理的业务模块进行深度融合，实现项目建设过程数据信息的高效存储与共享，针对关键节点，进行风险预警与模拟推演，以平台为抓手，提升业主方对项目协同管理的能力。为参建各方构建一个智慧化、集成化、互联互通的数字环境。最后在竣工验收时，三维模型关联施工全过程信息交付至运维阶段，为后期的运营公司的管理提供数据信息方面的有力支持。 同时江苏省交通工程建设局高速公路（新建与扩建）智慧建设BIM协同管理平台在总体目标系统下，针对各单位各层级的管理者提供配套的数字化管控解决方案。",
  },
  // 行业案例-江阴靖江长江隧道
  riverTunnelJyjj: {
    section1_title: "江阴靖江长江隧道智慧建设BIM协同管理平台",
    section1_title2: "",
    section1_content1: "江阴靖江长江隧道是《长江经济带综合立体交通走廊规划（2014-2020）》中确定的过江通道之一，也是江苏省高速公路网中规划的S90高速的过江通道部分。项目路线起自靖江公新公路与城西大道交叉处，向南以隧道形式穿越长江，止于江阴西外环路与芙蓉大道交叉口，路线全长11.825公里。其中，过江隧道段长6445米。全线设置互通3座，风塔2座，管理中心1处。项目采用双向六车道高速公路标准建设，设计速度80km/h。本项目建设工期为五年。",
    section1_content1_1: "",
    section1_content2: "针对江阴靖江长江隧道建设管理实际，聚焦大直径盾构长隧道特点，紧密围绕人、机、料、法、环关键要素，综合运用BIM技术、大数据、区块链、物联网、云计算等信息化技术手段，改进施工组织与人员交互的方式，实现智慧化项目管理，辅助江阴靖江长江隧道智慧工地建设，解决业务之间实时元数据联动难题，实现对各级责任管理主体及工程项目的指导、把关、监管、管控、分析与决策以及工程范围内信息资源的充分共享和形象展示。",
    section1_content2_1: "",

    section1_content3: "根据本项目特点及实际，项目BIM协同管理平台采用定制开发与系统集成相结合的思路，统一身份认证，理清各模块逻辑关联关系，增强系统的耦合性，实现数据输入一个入口，数据查询与输出方便快捷，与BIM三维场景实现相互关联。基于统一的WBS结构分解和BIM模型实现对项目管理进度、质量、安全、计量支付等协同管理。",
    section1_content4: "项目BIM协同管理平台主要成果有：",
    section1_content5: "（1）基于大盾构水下隧道特点，构建统一的数据标准体系，为系统数据互联互通打下基础。",
    section1_content6: "制定一套具有科学性、系统性、可扩延性、兼容性、具有适应大盾构隧道的BIM建设管理平台数据标准，是实现项目信息化的关键工作，标准编制需符合当前现行业务又具有一定前瞻性和灵活性。依托本项目，参考国家、交通部、江苏省相关标准， 组织参建各方完成了工程项目WBS编码手册，为本项目BIM平台开发与应用打下基础。",
    section1_content7: "（2）基于电子签名签章技术，实现本项目工程收发文及计量支付的全电子化流程。",
    section1_content8: "项目收发文通过内置挂接好的表库，实现收发文流转、审批信息化，通过CA数字签章，保证了收发文的时效性、真实性及合法性。将以往纸质线下流转改为线上流转，大幅度提高各参建单位收发文流转效率。平台利用电子签章功能实现网上计量报审签批，通过明确审批流程和审批责任人并启用审批提醒功能，大大缩短了计量的审批时间，让审批变得透明化、简洁化，防止超前、超量计量。",
    section1_content9: "（3）基于统一的WBS编码，实现进度、质量、计量等关键业务数据的互联互通。",
    section1_content10: "进度系统、计量系统与质量系统采用统一的WBS，实现系统间数据互联互通。质量系统以基于统一WBS的工序报验流程推送至进度系统完成实际进度统计与分析；平台以质量检验合格为基本计量条件，自动将检验结果推送计量系统，计算本期计量数量，同时根据可计量节点自动生成本期计量清单；将项目全过程的计量工作由人工计量改为系统自动计量。",
    section1_content11: "（4）基于二维码及统一的数据接口标准，实现盾构隧道管片全流程信息化管控。",
    section1_content12: "针对本项目大直径盾构施工特点，对全线约五万片管片的全流程信息化管控是本项目信息化工作的重中之重。基于管片施工主要有预制与拼装两个主要流程，管片生产信息化管理由施工单位的管片生产管理系统实施，管片拼装信息化管理利用BIM建设管理平台，两者统一数据接口标准，有机集成，实现数据互联互通，以二维码为载体，实现管片的生产、运输、安装全过程精确信息化管控。",
    section1_content13: "（5）基于BIM+IoT技术，实现对环境监测、人员定位、监控量测等重要信息的高效协同管理。",
    section1_content14: "长大水下过江隧道工程建设具有隐蔽、复杂、风险高的特点，因此本项目对安全和绿色环保要求高。本项目开发了绿色环保、人员定位、监控量测等系统，基于BIM+IoT技术，实现对环境监测、人员定位、监控量测等重要信息的高效协同管理和直观展示。",
    section1_content15: "（6）基于BIM+业务数据，实现工程管理BIM协同及信息的高度融合。",
    section1_content16: "BIM协同模块是整个项目信息化的大脑，通过BIM技术、大数据、物联网、云计算等技术将工程项目重要数据集成在该模块，实现工程项目的万物互联、数据共享。",
    section1_content17: "BIM协同集成各项工程数据，通过各项数据面板，可实时掌握进度、质量、计量、安全、智慧工地等总体情况。基于统一的WBS，依托模型构件，集成该构件各类信息，形成BIM+进度、质量、计量……。",
    section1_content18: "江阴靖江长江隧道BIM协同管理平台的应用，可以使设计方案、施工计划和监理过程等各个环节实现协同管理，避免了信息孤岛和重复工作。同时，该平台还可以实现施工进度的实时监控和调整，避免了因为施工计划不合理而造成的延误和浪费。此外，平台还可以实现材料和设备的优化选择和统一采购，减少了成本，提高了效率。",
    section1_content19: "江阴靖江长江隧道BIM协同管理平台的应用可以提高建设过程的效率，实现了设计、施工、监理、质量控制、安全管理等各个环节的协同管理，有助于提高建设质量和有效控制成本。同时也为中国的隧道建设行业提供了一种先进的管理模式和技术，具有重要的示范和推广意义。",
  },
  // 行业案例-海太长江隧道
  riverTunnelHt: {
    section1_title: "海太长江隧道智慧建设BIM协同管理平台",
    section1_title2: "",
    section1_content1: "海太长江隧道(公路部分)是《长江千线过江通道布局规划(2020 2035年)》中长江江苏段44座过江通道之一，也是江苏省高速公路网规划建设的S13通常高速公路过江设施。",
    section1_content2: "本项目起自沪陕高速(G40)，向南连续上跨宁启铁路、在建南通港通海港区至通州湾港区铁路专用线，向西南方向上跨浒通河、352县道，继续向南上跨356省道后，沿叠港公路西侧布线，在海太汽渡西侧以盾构隧道形式越江，在白河口东侧上岸，向西南方向上跨346国道后，止于沪武高速董浜枢纽互通东约5.5公里处，路线全长39.070公里采用盾构隧道方案穿越长江，隧道全长约11185米，北接线(南通段)长15.724公里，南接线苏州段)长12.161公里。",
    section1_content3: "2022年9月7日，江苏省委省政府组织海太长江隧道开工动员会。隧道主体结构采用双向六车道高速公路设计标准，盾构段设计行车速度100km/h。",
    section1_content4: "海太长江隧道智慧建设BIM协同管理平台是在海太长江隧道建设过程中采用的一种先进的建设管理平台。BIM（Building Information Modeling，建筑信息模型）技术是一种将建筑信息数字化、可视化、集成化的技术，通过BIM技术可以实现建筑设计、施工、运营的全过程协同管理。对于隧道建设过程中的设计、施工、监理、质量控制、安全管理、物资管理等各个环节进行全过程协同管理，并实现了信息的共享和交流，有效提高了建设管理的效率和质量。",
    section1_content5: "本项目聚焦海太长江隧道预制拼装一体化建造特点，围绕隧道建设安全、质量、投资、进度、档案等核心业务，着眼智慧建造、绿色零碳目标定位，借鉴江阴靖江长江隧道、张靖泉长江大桥智慧管理先进经验，力争在本项目中进一步提升，最终实现全业务、全流程、全数字、全线上的新一代智慧建设BIM协同管理平台标杆。",
    section1_content6: "",
    section1_content7: "",
    section1_content8: "",
    section1_content9: "",
    section1_content10: "",
    section1_content11: "",
    section1_content12: "",
  },
  // 行业案例-腾龙大道智慧快速路工程数字化建设管理平台
  tlAvenue: {
    section1_title: "腾龙大道智慧快速路、机场路快速化改造收尾工程",
    section1_content1: "腾龙大道智慧快速路二期工程起于长扬路，向北利用老路，终于龙城大道以北与黄河西路交叉处，路线长度19800米。新北区段包含腾龙大道段工程和龙城大道段地面道路工程，其中腾龙大道段工程起于新北与钟楼交界处，沿老路向北，终于黄河西路，长约7700米；龙城大道段地面道路工程起于东侧跳水台，终于兴奔路，长约2100米。",
    section1_content2: "",
    section2_title: "腾龙大道智慧快速路工程数字化建设管理平台",
    section2_content1: "高架预制装配里程长，路网密集区施工干扰多，建设质量管控任务重,同时项目参建方多，工点分散，这些都对建设管理提出了新的挑战，因此需要以新技术手段实现建设期全流程、全要素数字化管理，腾龙大道依托BIM、GIS、loT、AI等信息技术实时穿透各工程现场，以现场管控为目标、成本为核心进行数据实采，智慧建设BIM协同平台实现建设期的进度、质量、安全、计量、档案等关键业务的智慧化协同管理，每天数千条工程建设管理数据电子化线上流转审批，融合项目智慧工地、智慧梁场等系统为项目建设精细化管理提供技术支撑。",
    section2_content2: "",
    section2_content3: "",
    section2_content4: "",
    section2_content5: "",
  },
  // 行业案例-华设盐城智能制造产业园
  hsyc: {
    section1_title: "华设盐城智能制造产业园预制构建生产管理与数字孪生开发平台",
    section1_content1: "华设盐城智能制造产业园是位于江苏省盐城市建湖县的一座智能制造产业园，由华设集团投资兴建，于2021年正式开园。产业园占地面积约为300亩，总投资额达60亿元人民币，是华设集团在华东区域的重要战略投资。产业园以“新一代信息技术+智能制造”为主要方向，旨在打造成为集研发、生产、培训和展示为一体的智能制造产业基地。",
    section1_content2: "产业园的主要业务包括：",
    section1_content3: "智能装备制造：园区内设有智能装备制造车间，集中生产各类高精密、高自动化的智能制造设备和零部件。",
    section1_content4: "智能化解决方案：园区内设有智能化解决方案研发中心，为各类企业提供智能化升级方案和服务。",
    section1_content5: "技能培训和科技创新：园区内设有技能培训中心和科技创新中心，为企业和员工提供各类技能培训和科技创新支持。",
    section1_content6: "产业展示和交流：园区内设有产业展示中心和交流中心，为各类企业和客户提供产品展示和交流平台。",
    section1_content7: "华设盐城智能制造产业园采用先进的智能制造技术和设备，具有高效、智能、可持续等特点，将有助于推动中国制造业的升级和转型。同时，该产业园还将带动当地经济和就业发展，促进区域经济的快速发展。",
  },
  // 行业案例-汾湖站交通枢纽配套工程
  fhz: {
    section1_title: "汾湖站交通枢纽配套工程全过程BIM咨询服务",
    section1_content1: "汾湖站交通枢纽配套工程是位于中国江苏省苏州市的交通枢纽建设项目，项目总投资约为60亿元人民币。该项目的建设旨在提高当地交通运输的便捷性和效率，促进区域经济发展。",
    section1_content2: "汾湖站交通枢纽配套工程全过程BIM咨询服务具有以下特点和优势：",
    section1_content3: "提高建设质量和效率：通过BIM技术进行建模和协同工作，可以提高建设质量和效率，避免施工过程中的问题和错误。",
    section1_content4: "降低建设成本和风险：通过BIM仿真，可以提前发现和处理问题，降低施工风险，进而降低建设成本。",
    section1_content5: "实现数字化建设和智能化管理：通过BIM建模和可视化展示，可以实现数字化建设和智能化管理，为工程建设提供更加科学、智能化的管理和监控手段。",
    section1_content6: "支持协同工作和信息共享：通过BIM协同和可视化展示，实现了各个部门之间的协同工作和信息共享，提高了工作效率。",
    section1_content7: "提高项目管理水平：通过BIM技术的应用，可以实现对项目的全过程管理和监控，提高项目管理水平和效率。",
    section1_content8: "本次咨询服务的实施，将有助于推动数字化建设和智能化管理的发展，提高工程建设的质量和效率，降低建设成本和风险，为区域经济的快速发展做出贡献。",
  },

  // 资讯与活动
  information: {
    hd_text1: "最",
    hd_text2: "新资讯",
    hd_text3: "News",

    menu_title1: "信息快讯",
    menu_title2: "公司新闻",
    menu_title3: "通知公告",
    menu_title4: "行业资讯",
    menu_title5: "精彩视频",
  },

  // 联系我们
  about: {
    section1_title: "联系我们",
    section1_title2: "客服热线 4000-025-828",
    section1_text: "在线提交您的需求",
    section1_text2: "我们会在24小时内向您致电",

    section1_from1: "您的姓名",
    section1_from2: "您的电话/手机",
    section1_from3: "您的邮箱",
    section1_from4: "请填写您的需求",
    section1_submit1: "提交",
    from_rule1: "用户姓名不能为空",
    from_rule2: "手机号不能为空",
    from_rule3: "邮箱不能为空",
    from_rule4: "请输入正确的邮箱地址",
    from_rule5: "需求不能为空",
    submit_tips: "提交成功",

    section2_title: "商务洽谈",
    section2_item1_title: "数字建造咨询",
    section2_item1_text: "联系人",
    section2_item1_phone1: "董经理 15280106801",
    section2_item1_phone2: "伏经理 18505110818",

    section2_item2_title: "数字设计咨询",
    section2_item2_text: "联系人",
    section2_item2_phone1: "徐经理 17372261215",
    section2_item2_phone2: "李经理 18618162992",
    section2_item2_phone3: "吴经理 17730442539",

    section3_title: "我们在这里等待您的到来",
    section3_content1: "江苏省南京市江宁区智识路启迪科技城立业园1栋",
    section3_content2: "",

  },

  // EICAD-演示教学
  demonstrationTeaching: {
    text1: "人已看",
    text2: "时长",
    text3: "视频上传时间",
    text4: "内容简介",
    text5: "其他视频",
  },

  // 更新日志
  updateLog: {
    section1_title: "更新日志",

  },

};


