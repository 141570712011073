import axios from "axios"
import { Message } from "element-ui"
import router from "../router/index"
import store from "../store/index"
import { baseURL } from './env.js'
console.log('baseURL====', baseURL)
const baseConfig = {
  // baseURL: "http://58.213.48.53:99/prod-api/",//正式
  // baseURL: "http://47.116.12.36:99/prod-api/", // ceshi
  // baseURL: "http://106.14.238.88:8080/dnnWebManage/", // wuliang 2023-10-08 @guzhong
  // baseURL: 'https://dev.eicad.net/dnnWebManage/', // 线上
  baseURL,
  timeout: 30000,
  transformRequest: [
    function (data) {
      data = JSON.stringify(data)
      return data
    },
  ],
  //   在传给then之前修改响应数据
  transformResponse: [
    function (data) {
      if (typeof data === "string" && data.startsWith("{")) {
        data = JSON.parse(data)
      }
      return data
    },
  ],
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
}

const httpService = axios.create(baseConfig)
console.log('httpService ', baseConfig)
const http = axios.create(baseConfig)

// 请求拦截函数
const requestIterceptor = (config) => {
  const token = localStorage.getItem("token")
  //   
  //   config.headers["Authorization"] = token
  if (token) {
    config.headers["Authorization"] = "Bearer " + token
  }
  return config
}

// 请求拦截器
http.interceptors.request.use(requestIterceptor, (error) => {
  return Promise.reject(error)
})
httpService.interceptors.request.use(requestIterceptor, (error) => {
  return Promise.reject(error)
})

const errorHandle = (status, message) => {
  switch (status) {
    case 1011008:
      router.push("/login")
      break
    case 404:
      Message({
        message: message,
        type: "error",
      })
      break
    case 20001:
      Message({
        message: message,
        type: "error",
      })
      break
    default:
      Message({
        message: message,
        type: "error",
      })
  }
}

// 响应拦截器
httpService.interceptors.response.use((response) => {
  const data = response.data

  if (response.status === 200) {
    if (response.data.code === 1016002 || response.data.code === 1011008) {
      store.commit('REMOVE_INFO')
      router.push('/login')
    }
    return Promise.resolve(data)
  } else {
    errorHandle(data.code, data.message)
    return Promise.reject(data.message)
  }
})

http.interceptors.response.use((response) => {
  return Promise.resolve(response)
})

export default httpService
export { http }
