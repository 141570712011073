<!--
* @description 资讯与活动
* @fileName zxyhd.vue
* @author liulian
* @date 2023/06/30 14:27:46
-->
<template>
  <div class="zxyhy_container">
    <div class="zxyhd flex-row">
    <div v-if="lists.length > 0" class="z_left cursor-pointer text-align-left" @click="goDetail(lists[0])">
      <div class="left_img_box">
        <img class="l_img" :src="lists[0].messageUrl" alt="封面图" />
      </div>
      <div class="l_title font-size-16 font-weight-600 font-color-black-301D09">{{ lists[0].messageTitle }}</div>
      <!-- <div class="l_con clamp-2 font-size-14 font-weight-400 font-color-gray-666666" v-html="lists[0].messageSynopsis"></div> -->
      <div class="l_con clamp-3 font-size-14 font-weight-400 font-color-gray-666666" >{{lists[0].messageSynopsis}}</div>
      <div class="l_time flex-row-between align-center">
        <span class="font-size-12 font-weight-400 font-color-gray-999999">{{ lists[0].updateTime }}</span>
        <div class="font-color-yellow-FC8F01  font-size-12 font-weight-500" >{{ $t("common.KnowMore") }}></div>
      </div>
    </div>
    <div class="z_right" v-if="lists.length > 0">
      <div class="r_list">
        <template v-for="(item, index) in lists">
          <div class="l_item cursor-pointer" v-if="index !== 0" :key="index"  @click="goDetail(item)">
            <div class="l_top flex-row">
              <div class="t_left">
                <img class="l_img" :src="item.messageUrl" alt="封面图" />
              </div>
              <div class="t_right text-align-left">
                <div class="r_title clamp-2 font-size-16 font-weight-600 font-color-black-301D09">{{ item.messageTitle }}</div>
                <div class="r_con clamp-2 font-size-14 font-weight-400 font-color-gray-666666" >{{item.messageSynopsis}}</div>
              </div>
            </div>
            <div class="l_time_span flex-row-between align-center">
              <span class="left font-size-12 font-weight-400 font-color-gray-999999">{{ item.updateTime }}</span>
              <span class="right cursor-pointer font-color-yellow-FC8F01 font-size-12 font-weight-500">{{ $t("common.KnowMore") }} ></span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <div class="s_more_btn flex-row-center align-center">
      <span class="m_btn cursor-pointer font-size-12 font-weight-400" @click="moreNews">{{ $t("pc_index.MoreZixun") }}</span>
    </div>
  </div>
</template>

<script>
import { clientHomeLatestInfoApi } from "@/api/api"
export default {
  name: "zxyhd",
  components: {},
  data() {
    return {
      lists: [],
    }
  },
  computed: {},
  created() {
    this.getInfo()
  },
  mounted() {},
  methods: {
    /*
     * @Description: 获取资讯最新四条数据
     * @MethodAuthor:  liulian
     * @Date: 2023-07-25 18:18:30
     * @param: ''
     */
    getInfo() {
      clientHomeLatestInfoApi().then((res) => {
        if (res.code === 200) {
          this.lists = res.rows
        }
      })
    },

    /*
     * @Description: 跳转详情
     * @MethodAuthor:  liulian
     * @Date: 2023-07-17 14:38:41
     * @param: ''
     */
    goDetail(item) {
      // this.$router.push(`/newsDetail?type=information&id=${item.messageId}`)
      this.$uniFun.openUrl(`/dnnweb/newsDetail?type=information&id=${item.messageId}`)
    },
    /*
     * @Description: 跳转更多资讯
     * @MethodAuthor:  liulian
     * @Date: 2023-08-24 16:29:31
     * @param: '' 
    */
   moreNews(){
    this.$uniFun.openUrl(`/dnnweb/information?class=-1&type=information`)
  }
  },

  watch: {
    '$i18n.locale': function() {
      this.getInfo()
    }
  }
}
</script>

<style scoped lang="scss">
.zxyhy_container{
  margin-top: 40px;
  .zxyhd {
  width: 1188px;
  margin: auto;
  .z_left {
    min-height: 450px;
    background-color: #fff;
    padding-left: 16px;
    padding-top: 8px;
    padding-right: 25px;
    max-width: 588px;
    border-radius: 4px;
    .left_img_box {
      width: 547px;
      height: 298px;
    }
    .l_title {
      margin-top: 8px;
    }
    .l_img {
      width: 547px;
      height: 298px;
      margin-bottom: 8px;
      object-fit: cover;
    }
    .l_con {
      margin-top: 4px;
      line-height: 24px;
      // height: 72px;
      min-height: 47px;
      overflow: hidden;
      white-space: pre-wrap !important;
      color: #666666;
    }
    .l_time {
      margin-top: 12px;
      height: 12px;
    }
  }
  .z_right {
    margin-left: 24px;
    flex: 1;
    .l_item {
      padding-top: 8px;
      padding-right: 30px;
      background-color: #fff;
      margin-bottom: 11px;
      border-radius: 4px;
      padding-bottom: 12px;
      .l_time_span {
        margin-top: 10px;
        height: 12px;
        .left {
          margin-left: 10px;
        }
      }
    }
    .l_item:last-child {
      margin-bottom: unset;
    }
    .t_left {
      margin-left: 8px;
      margin-right: 12px;
      width: 140px;
      height: 100px;

      .l_img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .t_right {
      flex: 1;
      .r_con {
        margin-top: 4px;
        line-height: 24px;
        height: 48px;
        overflow: hidden;
        white-space: pre-wrap !important;

        color: #666666;
      }
      .r_title {
        line-height: 24px;
      }
    }
  }
}
.s_more_btn {
    margin-top: 29px;
    .m_btn {
      width: 80px;
      height: 24px;
      line-height: 24px;
      background: #fc8f01;
      border-radius: 3px;
      text-align: center;

      color: #ffffff;
    }
  }
}

</style>
