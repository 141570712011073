<!--
* @description 手机端首页
* @fileName phoneHome.vue
* @author liulian
* @date 2022/06/01 16:05:10
!-->
<template>
  <div class="box">
    <el-button type="primary">手机端首页</el-button>
  </div>
</template>

<script>
export default {
  name: 'phoneHome',
  components: {},
  data() {
    return {

    }
  },
  computed: {},
  created() { },
  mounted() { },
  methods: {}
}
</script>

<style scoped lang="scss">

.test1 {
  width: 300px;
  height: 70px;
  background-color: #eee;
    margin-top: 10px;
    flex: 1;
    font-size: 18px;
    
}
.test2 {
  width: 6.25rem;
  height: 1.875rem;
  background-color: yellow;
  margin-top: .125rem;
      flex:2;
      font-size: 18px;
}
.test3 {
  width: 3.75rem;
  background-color: pink;
  height: 70px;
  margin-top: 10px;
    flex: 1;
    font-size: .225rem;
    
}
</style>
