<!--
* @description 底部组件
* @fileName Footer.vue
* @author liulian
* @date 2023/06/30 09:15:05
-->
<template>
  <div class="footer_container">
    <div class="top flex-row">
      <div class="f_left">
        <img class="img" src="@/assets/images/footer_logo@2x.png" alt="logo" />
      </div>
      <div class="f_right">
        <div class="title font-size-18 font-weight-600">
          {{ $t("Footer.ContactUs") }}
        </div>
        <div class="r_list flex-row-between">
          <div class="l_item" v-for="(item, index) in lists" :key="index">
            <div class="i_title font-size-16 font-weight-600">
              {{ $t(`Footer.${item.title}`) }}
            </div>
            <div
              class="i_ls"
              :class="item.title == '地址' ? 'width210' : ''"
              v-if="item.title !== 'WechatAccount'"
            >
              <div
                class="i_con font-size-14 font-weight-400"
                v-for="(i, index1) in item.children"
                :key="index1"
              >
                <span
                  v-if="
                    i.title == 'CustomerService4' ||
                    i.title == 'CustomerService5' ||
                    i.title == 'CustomerService6'
                  "
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
                {{ $t(`Footer.${i.title}`) }}
              </div>
            </div>
            <div class="i_ls" v-else-if="$i18n.locale != 'English'">
              <img
                class="l_img"
                src="@/assets/images/f_code@2x.png"
                alt="二维码"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tip font-size-16 font-weight-400">
      <p class="t_p text-align-center">
        版权所有@2018 江苏狄诺尼信息技术有限责任公司
      </p>
      <p class="t_p text-align-center">苏ICP备18062906号</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  components: {},
  data() {
    return {
      lists: [
        {
          title: "CompanyHotline",
          children: [
            {
              title: "CustomerService1",
            },
            {
              title: "CustomerService2",
            },
            {
              title: "CustomerService3",
            },
            {
              title: "CustomerService4",
            },
            {
              title: "CustomerService5",
            },
            {
              title: "CustomerService6",
            },
          ],
        },
        {
          title: "DigitalDesignConsulting",
          children: [
            {
              title: "DesignMember1",
            },
            {
              title: "DesignMember2",
            },
            {
              title: "DesignMember3",
            },
            {
              title: "DesignMember4",
            },
            {
              title: "DesignMember5",
            },
            {
              title: "DesignMember6",
            },
            {
              title: "DesignMemberText",
            },
          ],
        },
        {
          title: "DigitalConstructionConsulting",
          children: [
            {
              title: "ConstructionMember1",
            },
            {
              title: "ConstructionMember2",
            },
            {
              title: "ConstructionMemberText",
            },
          ],
        },
        // {
        //   title: "QQ群",
        //   children: [
        //     {
        //       title: "咨询群：170767446",
        //     },
        //     {
        //       title: "咨询群：227387898",
        //     },
        //   ],
        // },
        {
          title: "Address",
          children: [
            {
              title: "AddressDescribe",
            },
          ],
        },
        {
          title: "WechatAccount",
        },
      ],
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
.footer_container {
  height: 500px;
  background: #242424;
  color: #fff;

  .top {
    width: 1405px;
    margin: auto;
  }
  .f_left {
    padding-top: 99px;
    margin-right: 66px;
    .img {
      width: 190px;
      height: 66px;
    }
  }
  .f_right {
    padding-top: 40px;
    flex: 1;
    .r_list {
      width: 100%;
    }
    .title {
      padding-bottom: 24px;
      border-bottom: 1px solid #d8d8d8;
      margin-bottom: 24px;
    }
    .l_item {
      //   margin-right: 40px;
      max-width: 245px;
      .i_title {
        margin-bottom: 20px;
      }
      .width210 {
        width: 210px;
      }
      .i_con {
        color: rgba(255, 255, 255, 0.6);
        margin-bottom: 12px;
      }
      .i_con:last-child {
        margin-bottom: unset;
      }
      .l_img {
        width: 120px;
        height: 120px;
      }
    }
    .l_item:last-child {
      margin-right: unset;
    }
  }
  .tip {
    color: rgba(255, 255, 255, 0.6);
    margin-top: 24px;
    .t_p:first-child {
      margin-bottom: 12px;
    }
  }
}
</style>
