<template>
  <div>
    <div ref="app_container">
      <div class="nav">
        <HeaderBar></HeaderBar>
      </div>
      <div class="main">
        <router-view />
      </div>
      <div class="footer" v-if="showFooter">
        <Footer />
      </div>
    </div>
  </div>
</template>
<script>
import HeaderBar from "../components/HeaderBar.vue";
import Footer from "../components/Footer.vue";
export default {
  components: {
    HeaderBar,
    Footer,
  },
  mounted() {
    // if (this._isMobile()) {
    //   // this.$router.replace('/phone_index') // 不用适配手机端
    // } else {
    //   this.$router.replace("/pc_index")
    // }
  },
  methods: {
    //App.vue
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  computed: {
    showFooter() {
      let routePath = ["/download", "/updateLog", "/updateLogDetail"]; // 所有页面都需要底部，所以改成不需要底部的页面将路由放置数组中
      let path = this.$route.path;
      return routePath.indexOf(path) === -1;
    },
  },
};
</script>
<style lang="scss">
@import "../../src/assets/styles/index.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
@import "~quill/dist/quill.core.css";
#app {
  font-family: PingFang SC-Regular, PingFang SC;
  background: #f8f8f8;
}
.nav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 999;
}

.main {
  margin-top: 80px;
}
</style>
